import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCar, faCartShopping} from "@fortawesome/free-solid-svg-icons";
import EuroFormat from "../../../../components/UI/EuroFormat";
import {TrashIcon} from "@heroicons/react/24/outline";
import React from "react";
import DateFormat from "../../../../components/UI/DateFormat";

export const ExpenseEntryTableItem = ({entry, onClick}) => {
    return (
        <>
            <div className='flex items-center min-w-full'>
                <div
                    className={`relative overflow-hidden bg-white px-1 py-4 w-full`}>
                    <div className='flex justify-between items-center'>
                        <div className='flex flex-col gap-y-3'>
                            <div className='flex flex-row gap-x-4'>
                                <div className='flex items-center'>
                                    <FontAwesomeIcon icon={entry?.type?.value === 'general' ? faCartShopping : faCar}/>
                                    <p className='text-sm flex-1 text-left items-center ml-5'><DateFormat value={entry?.date}/></p>
                                </div>

                                <div className='flex items-center'>
                                    <p className='text-sm items-center justify-end text-black'>
                                        <EuroFormat
                                            value={entry?.price_amount}/></p>
                                </div>
                            </div>
                            {entry?.type?.value === 'travel' ? <div className='flex flex-col gap-y-2'>
                                    <div className='flex flex-row'>
                                        <p className='text-sm text-left items-center'>Da:</p>
                                        <p className='text-sm text-left items-center ml-5 text-gray-500'>{entry?.departure}</p>
                                    </div>

                                    <div className='flex flex-row gap-x-2'>
                                        <p className='text-sm text-left items-center'>A:</p>
                                        <p className='text-sm text-left items-center ml-5 text-gray-500'>{entry?.arrival}</p>
                                    </div>
                                    <div className='flex flex-row'>
                                        <p className='text-sm text-left items-center'>Km:</p>
                                        <p className='text-sm text-left items-center ml-5 text-gray-500'>{entry?.km_amount}</p>
                                    </div>
                                </div>
                                : <></>}
                            {entry?.note ? <p className='text-sm text-left items-center w-full'>{entry?.note}</p> : <></>}
                        </div>

                        <div className='flex items-center'>
                            {/*<button
                                onClick={onClick}
                                className='ml-auto inline-flex ps-3 items-center gap-x-2 rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600'
                            >
                                <ArrowTopRightOnSquareIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>*/}
                            <button
                                onClick={onClick}
                                className="inline-flex ps-3 items-center gap-x-2 rounded-md text-xs font-semibold text-red-600 hover:text-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenseEntryTableItem;