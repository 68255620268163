import {NumericFormat} from "react-number-format";

const DiscountFormat = ({value}) => {
    return (
        <NumericFormat
            value={value}
            displayType='text'
            decimalSeparator=','
            decimalScale={0}
            allowNegative={false}
            fixedDecimalScale
            thousandSeparator='.'
            suffix='%'
            prefix='-'
        />
    );
};

export default DiscountFormat;