import React from "react";

export const InfoNoteField = ({value = undefined, title = 'Note'}) => {

    return (
        value ? (
            <div className="border-t border-gray-100 px-2 py-4 col-span-full">
                <dt className="text-sm font-bold leading-6 text-gray-900">{title}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                    {value}
                </dd>
            </div>
        ) : (
            <div className='mt-12'></div>
        )
    );
}
