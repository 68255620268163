import OfferForm from "./components/OfferForm";
import {useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import {offerServices} from "../../services/OfferService";
import LoadingSpinner from "../../components/UI/LoadingSpinner";

export const EditOffer = () => {
    const {uuid} = useParams();
    const {data: offer, loading} = useRequest(offerServices.offerShow, {
        defaultParams: [uuid]
    })

    return (
            <LoadingSpinner loading={loading}>
                <OfferForm offer={offer}/>
            </LoadingSpinner>
    )
}

export default EditOffer