import {Fragment, useEffect, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import {useParams} from "react-router-dom";
import ChangeStateModal from "./ChangeStateModal";
import {
    ArchiveBoxArrowDownIcon,
    CheckCircleIcon,
    ClockIcon,
    DocumentTextIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import PermissionChecker from "../Theme/PermissionChecker";

const stateIcon = {
    draft: <DocumentTextIcon className='h-5 w-5'/>,
    pending: <ClockIcon className='h-5 w-5'/>,
    terminated: <ArchiveBoxArrowDownIcon className='h-5 w-5'/>,
    suspended: <ExclamationTriangleIcon className='h5- w-5'/>,
    expired: <ExclamationCircleIcon className='h5- w-5'/>,
    refused: <XCircleIcon className='h-5 w-5'/>,
    accepted: <CheckCircleIcon className='h-5 w-5'/>,
    active: <CheckCircleIcon className='h-5 w-5'/>,
}

const statues = {
    active: 'text-gray-600 bg-green-200 ring-gray-500/10',
    accepted: 'text-gray-600 bg-green-200 ring-gray-500/10',
    suspended: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    terminated: 'text-indigo-600 bg-white ring-indigo-600/50',
    refused: 'text-red-600 bg-red-50 ring-red-600/20',
    expired: 'text-red-600 bg-red-50 ring-red-600/20',
    draft: 'text-dark bg-gray-300 ring-black/20',
}

export default function StateDropDown({available_states = [], value = {}, type, onStateUpdate, permissions}) {
    const [selected, setSelected] = useState(value)
    const [showModal, setShowModal] = useState(false);
    const [tempSelected, setTempSelected] = useState(value)
    const {uuid} = useParams();

    useEffect(() => {
        setSelected(value)
    }, [value]);

    const handleChange = (selected) => {
        setTempSelected(selected);
        setShowModal(true);
    }

    return (
        <>
            <Listbox value={selected} onChange={handleChange}>
                <div className="relative w-44">
                    <Listbox.Button
                        className={`relative w-full cursor-default rounded-xl py-1 text-center border border-dark ${statues[selected?.value]}`}>
                        <div className='w-full flex items-center justify-center gap-x-2'>
                            {stateIcon[selected.value]}
                            <span className="block truncate">{selected.label}</span>

                            {available_states?.length > 0 ?
                                <PermissionChecker permissions={permissions}>
                                    <span
                                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </PermissionChecker>
                                :
                                <></>
                            }
                        </div>

                    </Listbox.Button>
                    {available_states.length > 0 ?
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className="fixed w-44 mt-1 max-h-60 overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                {available_states?.map((state, stateIdx) => (
                                    <Listbox.Option
                                        key={stateIdx}
                                        className={({active}) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                active ? 'bg-gray-200' : 'text-gray-900'
                                            }`
                                        }
                                        value={state}
                                    >
                                        {({selected}) => (
                                            <>
                                                <div className='flex gap-x-2'>
                                                    {stateIcon[state.value]}
                                                    <span
                                                        className={`truncate ${
                                                            selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                    >
                                            {state.label}
                                          </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>

                        </Transition>
                        :
                        <></>
                    }
                </div>
            </Listbox>

            <ChangeStateModal
                value={tempSelected}
                type={type}
                setSelected={e => setSelected(tempSelected)}
                onClose={e => setShowModal(false)}
                onStateUpdate={onStateUpdate}
                state={showModal}/>
        </>
    )
}