export const SelectAllButton = ({selectAll, onChange, isDisabled}) => {

    return (
        <>
            <button
                type='button'
                disabled={isDisabled}
                onClick={onChange}
                className={isDisabled ? 'bg-gray-300 text-white py-1 px-2 rounded text-sm w-36' : 'bg-green-600 text-white py-1 px-2 rounded text-sm w-36'}
            >
                {selectAll ? 'Deseleziona Tutti' : 'Seleziona Tutti'}
            </button>
        </>
    )
}

export default SelectAllButton;