import LoadingSpinner from "../../components/UI/LoadingSpinner";
import QuoteForm from "./components/QuoteForm";
import {useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import quoteServices from "../../services/QuoteService";

export const EditQuote = () => {
    const {uuid} = useParams();
    const {data = [], loading} = useRequest(quoteServices.quoteShow, {
        defaultParams: [uuid]
    })

    return (
            <LoadingSpinner loading={loading}>
                <QuoteForm quote={data}/>
            </LoadingSpinner>
    )
}

export default EditQuote