import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import serverServices from "../../../../services/ServerService";
import {FormGroup} from "../../../../components/UI/Forms/FormGroup";
import {FormInput} from "../../../../components/UI/Fields/FormInput";
import CancelButton from "../../../../components/UI/Buttons/CancelButton";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";

const validationSchema = Yup.object().shape({
    site_id: Yup.string().nullable(),
    provider: Yup.string().nullable().required(),
    note: Yup.string().nullable(),
})
export const StorageForm = ({storage, serverUuid, siteId, onCancel, onCreate, onUpdate}) => {
    const {flashMsg} = useFlashMessage();

    const initialValues = {
        provider: storage?.provider ?? '',
        note: storage?.note ?? '',
    }

    const createStorage = (data, setSubmitting) => {
        serverServices.createStorage(serverUuid, data)
            .then(res => {
                flashMsg('Storage Creato', undefined, uuidv4(), true)
                onCreate(res, serverUuid, siteId);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const updateStorage = (data, setSubmitting) => {
        serverServices.updateStorage(serverUuid, storage?.uuid, data)
            .then(res => {
                flashMsg('Storage Modificato', undefined, uuidv4(), true)
                onUpdate(res, serverUuid, siteId);
                onCancel();
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            site_id: siteId ?? null,
            provider: values.provider,
            note: values.note
        }

        storage ? updateStorage(data, setSubmitting) : createStorage(data, setSubmitting)
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <div className="space-y-8">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 pb-4">

                            <div
                                className="grid grid-cols-1 gap-x-2 gap-y-3 sm:grid-cols-6">

                                <div className="col-span-full">
                                    <FormGroup required label='Provider' name='provider'>
                                        <div className="mt-2">
                                            <Field as={FormInput} required label='provider'
                                                   name='provider' value={formik.values.provider}/>
                                        </div>
                                    </FormGroup>
                                </div>


                                <div className="col-span-full">
                                    <FormGroup label='Note' name='note'>
                                        <div className="mt-2">
                                            <Field as={FormInput} type='textarea' label='note'
                                                   name='note' value={formik.values.note}/>
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 flex items-center justify-end gap-x-6">
                        {onCancel ? <CancelButton action={onCancel}/> : <></>}
                        <SubmitButton label={storage ? 'Salva' : 'Crea'}/>
                    </div>
                </Form>
            </FormikProvider>
        </>
    )
}

export default StorageForm;