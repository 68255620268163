import TableLink from "../../Buttons/TableLink";

const CompaniesTableConfig = [{
    Header: 'Nome',
    accessor: 'name',
    Cell: ({ row, value }) => <div className='mt-2 text-green-600'>
        <TableLink to={`/projects/${row.original.uuid}`} value={value}/>
    </div>
},{
    Header: 'Cliente',
    accessor: 'company.business_name',
    Cell: ({row, value}) => <TableLink to={`/companies/${row.original.company.uuid}`} value={value}/>
}/*,{
    Header: 'Cliente Finale',
    accessor: 'end_customer.business_name',
    Cell: ({row, value}) => <TableLink to={`/companies/${row.original.end_customer.uuid}`} value={value}/>
}*/
]

export default CompaniesTableConfig;