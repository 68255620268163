import React from 'react';
import Dashboard from '../pages/Dashboard/Dashboard';
import {PageBreadcrumbLink} from '../components/Theme/PageHeader/PageBreadcrumbLink';
import {companyRoutes} from "./private/companyRoutes";
import {projectRoutes} from "./private/projectRoutes";
import {serviceRoutes} from "./private/serviceRoutes";
import {packageRoutes} from "./private/packageRoutes";
import {offerRoutes} from "./private/OfferRoutes";
import Profile from "../pages/PersonalArea/Profile/Profile";
import Page404 from "../pages/Page404";
import {productRoutes} from "./private/productRoutes";
import {quoteRoutes} from "./private/quoteRoutes";
import {userRoutes} from "./private/userRoutes";
import {roleRoutes} from "./private/roleRoutes";
import {serverRoutes} from "./private/serverRoutes";

export const privateRoutes = [
    {
        index: true,
        path: '',
        element: <Dashboard/>,
    },
    ...companyRoutes,
    ...projectRoutes,
    ...serviceRoutes,
    ...packageRoutes,
    ...offerRoutes,
    ...productRoutes,
    ...quoteRoutes,
    ...userRoutes,
    ...roleRoutes,
    ...serverRoutes,
    {
        index: true,
        path: 'profile',
        element: <Profile/>,
        handle: {crumb: () => <PageBreadcrumbLink label='Profilo'/>},
    },
    {
        index: false,
        path: '*',
        element: <Page404/>,
    },
    {
        path: 'test',
        element: <>Test</>,
        handle: {crumb: () => <PageBreadcrumbLink to='/test' label='Test'/>},
        children: [{
            path: 'uno',
            element: <>Uno</>,
            handle: {crumb: () => <PageBreadcrumbLink to='/test/uno' label='Uno'/>}
        }, {
            path: 'due',
            element: <>Due</>,
            handle: {crumb: () => <PageBreadcrumbLink to='/test/due' label='Due'/>}
        }]
    }
];
