import {Field, Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {v4 as uuidv4} from "uuid";
import {useRequest} from "ahooks";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";
import SlideOverFormInput from "../../../Packages/components/SlideOverFormInput";
import {SlideOverFormGroup} from "../../../../components/UI/Forms/SlideOverFormGroup";
import {Select} from "../../../../components/UI/Forms/Select";
import expenseServices from "../../../../services/ExpenseService";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import customerService from "../../../../services/CustomerService";
import vehicleServices from "../../../../services/VehicleService";
import {useSanctum} from "react-sanctum";
import {useEffect} from "react";

export const ExpenseEntryForm = ({entry, expenseUuid, onCreate, onUpdate}) => {
    const {flashMsg} = useFlashMessage();
    const {user} = useSanctum()

    useEffect(() => {
        run({
            filter: {
                user_id: user?.id,
            }
        })
        // eslint-disable-next-line
    }, []);

    const {data: types} = useRequest(expenseServices.expenseTypeOptions);

    const {data: companies} = useRequest(customerService.companiesOptions);

    const {data: vehicles, run} = useRequest(vehicleServices.vehicleOptions, {
        manual: true
    });

    const initialValues = {
        company_id: entry?.company?.id ?? '',
        type: entry?.type?.value ?? '',
        price_amount: entry?.price_amount ?? '',
        departure: entry?.departure ?? '',
        arrival: entry?.arrival ?? '',
        note: entry?.note ?? '',
        date: entry?.date?.substring(0, 10) ?? '',
    }

    const validationSchema = Yup.object().shape({
        company_id: Yup.number().nullable().required(),
        type: Yup.string().nullable().required(),
        vehicle_id: Yup.string().nullable().when( 'type', (type, field) => {
            // eslint-disable-next-line
            if (type == 'travel') {
                return field.required();
            }
        }),
        departure: Yup.string().nullable().when('type', (type, field) => {
            // eslint-disable-next-line
            if (type == 'travel') {
                return field.required();
            }
        }),
        arrival: Yup.string().nullable().when('type', (type, field) => {
            // eslint-disable-next-line
            if (type == 'travel') {
                return field.required();
            }
        }),
        km_amount: Yup.string().nullable().when('type', (type, field) => {
            // eslint-disable-next-line
            if (type == 'travel') {
                return field.required();
            }
        }),
        price_amount: Yup.string().nullable().when('type', (type, field) => {
            // eslint-disable-next-line
            if (type == 'general') {
                return field.required();
            }
        }),
        date: Yup.string().nullable().required(),
        note: Yup.string().nullable(),
    })

    const createEntry = (data, setSubmitting) => {
        expenseServices.createExpenseEntry(expenseUuid, data)
            .then(res => {
                flashMsg('Spesa Inserita', undefined, uuidv4(), true)
                onCreate(res);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const updateEntry = (data, setSubmitting) => {
        expenseServices.updateExpenseEntry(expenseUuid, entry?.uuid, data)
            .then(res => {
                flashMsg('Spesa Modificata', undefined, uuidv4(), true)
                onUpdate(res);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            date: values.date,
            type: values.type,
            company_id: values.company_id,
            vehicle_id: values.vehicle_id,
            km_amount: values.km_amount,
            price_amount: values.price_amount,
            departure: values.departure,
            arrival: values.arrival,
            note: values.note
        }

        entry ? updateEntry(data, setSubmitting) : createEntry(data, setSubmitting)
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <FormikProvider value={formik}>
            <Form>
                <div className="space-y-8">
                    <div
                        className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 pb-4">

                        <div
                            className="grid grid-cols-1 gap-x-2 gap-y-3 sm:grid-cols-6">

                            <div className="col-span-full">
                                <SlideOverFormGroup required label='Data' name='date'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} required label='date'
                                               name='date' value={formik.values.date} type={'date'}/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>

                            <div className="col-span-full">
                                <SlideOverFormGroup required={true} label='Tipo' name='type'>
                                    <div className="mt-2">
                                        <Field as={Select} value={formik.values.type} name='type'>
                                            {types ?
                                                <>
                                                    <option className='text-center'>--SELEZIONA TIPO---</option>
                                                    {types?.map(item => (
                                                        <option key={item.value}
                                                                value={item.value}>{item.label}</option>
                                                    ))}
                                                </>

                                                : <option>Loading</option>
                                            }
                                        </Field>
                                    </div>
                                </SlideOverFormGroup>
                            </div>

                            <div className="col-span-full">
                                <SlideOverFormGroup required label='Cliente' name='company_id'>
                                    <div className="mt-2">
                                        <Field as={Select} value={formik.values.company_id} name='company_id'>
                                            {companies ?
                                                <>
                                                    <option className='text-center'>--SELEZIONA CLIENTE---</option>
                                                    {companies?.map(item => (
                                                        <option key={item.value}
                                                                value={item.value}>{item.label}</option>
                                                    ))}
                                                </>

                                                : <option>Loading</option>
                                            }
                                        </Field>
                                    </div>
                                </SlideOverFormGroup>
                            </div>

                            {formik.values?.type && formik.values?.type === "general" ?
                                <div className="col-span-full">
                                    <SlideOverFormGroup required label='Prezzo' name='price_amount'>
                                        <div className="mt-2">
                                            <Field as={SlideOverFormInput} required label='price_amount'
                                                   name='price_amount' value={formik.values.price_amount} type={'number'}/>
                                        </div>
                                    </SlideOverFormGroup>
                                </div>
                                : <></>}

                            {formik.values?.type && formik.values?.type === 'travel' ?
                                (<>
                                    <div className="col-span-full">
                                        <SlideOverFormGroup required label='Veicolo' name='vehicle_id'>
                                            <div className="mt-2">
                                                <Field as={Select} value={formik.values.vehicle_id} name='vehicle_id'>
                                                    {vehicles ?
                                                        <>
                                                            <option className='text-center'>--SELEZIONA VEICOLO---</option>
                                                            {vehicles?.map(item => (
                                                                <option key={item.value}
                                                                        value={item.value}>{item.label}</option>
                                                            ))}
                                                        </>

                                                        : <option>Loading</option>
                                                    }
                                                </Field>
                                            </div>
                                        </SlideOverFormGroup>
                                    </div>

                                    <div className="col-span-full">
                                        <SlideOverFormGroup required label='Partenza' name='departure'>
                                            <div className="mt-2">
                                                <Field as={SlideOverFormInput} required label='departure'
                                                       name='departure' value={formik.values.departure}/>
                                            </div>
                                        </SlideOverFormGroup>
                                    </div>

                                    <div className="col-span-full">
                                        <SlideOverFormGroup required label='Arrivo' name='arrival'>
                                            <div className="mt-2">
                                                <Field as={SlideOverFormInput} required label='arrival'
                                                       name='arrival' value={formik.values.arrival}/>
                                            </div>
                                        </SlideOverFormGroup>
                                    </div>

                                    <div className="col-span-full">
                                        <SlideOverFormGroup required label='Km' name='km_amount'>
                                            <div className="mt-2">
                                                <Field as={SlideOverFormInput} required label='km_amount'
                                                       name='km_amount' value={formik.values.km_amount} type={'number'}/>
                                            </div>
                                        </SlideOverFormGroup>
                                    </div>
                                </>)
                                : <></>}


                            <div className="col-span-full">
                                <SlideOverFormGroup label='Note' name='note'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} label='note'
                                               name='note' value={formik.values.note} type='textarea'/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flex items-center justify-end gap-x-6">
                    <SubmitButton label={entry ? 'Salva' : 'Inserisci'}/>
                </div>
            </Form>
        </FormikProvider>
    )
}

export default ExpenseEntryForm;