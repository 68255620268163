import {Disclosure} from "@headlessui/react";
import EuroFormat from "../../../components/UI/EuroFormat";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";
import StatusBadge from "../../../components/UI/StatusBadge";
import ExpenseEntriesTable from "./ExpenseEntries/ExpenseEntriesTable";
import expenseServices from "../../../services/ExpenseService";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";

export const ExpenseTableItem = ({expense, editAction, deleteAction}) => {
    const [updatedExpense, setUpdatedExpense] = useState(expense);

    const {flashMsg} = useFlashMessage();

    useEffect(() => {
        setUpdatedExpense(expense)
    }, [expense]);

    const addExpenseEntry = (entry) => {
        setUpdatedExpense({
            ...updatedExpense,
            amount: parseFloat(updatedExpense.amount) + parseFloat(entry.price_amount),
            expense_entries: [...updatedExpense?.expense_entries, entry]
        });
    }

    const updateExpenseEntry = (entry) => {
        let sum = 0;

        setUpdatedExpense({
            ...updatedExpense,
            expense_entries: updatedExpense?.expense_entries.map(e => e.uuid !== entry.uuid ? e : entry),
            amount: updatedExpense?.expense_entries.map(e => e.uuid !== entry.uuid ? sum += e.price_amount : sum -= e.price_amount, sum += entry.price_amount),
        })
    }

    const deleteExpenseEntry = (expenseUuid, entry) => {

        expenseServices.deleteExpenseEntry(expenseUuid, entry?.uuid)
            .then(res => {
                flashMsg('Spessa Eliminata', undefined, uuidv4(), true)
                setUpdatedExpense({
                    ...updatedExpense,
                    expense_entries: updatedExpense?.expense_entries.filter(e => e.uuid !== entry?.uuid),
                    amount: parseFloat(updatedExpense.amount) - parseFloat(entry?.price_amount),
                })
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
    }



    return (
        <>
            <div className='flex w-full'>
                <div className='rounded-xl border border-gray-300 my-2 mr-5 w-full'>
                    <Disclosure>
                        {({open}) => (
                            <>
                                <Disclosure.Button className="w-full">
                                    <div className="flex justify-between py-4 pl-4 pr-4 text-sm leading-6">
                                        <div className='flex basis-2/3'>
                                            <div className="flex flex-row gap-2 mr-3">
                                                <span
                                                    className="flex items-center font-medium">{expense?.month?.label}</span>
                                                <StatusBadge value={expense?.state?.label} color={expense?.state?.color}/>
                                            </div>
                                        </div>


                                        <div
                                            className='flex justify-between lg:items-center items-end lg:flex-row flex-col sm:gap-y-2'>
                                            <div className='flex items-center'>
                                            <span
                                                className="font-medium mr-1 items-end justify-end justify-items-end me-2">
                                            <EuroFormat value={updatedExpense?.amount}/>
                                        </span>
                                                <ChevronDownIcon
                                                    className={`${
                                                        open ? 'rotate-180 transform' : ''
                                                    } h-5 w-5 text-gray-500`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Button>


                                <Disclosure.Panel className="px-4 pt-4 pb-2">
                                    <ExpenseEntriesTable entries={updatedExpense?.expense_entries} expenseUuid={expense?.uuid}
                                                         onCreate={addExpenseEntry}
                                                         onUpdate={updateExpenseEntry}
                                                         onDelete={deleteExpenseEntry}/>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>

                </div>
                <div className='flex text-right'>
                    <button
                        onClick={editAction}
                        className="inline-flex items-center gap-x-2 rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                        <PencilSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                    </button>
                    <button
                        onClick={deleteAction}
                        className="inline-flex ps-3 items-center gap-x-2 rounded-md text-xs font-semibold text-red-600 hover:text-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                        <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                    </button>
                </div>
            </div>
        </>
    )
}

export default ExpenseTableItem;
