import api from '../configs/api.config';

const priceUrl = '/product-prices';
const productUrl = '/products'

const deletePrice = (id, productUuid, params) => api.delete(`${productUrl}/${id}${priceUrl}/${productUuid}`, params).then(res => res.data);
const createPrice = (uuid, params) => api.post(`${productUrl}/${uuid}${priceUrl}`, params).then(res => res.data);
const priceShow = (id, priceUuid, params) => api.get(`${productUrl}/${id}${priceUrl}/${priceUuid}`, params).then(res => res.data);
const updatePrice = (uuid, priceUuid, params) => api.put(`${productUrl}/${uuid}${priceUrl}/${priceUuid}`, params).then(res => res.data);

const swapPrices = (uuid, params) => api.post (`${productUrl}/${uuid}${priceUrl}/swap`, params).then(res => res.data);

const productPriceServices = {
    deletePrice,
    createPrice,
    priceShow,
    updatePrice,
    swapPrices,
}

export default productPriceServices