import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import React from "react";
import PermissionChecker from "../../Theme/PermissionChecker";

export const ActionButtonsSlideOver = ({editAction, deleteAction, canDelete = true, permissions}) => {
    return (
        <>
            <PermissionChecker permissions={permissions}>
                <div className='text-right'>
                    {editAction ? <button
                        onClick={editAction}
                        className="inline-flex items-center gap-x-2 rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                        <PencilSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                    </button> : <></>}
                    {canDelete ? <button
                        onClick={deleteAction}
                        className="inline-flex ps-3 items-center gap-x-2 rounded-md text-xs font-semibold text-red-600 hover:text-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                        <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                    </button> : <></>}
                </div>
            </PermissionChecker>
        </>
    )
}

export default ActionButtonsSlideOver