import React from 'react';
import { Login } from '../pages/Auth/Login';
import { ForgotPassword } from '../pages/Auth/ForgotPassword';
import { Navigate } from 'react-router-dom';
import { NewPassword } from '../pages/Auth/NewPassword';

export const publicRoutes = [{
  path: 'auth/login',
  element: <Login/>,
}, {
  path: 'auth/forgot-password',
  element: <ForgotPassword/>,
}, {
  path: 'auth/new-password/:token',
  element: <NewPassword/>,
}, {
  path: '/',
  index: true,
  element: <Navigate to="auth/login"/>,
}];
