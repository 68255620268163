import TableLink from "../../Buttons/TableLink";
import StatusBadge from "../../StatusBadge";

const PackagesTableConfig = [{
    Header: 'Ragione Sociale',
    accessor: 'company.business_name',
    Cell: ({row, value}) =>
        <div className='text-green-600 mt-2'>
            {/*<TableLink to={`/companies/${row.original.company.uuid}`} value={value}/>*/}
            <TableLink to={row.original.uuid} value={value}/>
        </div>

}
    , {
        Header: 'Progetto',
        accessor: 'project.name',
        Cell: ({row, value}) => <div className='mt-2 text-gray-500 text-sm'>
            <TableLink to={`/projects/${row.original.project?.uuid}`} value={value}/>
        </div>
    }, {
        Header: 'Stato',
        accessor: 'state.value',
        Cell: ({row}) =>
            <StatusBadge value={row.original.state.label.toUpperCase()} color={row.original?.state?.color}/>

    }
    , {
        Header: 'Utilizzato',
        accessor: 'used_amount',
        Cell: ({row, value}) => <div className={`mt-2 ${value === row.original.amount ? 'text-red-600' : 'text-gray-500'} text-sm`}>
            {row.original.used_amount + '/' + row.original.amount}
        </div>
    }, {
        Header: 'Giorni Rimanenti',
        accessor: 'expiration_days_left',
        Cell: ({row, value}) => <div className={`mt-2 ${value <= 14 ? 'text-red-600' : 'text-gray-500'} text-sm`}>
            {row.original.expiration_days_left}
        </div>
    }
]

export default PackagesTableConfig;