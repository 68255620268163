import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {SlideOverFormGroup} from "../../../components/UI/Forms/SlideOverFormGroup";
import SlideOverFormInput from "../../Packages/components/SlideOverFormInput";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import * as Yup from "yup";
import {v4 as uuidv4} from "uuid";
import vehicleServices from "../../../services/VehicleService";

const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().required(),
    license: Yup.string().nullable().required(),
    km_price: Yup.number().nullable().required(),
    note: Yup.string().nullable(),
})
export const VehicleForm = ({vehicle, onCreate, onUpdate}) => {
    const {flashMsg} = useFlashMessage();

    const initialValues = {
        name: vehicle?.name ?? '',
        license: vehicle?.license ?? '',
        km_price: vehicle?.km_price ?? '',
        note: vehicle?.note ?? '',
    }

    const createVehicle = (data, setSubmitting) => {
        vehicleServices.createVehicle(data)
            .then(res => {
                flashMsg('Veicolo Inserito', undefined, uuidv4(), true)
                onCreate(res);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const updateVehicle = (data, setSubmitting) => {
        vehicleServices.updateVehicle(vehicle?.uuid, data)
            .then(res => {
                flashMsg('Veicolo Modificato', undefined, uuidv4(), true)
                onUpdate(res);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            name: values.name,
            license: values.license,
            km_price: values.km_price,
            note: values.note
        }

        vehicle ? updateVehicle(data, setSubmitting) : createVehicle(data, setSubmitting)
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <FormikProvider value={formik}>
            <Form>
                <div className="space-y-8">
                    <div
                        className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 pb-4">

                        <div
                            className="grid grid-cols-1 gap-x-2 gap-y-3 sm:grid-cols-6">

                            <div className="col-span-full">
                                <SlideOverFormGroup required label='Nome' name='name'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} required label='name'
                                               name='name' value={formik.values.name}/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>

                            <div className="col-span-full">
                                <SlideOverFormGroup required label='Targa' name='license'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} required label='license'
                                               name='license' value={formik.values.license}/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>

                            <div className="col-span-full">
                                <SlideOverFormGroup required label='Prezzo al Km' name='km_price'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} required label='km_price' type='number'
                                               name='km_price' value={formik.values.km_price}/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>


                            <div className="col-span-full">
                                <SlideOverFormGroup label='Note' name='note'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} label='note'
                                               name='note' value={formik.values.note} type='textarea'/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flex items-center justify-end gap-x-6">
                    <SubmitButton label={vehicle ? 'Salva' : 'Inserisci'}/>
                </div>
            </Form>
        </FormikProvider>
    )
}

export default VehicleForm