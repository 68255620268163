import api from '../configs/api.config';
const revisionsUrl = '/quote-revisions'
const quotesUrl = '/quotes';
const revisionShow = (id, revisionUuid, params) => api.get(`${quotesUrl}/${id}${revisionsUrl}/${revisionUuid}`, params).then(res => res.data);
const revisionItemsSwap = (id, revisionUuid, params) => api.post(`${quotesUrl}/${id}${revisionsUrl}/${revisionUuid}/quote-items/swap`, params).then(res => res.data);
const addRevisionItem = (id, revisionUuid, params) => api.post(`${quotesUrl}/${id}${revisionsUrl}/${revisionUuid}/quote-items`, params).then(res => res.data);
const storeRevision = (id, params) => api.post(`${quotesUrl}/${id}${revisionsUrl}`, params).then(res => res.data);

const addProductPrice = (id, revisionUuid, itemUuid, params) => api.post(`${quotesUrl}/${id}${revisionsUrl}/${revisionUuid}/quote-items/${itemUuid}/quote-item-prices`, params).then(res=>res.data);
const updateProductPrice = (id, revisionUuid, itemUuid, priceUuid, params) => api.put(`${quotesUrl}/${id}${revisionsUrl}/${revisionUuid}/quote-items/${itemUuid}/quote-item-prices/${priceUuid}`, params).then(res=>res.data);
const updateProduct = (id, revisionUuid, itemUuid, params) => api.put(`${quotesUrl}/${id}${revisionsUrl}/${revisionUuid}/quote-items/${itemUuid}`, params).then(res => res.data);
const deleteItem = (id, revisionUuid, itemUuid, params) => api.delete(`${quotesUrl}/${id}${revisionsUrl}/${revisionUuid}/quote-items/${itemUuid}`, params).then(res => res.data);
const deletePriceItem = (id, revisionUuid, itemUuid, priceUuid, params) =>api.delete(`${quotesUrl}/${id}${revisionsUrl}/${revisionUuid}/quote-items/${itemUuid}/quote-item-prices/${priceUuid}`).then(res=>res.data);

export const revisionServices={
    revisionShow,
    revisionItemsSwap,
    storeRevision,
    addRevisionItem,
    deleteItem,
    deletePriceItem,
    updateProduct,
    updateProductPrice,
    addProductPrice,
}