import {DevicePhoneMobileIcon, GlobeAltIcon, KeyIcon, RectangleStackIcon, UserIcon, UserPlusIcon} from "@heroicons/react/24/outline";

const iconType = {
    project:
        <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
        >
            <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
        </svg>
    ,

    service:
        <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
            />
        </svg>,

    storage:
        <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
            />
        </svg>,

    database:
        <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
            />
        </svg>,

    package: <div className="flex items-center justify-center">
        <RectangleStackIcon className="w-12 h-12 text-gray-400" strokeWidth={1}/>
        <svg
            className="w-6 h-6 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m-8-8h16"
            />
        </svg>
    </div>,
    account: <div className="flex items-center justify-center">
        <UserIcon className="w-12 h-12 text-gray-400" strokeWidth={1}/>
        <svg
            className="w-6 h-6 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m-8-8h16"
            />
        </svg>
    </div>,

    site: <div className="flex items-center justify-center">
        <GlobeAltIcon className="w-12 h-12 text-gray-400" strokeWidth={1}/>
        <svg
            className="w-6 h-6 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m-8-8h16"
            />
        </svg>
    </div>,

    license: <div className="flex items-center justify-center">
        <KeyIcon className="w-12 h-12 text-gray-400" strokeWidth={1}/>
        <svg
            className="w-6 h-6 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m-8-8h16"
            />
        </svg>
    </div>,

    contact: <div className="flex items-center justify-center">
        <DevicePhoneMobileIcon className="w-12 h-12 text-gray-400" strokeWidth={1}/>
        <svg
            className="w-6 h-6 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m-8-8h16"
            />
        </svg>
    </div>,

    person: <div className="flex items-center justify-center">
        <UserPlusIcon className="w-12 h-12 text-gray-400" strokeWidth={1}/>
    </div>



}

const typeText = {
    project: 'Crea Nuovo Progetto',
    service: 'Crea Nuovo Servizio',
    package: 'Crea Nuovo Pacchetto',
    account: 'Crea Nuovo Account',
    storage: 'Crea Nuovo Storage',
    database: 'Crea Nuovo Database',
    site: 'Aggiungi Nuovo Sito',
    license: 'Aggiungi Nuova Licenza',
    person: 'Aggiungi Nuova Persona',
    contact: 'Aggiungi Nuovo Recapito',
}


export default function NewElement({type, onClick}) {
    return (
        <button
            onClick={onClick}
            type="button"
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-8 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
            {/*<CircleStackIcon className='mx-auto h-12 w-12 text-gray-400'/>*/}
            {iconType[type]}
            <span className="mt-2 block text-sm font-semibold text-gray-900">{typeText[type]}</span>
        </button>
    )
}
