import api from '../configs/api.config';
const accountUrl = '/accounts'
const createAccount = (model, uuid, params) => api.post(`/${model}/${uuid}${accountUrl}`, params).then(res => res.data);
const updateAccount = (model, uuid, accountUuid, params) => api.put(`/${model}/${uuid}${accountUrl}/${accountUuid}`, params).then(res => res.data);
const accountShow = (id, params) => api.get(accountUrl + '/' + id, params).then(res => res.data);
const accountTypeOptions = params => api.get(`/options/account-types`, params).then(res => res.data);

const deleteAccount = (model, uuid, accountUuid, params) => api.delete(`/${model}/${uuid}${accountUrl}/${accountUuid}`, params).then(res => res.data);

export const accountServices = {
    createAccount,
    updateAccount,
    accountShow,
    accountTypeOptions,
    deleteAccount,
}