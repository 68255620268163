import React, {useMemo} from 'react';
import {useRequest} from "ahooks";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import tableOptions from "../../configs/tableOptions";
import productServices from "../../services/ProductService";
import ProductsTableConfig from "../../components/UI/Configs/Tables/ProductsTableConfig";
import {useNavigate} from "react-router-dom";
import {createButtonConfig} from "../../components/UI/Configs/createButtonConfig";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";

const Products = () => {
    const navigation = useNavigate();
    const actions = [{
        ...createButtonConfig,
        action: (e => navigation('create')),
        permissions: ['products:edit'],
    }]
    const columns = useMemo(() => ProductsTableConfig, []);
    const {data = [], loading, run} = useRequest(productServices.products, {
        manual: true
    });


    return (
        <>
            <SectionHeader
                title={'Prodotti'}
                subtitle={'Elenco di tutti i prodotti registrati'}
                actions={actions}/>
            <div
                className="relative overflow-hidden rounded-lg bg-white pb-6 shadow sm:px-6 pt-6 text-center items-center justify-center">
                <PaginatedTable
                    data={data}
                    columns={columns}
                    onParamsChange={run}
                    options={tableOptions}
                    isLoading={loading}
                />
            </div>

        </>

    )

}

export default Products
