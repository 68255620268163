import React from "react";
import {Link} from "react-router-dom";
import InfoPopOver from "../../InfoPopOver";
import DateFormat from "../../DateFormat";

export const SlideOverLastActivities = ({pack}) => {
    return (
        <>
            <div>
                <h3 className="font-medium text-gray-900">Ultime Attività</h3>
                <div
                    className="mt-2">
                    {pack?.activities.length > 0 ? pack?.activities?.map(activity => (
                        <div key={activity.uuid}
                             className="flex items-center justify-between py-1">
                            <div className="flex items-center space-x-2">
                                <img
                                    alt=''
                                    src={activity?.user?.avatar}
                                    className="h-6 w-6 rounded-full"
                                />
                                <p className="text-sm font-medium text-gray-500">
                                    {activity?.user?.first_name}{' '}
                                    {activity?.user?.last_name}
                                </p>
                            </div>
                            <div className="flex items-center">
                                <p className="text-xs font-normal text-gray-900 w-40 text-center">{<DateFormat value={activity?.date}/>}</p>
                            </div>
                            <div className="flex items-center">
                                <p className="text-xs font-normal text-gray-900 w-40 text-center">{activity?.amount} h</p>

                            </div>
                            <div className="flex items-center">
                                <InfoPopOver>
                                    {activity?.note}
                                </InfoPopOver>
                            </div>
                        </div>
                    )) : <h4 className='font-normal text-sm py-3'>Non ci sono Attività</h4>}
                </div>
            </div>
            <div className='text-center'>
                <Link
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-green-900"
                    to={`/packages/${pack?.uuid}`}>
                    Mostra Tutto
                </Link>
            </div>
        </>
    )
}

export default SlideOverLastActivities