import {FunnelIcon} from "@heroicons/react/24/solid";

export const OffersFilter = ({setFilter}) => {
    return (
        <>
            <div className="relative col-start-1 row-start-1 py-4">
                <div className="mx-auto space-x-6 divide-x divide-gray-200 px-4 text-sm">
                    <div className="group flex items-center font-medium text-gray-700">
                    <FunnelIcon
                        className="mr-2 h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                    />
                        Filtra
                        <div className='mx-2'>
                            <input
                                type="text"
                                className="block w-full rounded-md border-0 px-2 py-0.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                placeholder="Cerca..."
                                onChange={e => setFilter(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OffersFilter;