import React from "react";
import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import ExpenseEntryForm from "./ExpenseEntryForm";

export const ExpenseEntrySlideOver = ({entry, expenseUuid, state, onClose, onUpdate, onCreate, onDelete}) => {

    return (
        <>
            <SlideOver title={entry ? 'Modifica Spesa' : 'Nuova Spesa'}
                       subtitle={entry ? 'Modifica i dettagli della spesa' : 'Inserisci i dettagli della spesa'}
                       state={state}
                       onClose={onClose}>
                <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                    {/*<ActionButtonsSlideOver editAction={e => setIsDisabled(false)} deleteAction={e => setShowModal(true)}/>*/}
                    <ExpenseEntryForm entry={entry}
                                      expenseUuid={expenseUuid}
                                      onCreate={onCreate}
                                      onUpdate={onUpdate}/>
                </div>
            </SlideOver>
        </>
    )
}

export default ExpenseEntrySlideOver;