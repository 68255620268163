import React from "react";
import SlideOver from "../../../components/UI/SlideOvers/SlideOver";
import ActivityForm from "./ActivityForm";

export const NewActivitySlideOver = (props) => {
    const {state, onClose, package_id, project_id, company_id, onCreate} = props;

    return (
        <SlideOver title={'Nuova Attività'}
                   subtitle={'Inserisci le informazioni sulla nuova attività'}
                   state={state}
                   onClose={onClose}>
            <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <ActivityForm
                    package_id={package_id}
                    project_id={project_id}
                    company_id={company_id}
                    onCreate={onCreate}/>
            </div>
        </SlideOver>
    )
}

export default NewActivitySlideOver;