import api from '../configs/api.config';

const activitiesUrl = '/activities';
const packagesUrl = '/packages';
const activities = params => api.get(activitiesUrl, {params}).then(res => res.data);
const activityShow = (id, activityUuid, params) => api.get(`${packagesUrl}/${id}${activitiesUrl}/${activityUuid}`, params).then(res => res.data);
const createActivity = (id, params) => api.post(`${packagesUrl}/${id}${activitiesUrl}`, params).then(res => res.data);
const updateActivity = (id, activityUuid, params) => api.put(`${packagesUrl}/${id}${activitiesUrl}/${activityUuid}`, params).then(res => res.data);
const deleteActivity = (id, activityUuid, params) => api.delete(`${packagesUrl}/${id}${activitiesUrl}/${activityUuid}`, params).then(res => res.data);

const activityServices = {
    activities,
    activityShow,
    createActivity,
    updateActivity,
    deleteActivity,
}

export default activityServices;