import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import {useParams} from "react-router-dom";
import {revisionServices} from "../../../../services/RevisionService";
import {FormGroup} from "../../../../components/UI/Forms/FormGroup";
import {FormInput} from "../../../../components/UI/Fields/FormInput";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";
import {PriceInput} from "../../../../components/UI/Forms/PriceInput";
import _ from "lodash";
import {DiscountInput} from "../../../../components/UI/Forms/DiscountInput";


const validationSchema = Yup.object().shape({
    is_default: Yup.string().nullable().required(),
    unit_price: Yup.number().nullable().required(),
    description: Yup.string().nullable().required(),
    discount: Yup.number().nullable().required(),
    add_to_product: Yup.string().nullable(),
})

export const RevisionProductPriceForm = ({price, revisionUuid, productUuid, onPriceUpdated, isDisabled, type, onProductPriceCreate}) => {
    const {flashMsg} = useFlashMessage();
    const {uuid} = useParams();


    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            unit_price: values.unit_price,
            description: values.description,
            is_default: values.is_default ?? false,
            discount: values.discount,
            add_to_product: values.add_to_product,
        }
            price ? updatePrice(data, setSubmitting) : addPrice(data, setSubmitting)
    }

    const updatePrice = (data, setSubmitting) => {

        revisionServices.updateProductPrice(uuid, revisionUuid, productUuid, price.uuid, data).then(res => {
            let myuuid = uuidv4();
            setSubmitting(false);
            onPriceUpdated(res, productUuid);
            flashMsg('Prezzo Aggiornato', undefined, myuuid, true)
        })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const addPrice = (data, setSubmitting) => {
        revisionServices.addProductPrice(uuid, revisionUuid, productUuid, data).then(res => {
            let myuuid = uuidv4();
            setSubmitting(false);
            onProductPriceCreate(res, productUuid)
            flashMsg('Prezzo Aggiunto', undefined, myuuid, true)
        })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const initialValues = {
        unit_price: price?.unit_price ?? '',
        description: price?.description ?? '',
        discount: price?.discount*100 ?? 0,
        is_default: price?.is_default ?? false,
        add_to_product: 0,
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <FormGroup required={true} label='Importo' name='unit_price'>
                        <div className="mt-2">
                            <Field as={PriceInput} required label='unit_price'
                                   name='unit_price' value={formik.values.unit_price}
                                   isDisabled={isDisabled}
                            />
                        </div>
                    </FormGroup>
                    <div className='mt-6'>
                        <FormGroup required={true} label='Sconto' name='discount'>
                            <div className="mt-2">
                                <Field as={DiscountInput} required label='discount'
                                       name='discount' value={formik.values.discount}
                                       isDisabled={isDisabled}
                                />
                            </div>
                        </FormGroup>
                    </div>
                    <div className='mt-6'>
                        <FormGroup required label='Descrizione' name='description'>
                            <div className="mt-2">
                                <FormInput
                                    name='description'
                                    type='textarea'
                                    isDisbaled={isDisabled}
                                    value={formik.values.description}
                                    onChange={event => formik.setFieldValue('description', event.target.value)}
                                    error={_.get(formik.errors, 'description')}
                                />
                            </div>
                        </FormGroup>
                    </div>

                    {!price ? <div className='mt-6'>
                        <FormGroup required label='Aggiungi anche al Prodotto' name='general_add'>
                            <div className="mt-2">
                                <input
                                    className='h-4 w-4 accent-green-600'
                                    name='add_to_product'
                                    defaultChecked={formik.values.add_to_product}
                                    onChange={event => formik.setFieldValue('add_to_product', event.target.checked)}
                                    error={_.get(formik.errors, 'add_to_product')}
                                    type={'checkbox'}/>
                            </div>
                        </FormGroup>
                    </div> : <></>
                    }

                    {!isDisabled ? <div className='mt-2 text-right'>
                        <SubmitButton label={price ? 'Aggiorna' : 'Aggiungi'}/>
                    </div> : <></>}

                </Form>
            </FormikProvider>
        </>
    )
}

export default RevisionProductPriceForm