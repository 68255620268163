import api from '../configs/api.config';

const customersUrl = '/companies';
const paymentConditionsUrl = '/options/payment-conditions'

const companies = params => api.get(customersUrl, params).then(res => res.data);

const companiesOptions = (params) => api.get('/options/companies', {params}).then(res => res.data);
const companyShow = (id, params) => api.get(customersUrl + '/' + id, params).then(res => res.data)
const createCustomer = params => api.post(customersUrl, params).then(res => res.data);
const updateCompany = (uuid, params) => api.put(`${customersUrl}/${uuid}`, params).then(res => res.data);
const deleteCompany = (id, params) => api.delete(`${customersUrl}/${id}`, params).then(res => res.data);
const paymentConditions = params => api.get(paymentConditionsUrl, params).then(res => res.data);


const customerService = {
    companies,
    createCustomer,
    companyShow,
    updateCompany,
    deleteCompany,
    paymentConditions,
    companiesOptions,
}

export default customerService;