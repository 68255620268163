import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {PriceInput} from "../../../components/UI/Forms/PriceInput";
import {FormGroup} from "../../../components/UI/Forms/FormGroup";
import {FormInput} from "../../../components/UI/Fields/FormInput";
import _ from "lodash";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {useParams} from "react-router-dom";
import productPriceServices from "../../../services/ProductPriceService";

const validationSchema = Yup.object().shape({
    is_default: Yup.string().nullable().required(),
    unit_price: Yup.number().nullable().required(),
    description: Yup.string().nullable().required(),
})

export const ProductPriceForm = ({price, revisionUuid, productUuid, onPriceUpdate, onPriceCreate, isDisabled}) => {
    const {flashMsg} = useFlashMessage();
    const {uuid} = useParams();

    console.log(onPriceUpdate)


    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            unit_price: values.unit_price,
            description: values.description,
            is_default: values.is_default ?? false
        }
        price ? updatePrice(data, setSubmitting) : addPrice(data, setSubmitting)
    }
    const updatePrice = (data, setSubmitting) => {

        productPriceServices.updatePrice(uuid ?? productUuid, price.uuid, data).then(res => {
            let myuuid = uuidv4();
            setSubmitting(false);
            onPriceUpdate(res);
            flashMsg('Prezzo Aggiornato', undefined, myuuid, true)
        })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const addPrice = (data, setSubmitting) => {
        productPriceServices.createPrice(uuid, data).then(res => {
            let myuuid = uuidv4();
            setSubmitting(false);
            onPriceCreate(res);
            flashMsg('Riga Creata', undefined, myuuid, true)
        })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const initialValues = {
        unit_price: price?.unit_price ?? '',
        description: price?.description ?? '',
        is_default: price?.is_default ?? false
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <FormGroup required={true} label='Importo' name='unit_price'>
                        <div className="mt-2">
                            <Field as={PriceInput} required label='unit_price'
                                   name='unit_price' value={formik.values.unit_price}
                                   isDisabled={isDisabled}
                            />
                        </div>
                    </FormGroup>
                    <div className='mt-6'>
                        <FormGroup required label='Descrizione' name='description'>
                            <div className="mt-2">
                                <FormInput
                                    name='description'
                                    type='textarea'
                                    isDisbaled={isDisabled}
                                    value={formik.values.description}
                                    onChange={event => formik.setFieldValue('description', event.target.value)}
                                    error={_.get(formik.errors, 'description')}
                                />
                            </div>
                        </FormGroup>
                    </div>

                    <div className='mt-6'>
                        <FormGroup label='Default' name='is_default'>
                            <div className="mt-2">
                                <input
                                    className='h-4 w-4 accent-green-600'
                                    name='is_default'
                                    disabled={isDisabled}
                                    defaultChecked={formik.values.is_default}
                                    onChange={event => formik.setFieldValue('is_default', event.target.checked)}
                                    error={_.get(formik.errors, 'is_default')}
                                    type={'checkbox'}/>
                            </div>
                        </FormGroup>
                    </div>

                    {!isDisabled ? <div className='mt-2 text-right'>
                        <SubmitButton label={price ? 'Aggiorna' : 'Aggiungi'}/>
                    </div> : <></>}

                </Form>
            </FormikProvider>
        </>
    )
}

export default ProductPriceForm