import React from 'react';
import { useSanctum } from 'react-sanctum';
import { Navigate } from 'react-router-dom';

function AuthMiddleware({ children, auth, redirect }) {
  const { authenticated } = useSanctum();

  if (authenticated !== null) {
    if (authenticated === auth) {
      return <>{children}</>;
    }
    return <Navigate to={{ pathname: redirect }} replace/>;
  }
  return <>LOADING</>;
}

export default AuthMiddleware;
