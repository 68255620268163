import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {FormInput} from "../../../components/UI/Fields/FormInput";
import customerService from "../../../services/CustomerService";
import {FormGroup} from '../../../components/UI/Forms/FormGroup';
import {SubmitButton} from '../../../components/UI/Forms/SubmitButton';
import CancelButton from "../../../components/UI/Buttons/CancelButton";
import {useNavigate, useParams} from "react-router-dom";
import {PaymentConditionsSelect} from "./PaymentConditionsSelect";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from 'uuid';

const setIsProvider = (state) => {
    if (state) {
        return '1'
    } else {
        return '0'
    }
}
export default function CompanyForm({company = undefined}) {
    const {flashMsg} = useFlashMessage();
    const {uuid} = useParams();
    const navigation = useNavigate();

    const createCompany = (data, setSubmitting) => {
        customerService.createCustomer(data)
            .then(() => {
                flashMsg('Azienda Creata', null, uuidv4(), true);
                navigation(-1);
            })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
            .finally(() =>
                setSubmitting(false))
    }

    const updateCompany = (data, setSubmitting) => {
        customerService.updateCompany(uuid, data)
            .then(() => {
                flashMsg('Azienda Modificata Con Successo', null, uuidv4(), true);
                navigation(-1);
            })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
            .finally(() =>
                setSubmitting(false))
    }

    const initialValues = {
        business_name: company?.business_name ?? '',
        vat_number: company?.vat_number ?? '',
        fiscal_code: company?.fiscal_code ?? '',
        payment_condition: company?.payment_condition?.id ?? '',
        is_provider: setIsProvider(company?.is_provider),
        sdi: company?.sdi ?? '',
        note: company?.note ?? '',
    }

    const handleSubmit = (values, {setSubmitting}) => {
        const data = {
            business_name: values.business_name,
            vat_number: values.vat_number,
            fiscal_code: values.fiscal_code,
            payment_condition_id: values.payment_condition,
            sdi: values.sdi,
            is_provider: values.is_provider,
            note: values.note
        }
        company ? updateCompany(data, setSubmitting) : createCompany(data, setSubmitting)
    }


    const validationSchema = Yup.object().shape({
        business_name: Yup.string().nullable().required(),
        vat_number: Yup.string().nullable(),
        fiscal_code: Yup.string().nullable().required(),
        payment_condition: Yup.string().nullable(),
        is_provider: Yup.boolean().nullable().required(),
        note: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });


    return (
        <>
            {/*<div className='mx-auto items-center justify-center max-w-7xl px-4'>*/}
            <FormikProvider value={formik}>
                <Form>
                    <div className="space-y-12">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div className='ms-4'>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">{company ? "Modifica Azienda" : 'Inserisci Nuova Azienda'}</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">{company ? 'Modifica' : 'Inserisci'} le
                                    informazioni dell'azienda</p>
                            </div>

                            <div
                                className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                <div className="sm:col-span-3">
                                    <FormGroup required label='Ragione Sociale' name='business_name'>
                                        <FormInput reuired label='business_name' name='business_name'
                                                   value={formik.values.business_name}/>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required={false} label='Partita IVA' name='vat_number'>
                                        <FormInput required={false} label='vat_number' name='vat_number'
                                                   value={formik.values.vat_number}/>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required label='Codice Fiscale' name='fiscal_code'>
                                        <FormInput label='fiscal_code' name='fiscal_code'
                                                   value={formik.values.fiscal_code}/>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup label='SDI' name='sdi'>
                                        <FormInput label='sdi' name='sdi' value={formik.values.sdi}/>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup label='Condizioni di Pagamento' name='payment_condition'>
                                        <div className="mt-2">
                                            <PaymentConditionsSelect value={formik.values.payment_condition}/>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required label='Provider' name='is_provider' className=''>
                                        <div className="mt-3" role='group'>
                                            <div
                                                className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                                <div key='1' className="flex items-center">
                                                    <Field type="radio" name="is_provider" value='1'
                                                           className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 accent-green-600"/>
                                                    <label
                                                        className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                        Sì
                                                    </label>
                                                </div>
                                                <div key='0' className="flex items-center">
                                                    <Field type="radio" name="is_provider" value='0'
                                                           className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 accent-green-600"/>
                                                    <label
                                                        className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="col-span-full">
                                    <FormGroup label='Note' name='note'>
                                        <div className="mt-2">
                                            <Field as={FormInput} type='textarea' label='note'
                                                   name='note' value={formik.values.note}/>
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <CancelButton/>
                        <SubmitButton label={company ? 'Salva' : 'Inserisci'}/>
                    </div>
                </Form>
            </FormikProvider>
            {/*</div>*/}
            {/*{notifyState && <ResponseNotify title={notifyText} subtitle={notifySubtext} state={notifyState}
                                           onClose={() => setNotifyState(false)} success={success}/>}*/}
        </>
    )
}