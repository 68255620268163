import {CopyToClipboard} from "react-copy-to-clipboard";
import {DocumentDuplicateIcon} from "@heroicons/react/24/outline";
import useFlashMessage from "../../FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";

export const CopyToClipboardButton = ({textToCopy, message = 'Testo Copiato'})=>{
    const {flashMsg} = useFlashMessage();
    const onCopySuccess = () =>{
        let myuuid = uuidv4();
        {textToCopy ?
            flashMsg(message, null, myuuid, true) : <></>
        }
    }
    return(
        <>
            <CopyToClipboard
                text={textToCopy}
                options={{format: 'text/plain'}}
                onCopy={onCopySuccess}
            >
                <button>
                    <DocumentDuplicateIcon className='h-5 w-5'/>
                </button>
            </CopyToClipboard>
        </>
    )
}

export default CopyToClipboardButton