import React, {useState} from "react";
import SlideOver from "../../../components/UI/SlideOvers/SlideOver";
import BillForm from "./BillForm";
import ActionButtonsSlideOver from "../../../components/UI/Buttons/ActionButtonsSlideOver";
import DeleteModal from "../../../components/UI/DeleteModal";

export const EditBillSlideOver = ({state, onClose, onUpdate, onDelete, bill, offerUuid, remainingAmount}) => {
    const [showModal, setShowModal] = useState(false)
    const handleUpdateBill = res => {
        onUpdate(res, offerUuid);
    }

    return (
        <>
            <SlideOver title={'Modifica Bill'}
                       subtitle={'Modifica le informazioni della bill'}
                       state={state}
                       size={'max-w-md'}
                       onClose={onClose}>
                <div className="space-y-6 py-6 sm:space-y-0 sm:py-0">
                    <ActionButtonsSlideOver deleteAction={() => setShowModal(true)}/>
                    <BillForm onBillUpdated={handleUpdateBill} bill={bill} offerUuid={offerUuid} remainingAmount={remainingAmount}/>
                </div>
            </SlideOver>
            <DeleteModal title={'questa riga'} onClose={() => setShowModal(false)}
                         onDelete={() => onDelete(offerUuid, bill.uuid)} state={showModal}/>
        </>

    )
}

export default EditBillSlideOver;