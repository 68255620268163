import {useNavigate} from "react-router-dom";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import React, {useMemo} from "react";
import {useRequest} from "ahooks";
import packageServices from "../../services/PackageService";
import PackagesTableConfig from "../../components/UI/Configs/Tables/PackagesTableConfig";
import tableOptions from "../../configs/tableOptions";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import {createButtonConfig} from "../../components/UI/Configs/createButtonConfig";

export const Packages = () => {
    const navigation = useNavigate();
    const actions = [{
        ...createButtonConfig,
        action: () => navigation('create'),
        permissions: ['packages:edit']
    }]
    const columns = useMemo(() => PackagesTableConfig, []);
    const {data = [], loading, run} = useRequest(packageServices.packages, {
        manual: true
    });

    return (
        <>
            <SectionHeader
                title={'Pacchetti'}
                subtitle={'Elenco di tutti i pacchetti registrati'}
                actions={actions}/>
            <div
                className="relative overflow-hidden rounded-lg bg-white pb-6  shadow sm:px-6 pt-6 text-center items-center justify-center">
                <PaginatedTable
                    data={data}
                    columns={columns}
                    onParamsChange={run}
                    options={tableOptions}
                    isLoading={loading}
                />
            </div>
        </>
    )
}

export default Packages;