import React from 'react';
import logo from '../../assets/images/dvsoft_full_white.svg'
import { Link } from 'react-router-dom';

export const MainNavbarLogo = () => {

  return (
    <Link className="flex-shrink-0" to='/'>
      <img
        className="h-8"
        src={logo}
        alt="DV Soft"
      />
    </Link>
  )

}
