import React, { Fragment } from 'react';
import { Disclosure } from '@headlessui/react';
import { MainNavbarProfileMenu } from './components/MainNavbarProfileMenu';
import { MainNavbarToggleMenuButton } from './components/MainNavbarToggleMenuButton';
import { MainNavbarMobileMenuContent } from './components/MainNavbarMobileMenuContent';
import { MainNavbarLogo } from './components/MainNavbarLogo';
import { useSanctum } from 'react-sanctum';
import { MainNavbarMenuContent } from './components/MainNavbarMenuContent';
import { navigation } from '../../../configs/navigation.config';
import {useNavigate} from "react-router-dom";

export const MainNavbar = () => {
  const navigate = useNavigate();
  const { user, signOut } = useSanctum()

  const userNavigation = [
    { name: 'Profilo', action: () => navigate('profile') },
    { name: 'Impostazioni', action: () => console.log('click') },
    { name: 'Esci', action: () => signOut() },
  ]

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <MainNavbarLogo/>
                <MainNavbarMenuContent navigation={navigation} className='hover:text-green-600'/>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <MainNavbarProfileMenu
                    user={user}
                    userNavigation={userNavigation}
                  />
                </div>
              </div>
              <MainNavbarToggleMenuButton open={open}/>
            </div>
          </div>

          <MainNavbarMobileMenuContent
            navigation={navigation}
            user={user}
            userNavigation={userNavigation}
          />

        </>
      )}
    </Disclosure>
  )

}
