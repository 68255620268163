import TableLink from "../../Buttons/TableLink";

const UsersTableConfig = [
    {
        Header: '',
        accessor: 'avatar',
        Cell: ({ row, value }) => <div className="flex items-center justify-center">
            <img
                className="h-7 w-7 rounded-full"
                src={row.original?.avatar}
                alt=""
            />
        </div>
    },{
    Header: 'Nome',
    accessor: 'full_name',
    Cell: ({ row, value }) =>
        <div className='mt-2 text-green-600'>
            <TableLink to={`/users/${row.original.uuid}`} value={value}/>
        </div>

},{
    Header: 'E-Mail',
    accessor: 'email',
    Cell: ({ row, value }) => <div className='text-gray-500 text-sm'>
        {row.original.email}
    </div>
},{
        Header: 'Ruolo',
        accessor: 'roles_label',
        Cell: ({ row, value }) => <div className='text-gray-500 text-sm'>
            {value}
        </div>
    },
]

export default UsersTableConfig;