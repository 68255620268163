import api from '../configs/api.config';

const packagesUrl = '/packages';

const packages = params => api.get(packagesUrl, {params}).then(res => res.data);
const packageShow = (id, params) => api.get(`${packagesUrl}/${id}`, params).then(res => res.data);
const createPackage = params => api.post(packagesUrl, params).then(res => res.data);
const updatePackage = (uuid, params) => api.put(`${packagesUrl}/${uuid}`, params).then(res => res.data);
const updateState = (uuid, params) => api.post(`${packagesUrl}/${uuid}/state`, params).then(res => res.data);
const deletePackage = (id, params) => api.delete(`${packagesUrl}/${id}`, params).then(res => res.data);

const packageServices = {
    packages,
    packageShow,
    createPackage,
    updatePackage,
    deletePackage,
    updateState,
}

export default packageServices;