import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup'
import { Form, FormikProvider, useFormik } from 'formik';
import { AuthLabel } from './components/AuthLabel';
import { AuthInput } from './components/AuthInput';
import _ from 'lodash';
import { ErrorAlert } from '../../components/UI/Alerts/ErrorAlert';
import { SuccessAlert } from '../../components/UI/Alerts/SuccessAlert';
import AuthService from '../../services/AuthService';

export const NewPassword = () => {

  const [newPasswordResponse, setNewPasswordResponse] = useState(undefined)
  const { token } = useParams();

  const initialValues = {
    email: '',
    password: '',
    password_confirmation: ''
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().min(8).required(),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null],
      "Passwords does not match")
  })

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setNewPasswordResponse(undefined)
    AuthService.resetPassword(values.email,
      token || '',
      values.password,
      values.password_confirmation)
    .then(res => {
      setNewPasswordResponse(res)
      resetForm()
    })
    .catch(err => setNewPasswordResponse(err))
    .finally(() => setSubmitting(false))
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit
  })

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6">
        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">Reset password</h2>
        <div>
          <AuthLabel htmlFor='email' label='Email'/>
          <AuthInput
            name='email'
            required
            type='email'
            autoComplete='email'
            value={formik.values.email}
            onChange={event => formik.setFieldValue('email', event.target.value)}
            error={_.get(formik.errors, 'email')}
          />
        </div>
        <div>
          <AuthLabel htmlFor='password' label='New password'/>
          <AuthInput
            name='password'
            required
            type='password'
            value={formik.values.password}
            onChange={event => formik.setFieldValue('password', event.target.value)}
            error={_.get(formik.errors, 'password')}
          />
        </div>
        <div>
          <AuthLabel htmlFor='password_confirmation' label='Confirm new password'/>
          <AuthInput
            name='password_confirmation'
            required
            type='password'
            value={formik.values.password_confirmation}
            onChange={event => formik.setFieldValue('password_confirmation', event.target.value)}
            error={_.get(formik.errors, 'password_confirmation')}
          />
        </div>

        <div className="flex items-center justify-end">
          <div className="text-sm leading-6">
            <Link to='/auth/login' className="font-semibold text-indigo-600 hover:text-indigo-500">
              Back to sign in
            </Link>
          </div>
        </div>

        <div>
          <button
            disabled={formik.isSubmitting ?? !formik.isValid}
            type="submit"
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {formik.isSubmitting ? 'Updating password' : 'Reset password'}
          </button>
        </div>

        {newPasswordResponse
          ? _.get(newPasswordResponse, 'errors')
            ? <ErrorAlert title={newPasswordResponse.message}/>
            : <SuccessAlert title={newPasswordResponse.message}/>
          : <></>}
      </Form>
    </FormikProvider>


  )

}
