import TableLink from "../../../components/UI/Buttons/TableLink";
import React, {useMemo, useState} from "react";
import Toggle from "./Table/Toggle";
import BillBadge from "./Table/BillBadge";
import NewBillSlideOver from "./NewBillSlideOver";
import {PlusCircleIcon} from "@heroicons/react/24/outline";
import EuroFormat from "../../../components/UI/EuroFormat";
import PermissionChecker from "../../../components/Theme/PermissionChecker";

const getSomma = (values) => {
    let sum = 0;
    values?.forEach((value) => {
        sum += value;
    });
    return sum;
};

const getDaFatturare = (offer) => {
    let somma = 0;

    offer?.bills?.forEach(bill => {
        somma += bill.amount
    });

    return Math.abs(offer?.accepted_price - somma);
};


export default function OffersTable({data, filter, onUpdate, onCreate, onDelete, permissions}) {
    const [showSlideOver, setShowSlideOver] = useState(false);
    const [offerData, setOfferData] = useState('')

    const [show, setShow] = useState({
        current: false,
        next: false,
        created: false,
        sent: false,
        paid: false,
        down: false
    })


    const cellValue = (offer, field = false, state) => {
        const filteredBills = offer?.bills?.filter((bill) => bill.states.state.value == state);

        if (!field) {
            if (filteredBills?.length > 1) {
                return <BillBadge value={getSomma(filteredBills.map(bill => bill.amount))}/>;
            } else if (filteredBills?.length === 1) {
                return <BillBadge value={filteredBills[0].amount} bill={filteredBills[0]}
                                  offer={offer} onUpdate={onUpdate} onDelete={onDelete}/>;
            }
        } else {
            if (filteredBills?.length > 1) {
                return filteredBills.map((bill) => (
                    <div key={bill.id} className='py-1'>
                        <BillBadge value={bill.amount} clickable bill={bill} offer={offer} onUpdate={onUpdate}
                                   onDelete={onDelete}/>
                    </div>
                ));
            } else if (filteredBills?.length === 1) {
                return <BillBadge value={filteredBills[0].amount} clickable bill={filteredBills[0]}
                                  offer={offer} onUpdate={onUpdate} onDelete={onDelete}/>;
            }
        }
    };


    const filteredData = useMemo(() => data?.filter((offer) => offer?.company?.business_name?.toUpperCase().includes(filter?.toUpperCase())
        || offer?.end_customer?.business_name.toUpperCase().includes(filter?.toUpperCase())
        || offer?.project?.name.toUpperCase().includes(filter?.toUpperCase())), [filter, data])

    return (
        <>
            <div className="px-4 w-full">
                <div className="flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                        N.
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Cliente
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Cliente Finale
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Progetto
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Descrizione
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        P. Accettato
                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Da Fatturare
                                    </th>
                                    <PermissionChecker permissions={permissions}>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                        </th>
                                    </PermissionChecker>

                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                        <div className='flex flex-col items-center '>
                                            <span>Fatturare PM</span>
                                            <Toggle onChange={() => setShow({...show, next: !show.next})}/>
                                        </div>

                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                        <div className='flex flex-col items-center '>
                                            <span>Fatturare FM</span>
                                            <Toggle onChange={() => setShow({...show, current: !show.current})}/>
                                        </div>

                                    </th>


                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                        <div className='flex flex-col items-center '>
                                            <span>Fattura Creata</span>
                                            <Toggle onChange={() => setShow({...show, created: !show.created})}/>
                                        </div>

                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                        <div className='flex flex-col items-center '>
                                            <span>Fattura Inviata</span>
                                            <Toggle onChange={() => setShow({...show, sent: !show.sent})}/>
                                        </div>

                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                        <div className='flex flex-col items-center '>
                                            <span>Incassati</span>
                                            <Toggle onChange={() => setShow({...show, paid: !show.paid})}/>
                                        </div>

                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                    >
                                        <div className='flex flex-col items-center '>
                                            <span>Scalati Da Anticipi</span>
                                            <Toggle onChange={() => setShow({...show, down: !show.down})}/>
                                        </div>

                                    </th>
                                    <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Note
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {filteredData
                                    .map((offer) => (
                                        (<tr key={offer.uuid}>
                                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-left text-sm text-gray-500 sm:pl-0">
                                                <TableLink to={offer.uuid} value={offer.full_number}/>
                                            </td>
                                            <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                                                <TableLink to={`/companies/${offer.company.uuid}`}
                                                           value={offer?.company?.business_name}/>
                                            </td>
                                            <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                                                <TableLink
                                                    to={`/companies/${offer?.end_customer?.uuid}`}
                                                    value={offer?.end_customer?.business_name}/></td>
                                            <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                                                {offer?.project ?
                                                    <TableLink
                                                        to={`/projects/${offer?.project?.uuid}`}
                                                        value={offer?.project?.name}/>
                                                    :
                                                    '---'}
                                            </td>
                                            <td className="truncate whitespace-nowrap max-w-xs px-2 py-2 text-left text-sm text-gray-900">{offer?.description}</td>
                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{offer.states.state.value == 'accepted' ?
                                                <EuroFormat value={offer?.accepted_price}/> : '---'}</td>
                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{offer.states.state.value == 'accepted' ?
                                                <BillBadge color='red' value={getDaFatturare(offer)}/>
                                                : '---'}</td>
                                            <PermissionChecker permissions={permissions}>
                                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                    <button
                                                        onClick={() => (setShowSlideOver(true), setOfferData(offer))}>
                                                        <PlusCircleIcon className='h-5 w-5 text-green-600'/>
                                                    </button>
                                                </td>
                                            </PermissionChecker>

                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                {cellValue(offer, show.next, 'next_month')}
                                            </td>
                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                {
                                                    cellValue(offer, show.current, 'current_month')
                                                }
                                            </td>
                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                {cellValue(offer, show.created, 'invoice_created')}
                                            </td>
                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                {cellValue(offer, show.sent, 'invoice_sent')}
                                            </td>
                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                {cellValue(offer, show.paid, 'invoice_paid')}
                                            </td>
                                            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                                {cellValue(offer, show.down, 'down_payment')}
                                            </td>
                                            <td className="truncate whitespace-nowrap max-w-xs px-2 py-2 text-left text-sm text-gray-900">{offer?.note}</td>
                                        </tr>)
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <NewBillSlideOver offer={offerData} state={showSlideOver} onClose={() => setShowSlideOver(false)}
                                                onBillCreated={onCreate}/>

        </>
    )
}
