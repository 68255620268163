import React from 'react';
import { HomeIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

export const PageBreadcrumbs = ({ crumbs }) => {

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <div>
              <Link to="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true"/>
              </Link>
            </div>
          </div>
        </li>
        {crumbs.map((crumb, index) => <li key={index}>{crumb}</li>)}
      </ol>
    </nav>
  )

}
