import React from "react";
import {PageBreadcrumbLink} from "../../components/Theme/PageHeader/PageBreadcrumbLink";
import ProductsWrapper from "../../components/Wrappers/ProductsWrapper";
import ProductWrapper from "../../components/Wrappers/ProductWrapper";
import Products from "../../pages/Products/Products";
import {ProductShow} from "../../pages/Products/ProductShow";
import NewProduct from "../../pages/Products/NewProduct";
import EditProduct from "../../pages/Products/EditProduct";
import PermissionChecker from "../../components/Theme/PermissionChecker";

export const productRoutes = [
    {
        path: '/products',
        element: <><ProductsWrapper/></>,
        handle: {
            crumb: () => <PageBreadcrumbLink to="/products" label="Prodotti"/>
        },
        children: [{
            path: ':uuid',
            element: <><ProductWrapper/></>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Dettagli'/>
            },
            children: [{
                path: 'edit',
                element: <><PermissionChecker permissions={['products:edit']} navigateTo={'/404'}><EditProduct/></PermissionChecker></>,
                handle: {
                    crumb: () => <PageBreadcrumbLink label='Modifica'/>
                }
            }, {
                path: '',
                element: <><ProductShow/></>,
            }]
        }, {
            path: 'create',
            element: <><PermissionChecker permissions={['products:edit']} navigateTo={'/404'}> <NewProduct/></PermissionChecker></>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Crea'/>
            }
        }, {
            path: '',
            element: <><Products/></>,
        }]
    },
];