import {FlashMessagesContext} from "./FlashMessagesContext";
import {useState} from "react";

export default function FlashMessagesContextProvider({children}) {

    const [flashMessages, setFlashMessages] = useState([])
    const pushMessages = (title, description, uuid, success) => {
        setFlashMessages(fm => {
            const newFlashMessagesArray = [...fm, {uuid, title, state: true, description, success}]
            dismissTimeOut(uuid);
            return newFlashMessagesArray;
        });
    };

    const deleteMessage = uuid => {
        setFlashMessages(msgs => msgs.filter(msg => msg.uuid !== uuid))
    }
    const dismissTimeOut = uuidToDismiss => setTimeout(() => {
        setNotificationState(uuidToDismiss);
        //deleteMessage(uuidToDismiss)
    }, 4000);
    const setNotificationState = (uuid) => {
        setFlashMessages(msgs =>
            msgs.map(msg => msg.uuid === uuid ? {...msg, state: false} : msg)
        )
    }

    const defaultValue = {
        flashMessages,
        pushMessages,
        deleteMessage
    }

    return (
        <FlashMessagesContext.Provider value={defaultValue}>
            {children}
        </FlashMessagesContext.Provider>
    )
}