import SlideOver from "../../../components/UI/SlideOvers/SlideOver";
import {ExpenseForm} from "./ExpenseForm";
import React, {useState} from "react";
import DeleteModal from "../../../components/UI/DeleteModal";

export const ExpenseSlideOver = ({expense, state, onClose, onUpdate, onCreate, onDelete}) => {
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <SlideOver title={expense ? 'Modifica Rimborso' : 'Nuovo Rimborso'}
                       subtitle={expense ? 'Modifica i dettagli del Rimborso' : 'Inserisci i dettagli del rimborso'}
                       state={state}
                       onClose={onClose}>
                <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                    <ExpenseForm expense={expense} onUpdate={onUpdate} onCreate={onCreate}/>
                </div>
            </SlideOver>

            <DeleteModal title={expense?.name}
                         onClose={() => setShowModal(false)}
                         onDelete={() => onDelete(expense?.uuid)}
                         state={showModal}/>
        </>
    )
}

export default ExpenseSlideOver;