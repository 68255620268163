import {useNavigate, useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import {offerServices} from "../../services/OfferService";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import React, {useState} from "react";
import OfferShowInfo from "./components/OfferShowInfo";
import BillsTable from "./components/BillsTable";
import NewBillSlideOver from "./components/NewBillSlideOver";
import useFlashMessage from "../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";
import {billServices} from "../../services/BillService";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import SectionContent from "../../components/Theme/PageSections/SectionContent";

export const OfferShow = () => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();
    const navigation = useNavigate();
    const [showSlideOver, setShowSlideOver] = useState(false);

    const {data: offer = undefined, loading, mutate} = useRequest(offerServices.offerShow, {
        defaultParams: [uuid],
    })

    const handleBillUpdate = bill => {
        mutate({
            ...offer,
            bills: offer.bills.map(b => b.uuid === bill.uuid ? bill : b)
        })
    }

    const handleBillCreate = (offerUuid, bill) => {
        mutate({
            ...offer,
            bills: [...offer.bills, bill]
        })
    }

    const handleStateUpdate = state => {
        mutate({
            ...offer,
            states: {
                available_states: state.available_states,
                state: state.state
            }
        })
    }

    const handleBillDelete = billUuid => {
        let myuuid = uuidv4();
        billServices.deleteBill(uuid, billUuid).then(res => {
                flashMsg(res.message, null, myuuid, true)
                mutate({
                    ...offer,
                    bills: offer.bills.filter((b) => b.uuid !== billUuid)
                })
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
    }

    const deleteHandler = () => {
        let myuuid = uuidv4();
        offerServices.deleteOffer(uuid).then(res => {
                flashMsg(res.message, null, myuuid, true)
                navigation(-1)
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
    }


    return (
        <>
            <LoadingSpinner loading={loading}>
                <SectionHeader title={'Dettagli Fatturazione'} subtitle={offer?.billable?.label}/>
                <SectionContent
                    left={
                        <div
                            className="relative overflow-hidden rounded-lg bg-white px-4 pb-6  shadow sm:px-4 sm:pt-4 py-6 basis-1/3">
                            <OfferShowInfo data={offer} onStateUpdate={handleStateUpdate} onDelete={deleteHandler}/>
                        </div>
                    }
                    right={
                        <div
                            className="relative overflow-hidden rounded-lg bg-white px-4 pb-6  shadow sm:px-4 sm:pt-4 py-6 flex-1">
                            <BillsTable offer={offer} onBillUpdated={handleBillUpdate}
                                        onBillDelete={handleBillDelete}
                                        action={{'action': () => setShowSlideOver(true), 'permissions': ['bills:edit']}}
                                        permissions={['bills:edit']}/>
                        </div>
                    }
                />
            </LoadingSpinner>

            <NewBillSlideOver offer={offer} state={showSlideOver} onClose={() => setShowSlideOver(false)}
                              onBillCreated={handleBillCreate}/>
        </>
    )
}

export default OfferShow;