import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup'
import { AuthLabel } from './components/AuthLabel';
import { AuthInput } from './components/AuthInput';
import { AuthCheckbox } from './components/AuthCheckbox';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useSanctum } from 'react-sanctum';
import { ErrorAlert } from '../../components/UI/Alerts/ErrorAlert';

export const Login = () => {

  const { signIn } = useSanctum();
  const [signInError, setSignInError] = useState(null);

  const initialValues = {
    email: '',
    password: '',
    remember_me: false,
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().nullable().required(),
    password: Yup.string().nullable().required(),
    remember_me: Yup.boolean().nullable().notRequired()
  })

  const handleSubmit = (values, { setSubmitting }) => {
    setSignInError(null);
    signIn(values.email, values.password, values.remember_me)
    .then(res => true)
    .catch(err => setSignInError(err.response.data.message))
    .finally(() => setSubmitting(false));
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit
  });

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6">
        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in</h2>
        <div>
          <AuthLabel htmlFor='email' label='Email'/>
          <AuthInput
            name='email'
            required
            type='email'
            autoComplete='email'
            value={formik.values.email}
            onChange={event => formik.setFieldValue('email', event.target.value)}
            error={_.get(formik.errors, 'email')}
          />
        </div>

        <div>
          <AuthLabel htmlFor='password' label='Password'/>
          <AuthInput
            name='password'
            required
            type='password'
            autoComplete='current-password'
            value={formik.values.password}
            onChange={event => formik.setFieldValue('password', event.target.value)}
            error={_.get(formik.errors, 'password')}
          />
        </div>

        <div className="flex items-center justify-between">
          <AuthCheckbox
            label='Remember me'
            name='remeber_me'
            checked={formik.values.remember_me}
            onChange={event => formik.setFieldValue('remember_me', event.target.checked)}
          />

          <div className="text-sm leading-6">
            <Link to='/auth/forgot-password' className="font-semibold text-indigo-600 hover:text-indigo-500">
              Forgot password?
            </Link>
          </div>
        </div>

        <div>
          <button
            disabled={formik.isSubmitting ?? !formik.isValid}
            type="submit"
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {formik.isSubmitting ? 'Checking credentials' : 'Sign In'}
          </button>
        </div>

        {signInError ? <ErrorAlert title={signInError}/> : <></>}

      </Form>
    </FormikProvider>
  )

}
