import api from '../configs/api.config';

const contactUrl = '/contacts'

const contacts = params => api.get(contactUrl, params).then(res => res.data);
const createContact = (model, uuid, params) => api.post(`/${model}/${uuid}${contactUrl}`, params).then(res => res.data);
const updateContact = (model, uuid, contactUuid, params) => api.put(`/${model}/${uuid}${contactUrl}/${contactUuid}`, params).then(res => res.data);
const contactShow = (id, params) => api.get(contactUrl + '/' + id, params).then(res => res.data);
const contactOptions = params => api.get(`/options${contactUrl}`, params).then(res => res.data);
const deleteContact = (model, uuid, contactUuid, params) => api.delete(`/${model}/${uuid}${contactUrl}/${contactUuid}`, params).then(res => res.data);

export const contactServices = {
    contacts,
    createContact,
    updateContact,
    deleteContact,
    contactShow,
    contactOptions,
}