import api from '../configs/api.config';

const offerUrl = '/offers';


const offers = params => api.get(offerUrl, params).then(res=> res.data);
const createOffer = (params) => api.post(offerUrl, params).then(res => res.data);
const offerShow = (id, params) => api.get(offerUrl + '/' + id, params).then(res => res.data);
const offerStates = (params) => api.get('/options/bill-states', params).then(res => res.data);
const offerBills = (id, params) => api.get(offerUrl + '/' + id +'/bills', params).then(res => res.data);
const updateOffer = (uuid, params) => api.put(`${offerUrl}/${uuid}`, params).then(res => res.data);
const updateState = (uuid, params) => api.post(`${offerUrl}/${uuid}/state`, params).then(res => res.data);
const deleteOffer = (uuid, params) => api.delete(`${offerUrl}/${uuid}`, params).then(res => res.data);
export const offerServices = {
    offers,
    createOffer,
    updateOffer,
    offerShow,
    deleteOffer,
    offerBills,
    offerStates,
    updateState,
}