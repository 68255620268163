import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";
import SlideOverFormInput from "../../../Packages/components/SlideOverFormInput";
import {SlideOverFormGroup} from "../../../../components/UI/Forms/SlideOverFormGroup";
import {contactServices} from "../../../../services/ContactService";

export const ContactForm = ({contact, model, modelUuid, onCreate, onUpdate, isDisabled}) => {
    const {flashMsg} = useFlashMessage();

    const initialValues = {
        label: contact?.label ?? '',
        value: contact?.value ?? '',
        note: contact?.note ?? '',
    }

    const createContact = (data, setSubmitting) => {
        contactServices.createContact(model, modelUuid, data)
            .then(res => {
                flashMsg('Contatto Creato', undefined, uuidv4(), true)
                onCreate(res, modelUuid);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const updateContact = (data, setSubmitting) => {
        contactServices.updateContact(model, modelUuid, contact?.uuid, data)
            .then(res => {
                flashMsg('Contatto Modificato', undefined, uuidv4(), true)
                onUpdate(res, modelUuid);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            label: values.label,
            value: values.value,
            note: values.note
        }

        contact ? updateContact(data, setSubmitting) : createContact(data, setSubmitting)
    }


    const validationSchema = Yup.object().shape({
        label: Yup.string().nullable().required(),
        value: Yup.string().nullable().required(),
        note: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <FormikProvider value={formik}>
            <Form>
                <div className="space-y-8">
                    <div
                        className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 pb-4">

                        <div
                            className="grid grid-cols-1 gap-x-2 gap-y-3 sm:grid-cols-6">

                            <div className="col-span-full">
                                <SlideOverFormGroup required label='Label' name='label'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} required label='label'
                                               name='label' value={formik.values.label} isDisabled={isDisabled}/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>

                            <div className="col-span-full">
                                <SlideOverFormGroup required label='Valore' name='value'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} required label='value'
                                               name='value' value={formik.values.value} isDisabled={isDisabled}/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>


                            <div className="col-span-full">
                                <SlideOverFormGroup label='Note' name='note'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} label='note'
                                               name='note' value={formik.values.note} type='textarea' isDisabled={isDisabled}/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                {!isDisabled && <div className="mt-4 flex items-center justify-end gap-x-6">
                    <SubmitButton label={contact ? 'Salva' : 'Crea'}/>
                </div>}
            </Form>
        </FormikProvider>
    )
}

export default ContactForm