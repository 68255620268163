import React from "react";
import {useFormikContext} from "formik";

export const FormInput = ({
                              name,
                              type = "text",
                              autoComplete = undefined,
                              required,
                              value,
                              onChange,
                              isDisabled = false,
                          }) => {
    const {setFieldValue} = useFormikContext()
    return (
        <>

            {type !== 'textarea' ? <input
                    id={name}
                    name={name}
                    type={type}
                    value={value}
                    disabled={isDisabled}
                    onChange={event => setFieldValue(name, event.target.value)}
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2  focus:ring-green-600 sm:text-sm sm:leading-6"
                />
                :
                <div className="relative mt-2 rounded-md shadow-sm">
                    <textarea
                        id={name}
                        name={name}
                        autoComplete={autoComplete}
                        required={required}
                        value={value}
                        onChange={onChange}
                        disabled={isDisabled}
                        className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2  focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                </div>
            }
        </>
    )
}