import React from "react";

export const Divider = ()=>{
    return(
        <div className="relative mt-4 mb-8">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-200"/>
            </div>
        </div>
    )
}

export default Divider;