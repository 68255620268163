import TableLink from "../../Buttons/TableLink";
import {CircleStackIcon, ComputerDesktopIcon, EnvelopeIcon, ServerStackIcon} from "@heroicons/react/24/outline";
import StatusBadge from "../../StatusBadge";
import React from "react";

const typeIcon = {
    hosting: <ServerStackIcon className='h-5 w-5'/>,
    newsletter: <EnvelopeIcon className='h-5 w-5'/>,
    storage: <CircleStackIcon className='h-5 w-5'/>,
    vps: <ComputerDesktopIcon className='h-5 w-5'/>
}

const CompaniesTableConfig = [{
    Header: 'Servizio',
    accessor: 'type.label',
    Cell: ({row, value}) => (
        <>
            <div className="flex items-start gap-x-3 mt-2 text-green-600">
                {typeIcon[row.original.type?.value]}
                <TableLink to={row.original.uuid} value={value}/>
            </div>
        </>

    )
},
    {
        Header: 'Stato',
        accessor: 'state.value',
        Cell: ({row, value}) => <StatusBadge value={row.original?.state?.label?.toUpperCase()} color={row.original.state.color}/>
    },
    {
        Header: 'Cliente',
        accessor: 'company.business_name',
        Cell: ({row, value}) => <TableLink to={`/companies/${row.original.company.uuid}`} value={value}/>
    }
]

export default CompaniesTableConfig;