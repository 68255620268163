import React from "react";
import Packages from "../../pages/Packages/Packages";
import PackageShow from "../../pages/Packages/PackageShow";
import NewPackage from "../../pages/Packages/NewPackage";
import EditPackage from "../../pages/Packages/EditPackage";
import {PageBreadcrumbLink} from "../../components/Theme/PageHeader/PageBreadcrumbLink";
import PackagesWrapper from "../../components/Wrappers/PackagesWrapper";
import PackageWrapper from "../../components/Wrappers/PackageWrapper";
import PermissionChecker from "../../components/Theme/PermissionChecker";

export const packageRoutes=[
    {
        path: '/packages',
        element: <PackagesWrapper/>,
        handle: { crumb: () => <PageBreadcrumbLink to='/packages' label='Pacchetti'/> },
        children: [{
            path: ':uuid',
            element: <PackageWrapper/>,
            handle: { crumb: () => <PageBreadcrumbLink label='Show'/> },
            children: [{
                path: 'edit',
                element: <PermissionChecker permissions={['packages:edit']} navigateTo={'/404'}><EditPackage/></PermissionChecker>,
                handle: { crumb: () => <PageBreadcrumbLink label='Modifica'/> },
            },{
                path: '',
                element: <PackageShow/>,
            }]
        },{
            path: 'create',
            element: <PermissionChecker permissions={['packages:edit']} navigateTo={'/404'}><NewPackage/></PermissionChecker>,
            handle: { crumb: () => <PageBreadcrumbLink label='Crea'/> },
        },
            {
                path: '',
                element: <Packages/>,
            }]
    },
]