import React from "react";
import DateFormat from "../../DateFormat";

const ProjectActivitiesTableConfig = [{
    Header: <h5 className='text-center font-normal'>Persone</h5>,
    accessor: 'user.first_name',
    disableSortBy: true,
    className: 'w-48',
    Cell: ({row}) => <div className="flex items-center space-x-2 mt-3">
        <img
            alt=''
            src={row.original?.user?.avatar}
            className="h-6 w-6 rounded-full"
        />
        <p className="text-sm font-medium text-gray-500">
            {row.original?.user?.first_name}{' '}
            {row.original?.user?.last_name}
        </p>
    </div>
},
    {
        Header: <h5 className='text-center font-normal'>Data</h5>,
        accessor: 'date',
        Cell: ({value}) => <div className="items-center">
            <p className="text-xs font-normal text-gray-900 text-center">{<DateFormat value={value}/>}</p>
        </div>
    },
    {
        Header: <h5 className='text-center font-normal'>Ore</h5>,
        accessor: 'amount',
        disableSortBy: true,
        //className: 'w-12',
        Cell: ({row}) => <div className="items-center">
            <p className="text-xs font-normal text-gray-900 text-center">{row.original?.amount} h</p>

        </div>
    },
    {
        Header: <h5 className='text-center font-normal'>Note</h5>,
        accessor: 'note',
        disableSortBy: true,
        className: 'w-2/5',
        Cell: ({row}) =>
            <div className='items-center mx-8'>
                <p className="text-xs font-normal text-center text-gray-900">{row.original?.note ? row.original?.note : '---'}</p>
            </div>
    }
]

export default ProjectActivitiesTableConfig;