import React from "react";
import {PageBreadcrumbLink} from "../../components/Theme/PageHeader/PageBreadcrumbLink";
import OffersWrapper from "../../components/Wrappers/OffersWrapper";
import OfferWrapper from "../../components/Wrappers/OfferWrapper";
import EditOffer from "../../pages/Offers/EditOffer";
import NewOffer from "../../pages/Offers/NewOffer";
import Offers from "../../pages/Offers/Offers";
import OfferShow from "../../pages/Offers/OfferShow";

export const offerRoutes = [
    {
        path: '/offers',
        element: <OffersWrapper/>,
        handle: {crumb: () => <PageBreadcrumbLink to='/offers' label='Offerte'/>},
        children: [{
            path: ':uuid',
            element: <OfferWrapper/>,
            handle: {crumb: () => <PageBreadcrumbLink label='Show'/>},
            children: [{
                path: 'edit',
                element: <EditOffer/>,
                handle: {crumb: () => <PageBreadcrumbLink label='Modifica'/>},
            }, {
                path: '',
                element: <OfferShow/>,
            }]
        }, {
            path: 'create',
            element: <NewOffer/>,
            handle: {crumb: () => <PageBreadcrumbLink label='Crea'/>},
        },
            {
                path: '',
                element: <Offers/>,
            }]
    },
];