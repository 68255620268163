import ActionButtons from "./UI/Buttons/ActionButtons";
import React from "react";

export const CardHeader = ({
                               title,
                               onDelete,
                               icon = undefined,
                               canDelete = true,
                               permissions
                           }) => {
    return (
        <>
            <div className="flex justify-between sm:px-0">
                <h3 className="flex items-center text-left text-xl font-semibold leading-7 text-gray-900">
                    {icon ? icon : <></>}
                    <i className={icon ? 'px-4' : ''}>
                        {title?.toUpperCase()}
                    </i>
                </h3>
                <ActionButtons title={title} onDelete={onDelete} canDelete={canDelete}
                               permissions={permissions}/>
                {/*<p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p>*/}
            </div>
        </>
    )
}

export default CardHeader;