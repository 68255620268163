export const UserInfo = ({user}) => {
    return (
        <>
            <div
                className="mx-auto max-w-3xl px-2 sm:px-2 md:flex items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-2">
                <div className="flex items-center space-x-5">
                    <div className="flex-shrink-0">
                        <div className="relative">
                            <img
                                className="h-10 w-10 rounded-full"
                                src={user.avatar}
                                alt=""
                            />
                            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"/>
                        </div>
                    </div>
                    <div>
                        <h1 className="text-md font-bold text-gray-900">{user.full_name}</h1>
                        <p className="text-xs font-medium text-gray-500">
                            {user.email}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserInfo