import React from 'react';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';

export const MainNavbarMobileMenuItem = ({ item }) => {
  return (
    <Disclosure.Button
      as={Link}
      to={item.action}
      className='block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white'>
      {item.name}
    </Disclosure.Button>
  )
}

