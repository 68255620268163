const statuses = {
    active: 'text-gray-600 bg-green-200 ring-gray-500/10',
    accepted: 'text-gray-600 bg-green-200 ring-gray-500/10',
    suspended: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    terminated: 'text-indigo-600 bg-white ring-indigo-600/50',
    overflow: 'text-red-600 bg-red-50 ring-red-600/20',
    draft: 'text-dark bg-gray-300 ring-black/20',
}

export const StatusBadge = ({value, color}) => {
    return (
        <>
            <span
                className={`inline-flex w-24 max-w-full items-center justify-center rounded-full py-0.5 text-xs font-medium ring-1 ring-inset ${color}`}>
                {value}
                  </span>
        </>
    )
}

export default StatusBadge;