import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {revisionServices} from "../../../services/RevisionService";
import {useParams} from "react-router-dom";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import RevisionItemsDragNDrop from "./components/RevisionItemsDragNDrop";

export const RevisionItemsTable = ({
                                       items,
                                       revisionUuid,
                                       onProductDelete,
                                       onProductUpdate,
                                       onProductPriceUpdate,
                                       onProductPriceDefaultChange,
                                       onProductPriceCreate,
                                       onProductPriceDelete
                                   }) => {
    const [currentItems, setCurrentItems] = useState(items);
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();

    useEffect(() => {
        setCurrentItems(items)
    }, [items]);

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const updatedItems = [...currentItems];
        const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, reorderedItem);

        setCurrentItems(updatedItems);

        const data = {
            current_quote_items: updatedItems.map(item => (item.id))
        };

        revisionServices.revisionItemsSwap(uuid, revisionUuid, data).then(res => {
        })
            .catch(err => {
                let myuuid = uuidv4();
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
            });
    };

    return (
        <>
            <div className="px-4 py-6 sm:grid sm:grid-cols-full sm:gap-4 sm:px-0">
                <div className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-full sm:mt-0">
                    <div role="list"
                         className="divide-y divide-gray-100 rounded-xl border border-gray-500 sm:col-span-full">
                        <RevisionItemsDragNDrop items={currentItems} onDrag={handleDragEnd} revisionUuid={revisionUuid}
                                                onProductDelete={onProductDelete} onProductUpdate={onProductUpdate}
                                                onProductPriceUpdate={onProductPriceUpdate}
                                                onProductPriceCreate={onProductPriceCreate}
                                                onProductPriceDelete={onProductPriceDelete}
                                                onProductPriceDefaultChange={onProductPriceDefaultChange}/>
                    </div>
                </div>
            </div>
        </>
    )
}