import React from 'react';
import {useFormikContext} from 'formik';

export const Select = ({name, value = '', onChange = undefined, children, isDisabled = false}) => {

    const {setFieldValue} = useFormikContext()

    return (
        <select
            id={name}
            name={name}
            value={value}
            disabled={isDisabled}
            onChange={event => setFieldValue(name, event.target.value)}
            className="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2  focus:ring-green-600 sm:text-sm sm:leading-6"
        >
            {children}
        </select>
    )
}
