import UserForm from "./components/UserForm";
import {useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import userServices from "../../services/UserService";
import LoadingSpinner from "../../components/UI/LoadingSpinner";

export const EditUser = () => {
    const {uuid} = useParams();
    const {data = [], loading} = useRequest(userServices.userShow, {
        defaultParams: [uuid]
    })
    return (
        <LoadingSpinner loading={loading}>
            <UserForm user={data}/>
        </LoadingSpinner>

    )
}

export default EditUser