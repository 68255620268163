import {Popover, Transition} from "@headlessui/react"
import {Fragment, useRef} from "react"
import {InformationCircleIcon} from "@heroicons/react/24/outline";

export default function InfoPopOver({children}) {
    const buttonRef = useRef(null)

    let timeout


    const onMouseEnter = (open) => {
        clearTimeout(timeout)
        if (open) return
        return buttonRef.current?.click()
    }

    const onMouseLeave = (open) => {
        // eslint-disable-next-line
        if (!open) return
        //timeout = setTimeout(() => closePopover(), timeoutDuration)
    }

    return (
        <Popover className={''}>
            {({open}) => {
                return (
                    <>
                        <div onMouseLeave={onMouseLeave.bind(null, open)}>
                            <Popover.Button
                                ref={buttonRef}
                                className={open ? "" : "text-opacity-90"}
                                onMouseEnter={onMouseEnter.bind(null, open)}
                                onMouseLeave={onMouseLeave.bind(null, open)}
                            >
                                <InformationCircleIcon className='h-6 w-6'/>
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute z-10 px-4 mt-0 transform -translate-x-1/2 sm:px-0 lg:max-w-3xl">
                                    <div
                                        className="overflow-hidden rounded-lg shadow-lg"
                                        onMouseEnter={onMouseEnter.bind(null, open)}
                                        onMouseLeave={onMouseLeave.bind(null, open)}
                                    >
                                        <div className="p-4 bg-gray-700 text-green-600">
                                            {children}
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </div>
                    </>
                )
            }}
        </Popover>
    )
}