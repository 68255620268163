import {ArrowTopRightOnSquareIcon, UserCircleIcon} from "@heroicons/react/24/outline";
import InfoPopOver from "../../../../components/UI/InfoPopOver";
import React from "react";
import {Disclosure} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import ContactsTable from "../Contacts/ContactsTable";

export const PersonTableField = ({person, onClick, onCreate, onUpdate, onDelete}) => {

    return (
        <>
            <li key={person.id}
                className="flex items-center justify-between py-3">
                {/*<div className="flex items-center space-x-2">
                    {person?.avatar ?
                        <img
                            alt=''
                            src={''}
                            className="h-6 w-6 rounded-full"
                        />
                        :
                        <UserCircleIcon className="h-6 w-6 rounded-full"/>
                    }

                    <p className="text-sm font-medium text-gray-500">
                        {person?.first_name}{' '}
                        {person?.last_name}
                    </p>
                </div>*/}

                <div className="w-full rounded-2xl bg-white basis-5/6 ">
                    <Disclosure>
                        {({open}) => (
                            <>
                                <Disclosure.Button
                                    className="flex w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75">
                                    <div className='flex items-center gap-x-4'>
                                        {person?.avatar ?
                                            <img
                                                alt=''
                                                src={''}
                                                className="h-6 w-6 rounded-full"
                                            />
                                            :
                                            <UserCircleIcon className="h-6 w-6 rounded-full"/>
                                        }
                                        <span>{person?.full_name}</span>
                                    </div>

                                    <ChevronDownIcon
                                        className={`${
                                            open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-gray-500`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel
                                    className="px-4 pb-2 pt-4 mt-1 border border-gray-500 text-sm rounded-lg">
                                    <div className='flex flex-col min-w-full mb-4'>
                                        <div>
                                            <ContactsTable contacts={person?.contacts}
                                                           model={person?.model}
                                                           modelUuid={person?.uuid}
                                                           onCreate={onCreate}
                                                           onUpdate={onUpdate}
                                                           onDelete={onDelete}
                                            />
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </div>

                <div className='flex flex-row gap-x-6'>
                    {person?.note ? <InfoPopOver>
                        {person?.note}
                    </InfoPopOver> : <></>}
                    <div className="flex items-center pb-2">
                        <button
                            onClick={onClick}
                            className="rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            <ArrowTopRightOnSquareIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>

                    </div>
                </div>
            </li>
        </>
    )
}

export default PersonTableField