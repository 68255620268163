import {Fragment, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {useFormikContext} from "formik";
import {useRequest} from "ahooks";
import roleServices from "../../../services/RoleService";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";

export default function RolesSelect({selectedRoles}) {

    const [selected, setSelected] = useState(selectedRoles ? selectedRoles?.map(r => r) : [])
    const {setFieldValue} = useFormikContext()

    const {data = [], loading} = useRequest(roleServices.roleOptions);

    const handleChange = (value) => {
        setSelected(value)
        setFieldValue('roles', value)
    }

    return (
        <LoadingSpinner loading={loading}>
            <Listbox name='roles' value={selected} by='value' onChange={handleChange} multiple>
                <div className="relative mt-1">
                    <Listbox.Button
                        className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-green-600 sm:text-sm">
                    <span
                        className="block truncate">{selected[0] ? selected.map((role) => role.label).join(', ') : 'Seleziona Ruoli'}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
              />
            </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                            {data.map((role, roleIdx) => (
                                <Listbox.Option
                                    key={roleIdx}
                                    className={({active}) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-green-100 text-green-600' : 'text-gray-900'
                                        }`
                                    }
                                    value={role}
                                >
                                    {({selected}) => (
                                        <>
                                          <span
                                              className={`block truncate ${
                                                  selected ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {role.label}
                                            </span>
                                            {selected ? (
                                                <span
                                                    className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                            </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </LoadingSpinner>
        /*        <Listbox
                    name='roles'
                    value={values.roles}
                    by={(a, b) => a.value === b.value}
                    onChange={roles => setFieldValue('roles', roles)}
                    multiple
                >
                    <Listbox.Button
                        className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-green-600 sm:text-sm">
                            <span className="block truncate">
                            {values.roles.length > 0
                                ? values.roles.map(role => role.name || role.label).join(', ')
                                : 'Seleziona un ruolo'
                            }
                            </span>
                    </Listbox.Button>

                    <Listbox.Options>
                        {data.map((role) => (
                            <Listbox.Option
                                className='relative cursor-default select-none py-2 pl-10 pr-4'
                                key={role.value}
                                value={role}>
                                {role.label}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>

                </Listbox>*/
    )
}
