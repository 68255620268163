import React from 'react';
import { MainNavbarMenuPopover } from './MainNavbarMenuPopover/MainNavbarMenuPopover';
import { MainNavbarMenuItem } from './MainNavbarMenuItem/MainNavbarMenuItem';

export const MainNavbarMenuContent = ({ navigation }) => {

  return (
    <div className="hidden md:block">
      <div className="ml-10 flex items-baseline space-x-4">
        {navigation.map((item, index) =>
          item.children && item.children.length > 0
            ? <MainNavbarMenuPopover key={index} item={item}/>
            : <MainNavbarMenuItem key={index} item={item}/>
        )}
      </div>
    </div>
  )

}
