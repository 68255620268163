import {useRequest} from "ahooks";
import quoteServices from "../../services/QuoteService";
import React, {useMemo} from "react";
import QuotesTableConfig from "../../components/UI/Configs/Tables/QuotesTableConfig";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import tableOptions from "../../configs/tableOptions";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import {useNavigate} from "react-router-dom";
import {createButtonConfig} from "../../components/UI/Configs/createButtonConfig";
import PermissionChecker from "../../components/Theme/PermissionChecker";

export const Quotes = () => {
    const navigation = useNavigate();
    const actions = [{
        ...createButtonConfig,
        action: (e => navigation('create')),
        permissions: ['quotes:create']
    }]
    const {data = [], loading, run} = useRequest(quoteServices.quotes, {
        manual: true
    });
    const columns = useMemo(() => QuotesTableConfig, []);

    return (
        <>
            <SectionHeader
                title={'Preventivi'}
                subtitle={'Elenco di tutti i preventivi registrati'}
                actions={actions}/>
            <div
                className="relative overflow-hidden rounded-lg bg-white pb-6  shadow sm:px-6 pt-6 text-center items-center justify-center">
                <PermissionChecker permissions={['quotes:view']} message={'NON HAI IL PERMESSO DI VISUALIZZARE I PREVENTIVI'}>
                <PaginatedTable
                    data={data}
                    columns={columns}
                    onParamsChange={run}
                    options={tableOptions}
                    isLoading={loading}
                />
                </PermissionChecker>
            </div>
        </>
    )
}