export const SectionContent = ({left, right, children})=>{
    return(
        <>
            <div className='flex lg:flex-row flex-col gap-x-4 gap-y-4'>
                <div className='basis-1/3'>
                    {left}
                </div>
                <div className='flex-1'>
                    {right}
                </div>
            </div>
        </>
    )
}

export default SectionContent;