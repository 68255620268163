import React, {useState} from "react";
import {InfoNoteField} from "../../../components/UI/Fields/InfoNoteField";
import {RevisionItemsTable} from "./RevisionItemsTable";
import RevisionFooter from "./RevisionFooter";
import {revisionServices} from "../../../services/RevisionService";
import {v4 as uuidv4} from "uuid";
import {useParams} from "react-router-dom";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {RevisionHeader} from "./RevisionHeader";
import AddProduct from "./AddProduct";
import PermissionChecker from "../../../components/Theme/PermissionChecker";

export const Revision = ({revision}) => {
    const [displayRevision, setDisplayRevision] = useState(revision);
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();

    const handleChangeRevision = (revisionUuid) => {
        revisionUuid ?
            revisionServices.revisionShow(uuid, revisionUuid).then(res => {
                setDisplayRevision(res);
            })
                .catch(err => {
                    let myuuid = uuidv4();
                    flashMsg('Error', err?.response?.data?.message, myuuid, false)
                    console.log(err);
                })
                .finally(() => {
                })
            : <></>
    }

    const handleNewRevision = (newRevision) => {
        setDisplayRevision(newRevision)
    }

    const handleAddItem = (item) => {
        revisionServices.addRevisionItem(uuid, revision?.uuid, {product_id: item.value})
            .then(res => {
                const updatedData = {
                    ...displayRevision,
                    quote_items: [...displayRevision.quote_items, res],
                    total_price: displayRevision.total_price += res.total_price
                }

                setDisplayRevision(updatedData);

            })
            .catch(err => {
                let myuuid = uuidv4();
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
            });
    }

    const handleUpdateItem = (res) => {
        const updatedData = {
            ...displayRevision,
            quote_items: displayRevision.quote_items.map(p => p.uuid === res.uuid ? res : p)
        }

        const toUpdatePrice = displayRevision.quote_items.filter(p => p.uuid === res.uuid);
        updatedData.total_price -= toUpdatePrice[0].total_price;
        updatedData.total_price += res.total_price;

        setDisplayRevision(updatedData);
    }

    const handleDeleteItem = (revisionUuid, itemUuid) => {

        let myuuid = uuidv4();
        revisionServices.deleteItem(uuid, revisionUuid, itemUuid).then(res => {
                flashMsg(res.message, null, myuuid, true)
                const updatedData = {
                    ...displayRevision,
                    quote_items: displayRevision.quote_items.filter(p => p.uuid !== itemUuid)
                }

                const toDeletePrice = displayRevision.quote_items.filter(p => p.uuid === itemUuid);

                updatedData.total_price -= toDeletePrice[0].total_price;

                setDisplayRevision(updatedData);
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })


    }

    const handleUpdateProductPrice = (res, quoteItemUuid) => {

        const updatedData = {
            ...displayRevision,
            quote_items: displayRevision.quote_items.map(item => {
                if (item.uuid === quoteItemUuid) {
                    item.quote_item_prices = item.quote_item_prices.map(price => {
                        return price.uuid === res.uuid ? res : price;
                    });
                }
                item.total_price = res.total_price;
                return item;
            }),
        };
        setDisplayRevision(updatedData);
    }

    const handleDeleteProductPrice = (revisionUuid, quoteItemUuid, priceUuid) => {

        let myuuid = uuidv4();
        revisionServices.deletePriceItem(uuid, revisionUuid, quoteItemUuid, priceUuid).then(res => {
                flashMsg(res.message, null, myuuid, true)

                return {
                    ...displayRevision,
                    quote_items: displayRevision.quote_items.map(i => i.uuid === quoteItemUuid ? i.quote_item_prices = i.quote_item_prices.filter(p =>
                        p.uuid !== priceUuid
                    ) : i)
                }

            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
    }

    const handleUpdateDefaultPrice = (res, quoteItemUuid) => {
        const itemToUpdate = displayRevision.quote_items.filter(i => i.uuid === quoteItemUuid);

        const updatedData = {
            ...displayRevision,
            quote_items: displayRevision.quote_items.map(i => {
                return i.uuid === quoteItemUuid ? {...i, total_price: res.total_price} : i;
            }),
            total_price: displayRevision.total_price - itemToUpdate[0].total_price + res.total_price
        };
        setDisplayRevision(updatedData);
    };

    const handleAddProductPrice = (res, quoteItemUuid) => {
        return {
            ...displayRevision,
            quote_items: displayRevision.quote_items.map(p => p.uuid === quoteItemUuid ? p.quote_item_prices.push(res) : p)
        }
    }


    return (
        <>
            <RevisionHeader revision={displayRevision} onNew={handleNewRevision} onChange={handleChangeRevision}/>
            <div>
                <div className="px-4 sm:px-0">
                </div>
                <div className="mt-6">
                    <div className="grid grid-cols-1 sm:grid-cols-3">
                        <div className='col-span-2'>
                            <InfoNoteField value={displayRevision?.note}/>
                        </div>

                        <PermissionChecker permissions={['quotes:edit']}>
                            <AddProduct onAddItem={handleAddItem}/>
                        </PermissionChecker>

                    </div>

                    <RevisionItemsTable items={displayRevision?.quote_items} revisionUuid={displayRevision?.uuid}
                                        onProductDelete={handleDeleteItem}
                                        onProductUpdate={handleUpdateItem}
                                        onProductPriceUpdate={handleUpdateProductPrice}
                                        onProductPriceCreate={handleAddProductPrice}
                                        onProductPriceDelete={handleDeleteProductPrice}
                                        onProductPriceDefaultChange={handleUpdateDefaultPrice}/>
                    <div className='border-t border-gray-400'>
                        <RevisionFooter total={displayRevision?.total_price}/>
                    </div>
                </div>
            </div>
        </>
    )
}