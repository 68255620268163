import Companies from "../../pages/Customers/Companies";
import CompanyShow from "../../pages/Customers/CompanyShow";
import EditCompany from "../../pages/Customers/EditCompany";
import NewCompany from "../../pages/Customers/NewCompany";
import React from "react";
import {PageBreadcrumbLink} from "../../components/Theme/PageHeader/PageBreadcrumbLink";
import {CompanyWrapper} from "../../components/Wrappers/CompanyWrapper";
import CompaniesWrapper from "../../components/Wrappers/CompaniesWrapper";
import PermissionChecker from "../../components/Theme/PermissionChecker";

export const companyRoutes = [
    {
        path: '/companies',
        element: <><CompaniesWrapper permissions={['companies:view']}/></>,
        handle: {
            crumb: () => <PageBreadcrumbLink to="/companies" label="Clienti"/>
        },
        children: [{
            path: ':uuid',
            element: <><CompanyWrapper permissions={['companies:view']}/></>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Dettagli'/>
            },
            children: [{
                path: 'edit',
                element: <><PermissionChecker permissions={['companies:edit']} navigateTo={('/404')}><EditCompany/></PermissionChecker></>,
                handle: {
                    crumb: () => <PageBreadcrumbLink label='Modifica'/>
                }
            }, {
                path: '',
                element: <><CompanyShow/></>,
            }]
        }, {
            path: 'create',
            element: <><PermissionChecker permissions={['companies:edit']} navigateTo={('/404')}><NewCompany/></PermissionChecker></>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Crea'/>
            }
        }, {
            path: '',
            element: <><Companies/></>,
        }]
    },
    /*{
        index: true,
        path: '/companies/:uuid',
        element: <CompanyShow/>,
    },*/
    /*{
        index: true,
        path: '/companies/:uuid/edit',
        element: <EditCompany/>
    },*/
    /*{
        index: true,
        path: '/companies/create',
        element: <NewCompany/>,
    }*/
];