import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";
import EuroFormat from "../../../components/UI/EuroFormat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGripVertical} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import DiscountFormat from "../../../components/UI/DiscountFormat";
import ShowRevisionPriceItemSlideOver from "./components/ShowRevisionPriceItemSlideOver";
import {revisionServices} from "../../../services/RevisionService";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import PermissionChecker from "../../../components/Theme/PermissionChecker";

export const RevisionPriceItem = ({
                                      price,
                                      revisionUuid = undefined,
                                      productUuid = undefined,
                                      is_default = false,
                                      onPriceDelete,
                                      onPriceUpdated,
                                      onProductPriceUpdate,
                                      onProductPriceDefaultChange,
                                      onProductPriceDelete,
                                      permissions
                                  }) => {
    const [showSlideOver, setShowSlideOver] = useState(false)
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();


    const handleChangeDefault = (event) => {
        price.is_checked = true;

        const data = {
            ...price,
            discount: price?.discount * 100

        }

        revisionServices.updateProductPrice(uuid, revisionUuid, productUuid, price.uuid, data)
            .then(res => {
                onProductPriceDefaultChange(res, productUuid);
            })
            .catch(err => {
                let myuuid = uuidv4();
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
            })

    }


    return (
        <>
            <div className='flex items-center min-w-full'>
                <PermissionChecker permissions={permissions}>
                    <button className='me-3'>
                        <FontAwesomeIcon icon={faGripVertical} className="-ml-0.5 h-4 w-4" color={'gray'}/>
                    </button>
                </PermissionChecker>
                <div
                    className={`relative overflow-hidden border ${is_default ? 'border-black' : 'border-gray-300'} rounded-lg bg-white shadow-md px-3 py-2 w-full`}>
                    <div className='flex justify-between items-center'>
                        <div className='flex items-center basis-7/12'>
                            <PermissionChecker permissions={permissions}>
                                <input
                                    id={`radio${productUuid}`}
                                    name={`radio${productUuid}`}
                                    type="radio"
                                    value={price?.id}
                                    defaultChecked={price?.is_checked}
                                    onChange={handleChangeDefault}
                                    className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600 accent-green-600"
                                />
                            </PermissionChecker>
                            <p className='text-sm flex-1 text-left items-center ml-5'>{price?.description}</p>
                        </div>

                        <div className='flex-1 items-center'>
                            <div className='grid lg:grid-cols-7 sm:grid-cols-4 grid-cols-1 items-center text-left'>
                                <p className='text-sm items-center text-black font-light line-through decoration-red-500 col-span-2'>
                                    <EuroFormat
                                        value={price?.unit_price}/></p>
                                <p className='text-sm items-center text-red-600 col-span-1'><DiscountFormat
                                    value={price?.discount * 100}/></p>
                                <p className='text-sm items-center text-black col-span-2'><EuroFormat
                                    value={price?.unit_price_discounted}/></p>
                                <p className='text-sm items-center text-black col-span-2'>Tot. <EuroFormat
                                    value={price?.total_price}/></p>
                            </div>

                        </div>
                        <PermissionChecker permissions={permissions}>


                            <div className='flex items-center'>
                                <button
                                    onClick={e => setShowSlideOver(true)}
                                    className='ml-auto inline-flex ps-3 items-center gap-x-2 rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600'
                                >
                                    <ArrowTopRightOnSquareIcon className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                        </PermissionChecker>
                    </div>
                </div>
            </div>
            <ShowRevisionPriceItemSlideOver price={price}
                                            state={showSlideOver}
                                            revisionUuid={revisionUuid}
                                            productUuid={productUuid}
                                            onProductPriceUpdate={onProductPriceUpdate}
                                            onProductPriceDelete={onProductPriceDelete}
                                            onClose={e => setShowSlideOver(false)}/>
        </>
    )
}