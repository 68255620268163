import React, {useMemo} from 'react';
import {useRequest} from "ahooks";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import {useNavigate} from "react-router-dom";
import tableOptions from "../../configs/tableOptions";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import {createButtonConfig} from "../../components/UI/Configs/createButtonConfig";
import roleServices from "../../services/RoleService";
import RolesTableConfig from "../../components/UI/Configs/Tables/RolesTableConfig";

const Roles = () => {
    const navigation = useNavigate();
    const actions = [{
        ...createButtonConfig,
        action: (e => navigation('create')),
        permissions: ['roles:edit']
    }]
    const columns = useMemo(() => RolesTableConfig, []);
    const {data = [], loading, run} = useRequest(roleServices.roles, {
        manual: true
    });


    return (
        <>
            <SectionHeader
                title={'Ruoli'}
                subtitle={'Elenco di tutti i ruoli registrati'}
                actions={actions}/>
            <div
                className="relative overflow-hidden rounded-lg bg-white pb-6  shadow sm:px-6 pt-6 text-center items-center justify-center">
                <PaginatedTable
                    data={data}
                    columns={columns}
                    onParamsChange={run}
                    options={tableOptions}
                    isLoading={loading}
                />
            </div>

        </>

    )

}

export default Roles
