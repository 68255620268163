import TableLink from "../Buttons/TableLink";

const InfoTableField = ({title, value = undefined, link=undefined, span = '1'}) =>{
    return(
        <div className={`border-t border-gray-100 px-2 py-4 col-span-${span}`}>
            <dt className="text-sm font-bold leading-6 text-gray-900">{title}</dt>
                {/*<dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{value ? value : '---'}</dd>*/}
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{link && value ? <TableLink to={link} value={value}/> : value ? value : '---'}</dd>
        </div>
    )
}

export default InfoTableField;