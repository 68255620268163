import {Field, Form, FormikProvider, useFormik} from "formik";
import {FormGroup} from "../../../components/UI/Forms/FormGroup";
import {Select} from "../../../components/UI/Forms/Select";
import {FormInput} from "../../../components/UI/Fields/FormInput";
import _ from "lodash";
import CancelButton from "../../../components/UI/Buttons/CancelButton";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import {useNavigate, useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import CustomerService from "../../../services/CustomerService";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {useEffect, useState} from "react";
import projectService from "../../../services/ProjectService";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {offerServices} from "../../../services/OfferService";
import {PriceInput} from "../../../components/UI/Forms/PriceInput";

export const OfferForm = ({offer = undefined}) => {
    const {uuid} = useParams();
    const navigation = useNavigate();
    const {flashMsg} = useFlashMessage();

    const {data = []} = useRequest(CustomerService.companies);
    const {data: projects = [], run} = useRequest(projectService.projectOptions, {
        manual: true,
    });

    const [extraParams, setExtraParams] = useState({
        filter: {
            company_id: offer?.company?.id ?? '',
        },
    });

    const updateOffer = (data, setSubmitting) => {
        offerServices.updateOffer(uuid, data).then(res => {
            flashMsg('Offerta Aggiornata', null, uuidv4(), true);
            navigation(-1);
        })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
            .finally(() => setSubmitting(false))
    }

    const createOffer = (data, setSubmitting) => {
        offerServices.createOffer(data).then(res => {
            flashMsg('Offerta Creata', null, uuidv4(), true);
            navigation(-1);
        })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
            .finally(() => setSubmitting(false))
    }


    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            billable_id: offer?.billable?.value,
            billable_type: offer?.billable?.model,
            description: values.description,
            company_id: values.company_id,
            project_id: values.project_id,
            end_customer_id: values.end_customer_id,
            proposed_price: values.proposed_price,
            accepted_price: values.accepted_price,
            date: values.date,
            note: values?.note
        }

        offer ? updateOffer(data, setSubmitting) : createOffer(data, setSubmitting)


    }


    const initialValues = {
        company_id: offer?.company?.id ?? '',
        end_customer_id: offer?.end_customer?.id ?? '',
        project_id: offer?.project?.id ?? '',
        description: offer?.description ?? '',
        billable_id: offer?.billable_id ?? '',
        date: offer?.date?.substring(0, 10),
        proposed_price: offer?.proposed_price ?? '',
        accepted_price: offer?.accepted_price ?? '',
        note: offer?.note ?? '',
    }

    const validationSchema = Yup.object().shape({
        company_id: Yup.number().nullable().required(),
        end_customer_id: Yup.number().nullable().required(),
        project_id: Yup.number().nullable(),
        proposed_price: Yup.number().nullable().required(),
        accepted_price: Yup.number().nullable().required(),
        date: Yup.string().nullable().required(),
        description: Yup.string().nullable().required(),
        note: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    useEffect(() => {
            formik.values.company_id !== '' ?
                setExtraParams(prevExtraParams => ({
                    ...prevExtraParams,
                    filter: {
                        ...prevExtraParams.filter,
                        company_id: formik.values.company_id
                    }
                }))
                :
                <></>
    }, [formik.values.company_id]);

    useEffect(() => {
            extraParams.filter.company_id !== '' ?
                run(extraParams)
                :
                <></>;
    }, [extraParams, run]);


    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <div className="space-y-12">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div className='sm:ms-4'>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">{offer ? 'Modifica' : 'Nuova'} Offerta</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">{offer ? 'Modifica' : 'Inserisci'} le
                                    informazioni dell'offerta</p>
                            </div>

                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                                <div className="sm:col-span-3">
                                    <FormGroup required label='Cliente' name='company_id'>
                                        <div className="mt-2">
                                            <Select
                                                value={formik.values.company_id}
                                                name="company_id"
                                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                            >
                                                {data.data ?
                                                    <>
                                                        <option value='' className='text-center'>--SELEZIONA
                                                            AZIENDA---
                                                        </option>
                                                        {data.data.map(item => (
                                                            <option key={item.id}
                                                                    value={item.id}>{item.business_name}</option>
                                                        ))}
                                                    </>

                                                    : <option>Loading</option>
                                                }
                                            </Select>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required label='Cliente Finale' name='end_customer_id'>
                                        <div className="mt-2">
                                            <Select
                                                value={formik.values.end_customer_id}
                                                name="end_customer_id"
                                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                            >
                                                {data.data ?
                                                    <>
                                                        <option value='' className='text-center'>--SELEZIONA
                                                            AZIENDA---
                                                        </option>
                                                        {data.data.map(item => (
                                                            <option key={item.id}
                                                                    value={item.id}>{item.business_name}</option>
                                                        ))}
                                                    </>

                                                    : <option>Loading</option>
                                                }
                                            </Select>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup label='Progetto' name='project_id'>
                                        <div className="mt-2">
                                            <Field as={Select} value={formik.values.project_id} name="project_id">
                                                {formik.values.company_id ? (
                                                    <>
                                                        <option value='' className='text-center'>--SELEZIONA PROGETTO---</option>
                                                        {projects ? (
                                                            projects?.map(item => (
                                                                <option key={item.value} value={item.value}>{item.label}</option>
                                                            ))
                                                        ) : (
                                                            <option>Loading</option>
                                                        )}
                                                    </>
                                                ) : (
                                                    <option className='text-center' value='' disabled={true}>--SELEZIONA PRIMA L'AZIENDA---</option>
                                                )}

                                            </Field>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required={true} label='Data' name='date'>
                                        <div className="mt-2">
                                            <Field as={FormInput} required={true} label='date' type='date'
                                                   name='date' value={formik.values.date}/>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required={true} label='Prezzo Proposto' name='proposed_price'>
                                        <div className="mt-2">
                                            <Field as={PriceInput} required={true} label='proposed_price' type='number'
                                                   name='proposed_price' value={formik.values.proposed_price}/>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required={true} label='Prezzo Accettato' name='accepted_price'>
                                        <div className="mt-2">
                                            <Field as={PriceInput} required={true} label='accepted_price' type='number'
                                                   name='accepted_price' value={formik.values.accepted_price}/>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="col-span-full">
                                    <FormGroup required={true} label='Descrizione' name='description'>
                                        <div className="mt-2">
                                            <FormInput
                                                name='description'
                                                type='textarea'
                                                autoComplete=''
                                                value={formik.values.description}
                                                onChange={event => formik.setFieldValue('description', event.target.value)}
                                                error={_.get(formik.errors, 'description')}
                                            />
                                        </div>
                                    </FormGroup>
                                    {/*<label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                        Descrizione
                                    </label>*/}
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="note" className="block text-sm font-medium leading-6 text-gray-900">
                                        Note
                                    </label>
                                    <FormInput
                                        name='note'
                                        type='textarea'
                                        autoComplete=''
                                        value={formik.values.note}
                                        onChange={event => formik.setFieldValue('note', event.target.value)}
                                        error={_.get(formik.errors, 'note')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <CancelButton/>
                        <SubmitButton label={offer ? 'Salva' : 'Crea'}/>
                    </div>
                </Form>
            </FormikProvider>
        </>
    )
}

export default OfferForm;