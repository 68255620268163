import {useRequest} from "ahooks";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import {useNavigate, useParams} from "react-router-dom";
import projectServices from "../../services/ProjectService";
import React from "react";
import {PackagesTable} from "../Customers/components/Packages/PackagesTable";
import ActivitiesTable from "../Packages/components/ActivitiesTable";
import ProjectActivitiesTableConfig from "../../components/UI/Configs/Tables/ProjectActivitiesTableConfig";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../components/FlashMessages/useFlashMessage";
import SectionContent from "../../components/Theme/PageSections/SectionContent";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import InfoCard from "../../components/UI/Cards/InfoCard";
import InfoTableField from "../../components/UI/Fields/InfoTableField";
import {InfoNoteField} from "../../components/UI/Fields/InfoNoteField";
import Card from "../../components/UI/Cards/Card";


const ProjectShow = () => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();
    const navigation = useNavigate();
    const {data = [], loading} = useRequest(projectServices.projectShow, {
        defaultParams: [uuid]
    })

    const deleteHandler = () => {
        let myuuid = uuidv4();
        projectServices.deleteProject(uuid).then(res => {
                flashMsg(res.message, null, myuuid, true)
                navigation(-1)
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
    }

    return (
        <>
            <LoadingSpinner loading={loading}>
                <SectionHeader title={`Dettagli Progetto`} subtitle={data?.name}/>

                <SectionContent
                    /*left={
                        <div
                            className="relative overflow-hidden rounded-lg bg-white px-4 pb-6 shadow sm:px-4 sm:pt-4 py-6">
                            <ProjectShowInfo data={data} onDelete={deleteHandler}/>
                        </div>
                    }*/

                    left={
                        <InfoCard title={data?.name} onDelete={deleteHandler} permissions={['projects:edit']}>
                            <InfoTableField title='Cliente'
                                            value={data?.company?.business_name ? data?.company?.business_name : undefined}
                                            link={`/companies/${data?.company?.uuid}`}
                            />
                            <InfoTableField title='Cliente Finale'
                                            value={data?.end_customer?.business_name ? data?.end_customer?.business_name : undefined}
                                            link={`/companies/${data?.end_customer?.uuid}`}/>
                            {data?.note ?
                                <InfoNoteField value={data?.note}/>
                                : null
                            }
                        </InfoCard>
                    }

                    right={
                        <div className='flex flex-col gap-y-4'>
                            {/*<div
                                className="relative overflow-hidden rounded-lg bg-white px-4 pb-6 shadow sm:px-6 sm:pt-6 py-6 basis-1/2">
                                <PackagesTable packages_count={data?.packages_count} project_uuid={uuid}
                                               company_id={data?.company?.id} project_id={data?.id}/>
                            </div>*/}
                            <Card flex='basis-1/2'>
                                <PackagesTable packages_count={data?.packages_count} project_uuid={uuid}
                                               company_id={data?.company?.id} project_id={data?.id}/>
                            </Card>
                            <Card flex='flex-1'>
                                <ActivitiesTable project_id={data?.id} tableConfig={ProjectActivitiesTableConfig}/>
                            </Card>
                        </div>
                    }
                />

            </LoadingSpinner>
            {/*</div>*/}
        </>
    )
}

export default ProjectShow;