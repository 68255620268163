import React from 'react';
import { PageBreadcrumbs } from './PageBreadcrumbs';
import { useMatches } from 'react-router-dom';
import _ from 'lodash';

export const PageHeader = () => {

  const matches = useMatches();

  let crumbs = _.map(
    _.filter(matches, m => Boolean(m.handle?.crumb)),
    m => m.handle.crumb(m.data)
  )


  return (
    <header className="bg-white shadow-sm">
      <div className="mx-auto max-w-full px-4 py-4 sm:px-6 lg:px-8">
        <PageBreadcrumbs crumbs={crumbs}/>
      </div>
      {/*<div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
        <h1 className="text-lg font-semibold leading-6 text-gray-900">Dashboard</h1>
      </div>*/}
    </header>
  )

}
