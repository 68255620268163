import api from '../configs/api.config';

const billUrl = '/bills';
const offerUrl = '/offers';


const bills = params => api.get(billUrl, params).then(res => res.data);
const createBill = (uuid, params) => api.post(`/offers/${uuid}${billUrl}`, params).then(res => res.data);
const billShow = (id, params) => api.get(billUrl + '/' + id, params).then(res => res.data);
const updateBill = (uuid, billUuid, params) => api.put(`/offers/${uuid}${billUrl}/${billUuid}`, params).then(res => res.data);
const deleteBill = (id, billUuid, params) => api.delete(`${offerUrl}/${id}${billUrl}/${billUuid}`, params).then(res => res.data);
export const billServices = {
    bills,
    createBill,
    billShow,
    updateBill,
    deleteBill
}