import axios from 'axios';
import qs from 'qs';
import router from "../routes/router";
import {sessionConfig} from './session.config';

const onRequest = (config) => {
    const token = sessionConfig.getToken();

    if (token) {
        config.headers.Authorization = token;
    }

    config.paramsSerializer = (params) => qs.stringify(params, {
        arrayFormat: 'comma',
        skipNulls: true,
    });

    return config;
};

const onRequestError = (error) => {
    return Promise.reject(error)
};

const onResponse = (response) => response;

const onResponseError = (error) => {
    if (error.response.status == 404) {
        console.log(error)
        router.navigate('/404')
    }
    return Promise.reject(error);
}

export const setupInterceptorsTo = (axiosInstance) => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
};

const api = setupInterceptorsTo(
    axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        withCredentials: true,
        withXSRFToken: true,
    }),
);

export default api;
