import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import serverServices from "../../../../services/ServerService";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";
import SlideOverFormInput from "../../../Packages/components/SlideOverFormInput";
import {SlideOverFormGroup} from "../../../../components/UI/Forms/SlideOverFormGroup";

export const LicenseForm = ({license, siteId, serverUuid, onCreate, onUpdate, isDisabled}) => {
    const {flashMsg} = useFlashMessage();

    const initialValues = {
        name: license?.name ?? '',
        key: license?.key ?? '',
        note: license?.note ?? '',
    }

    const createLicense = (data, setSubmitting) => {
        serverServices.createLicense(serverUuid, data)
            .then(res => {
                flashMsg('Licenza Creata', undefined, uuidv4(), true)
                onCreate(res, serverUuid, siteId);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const updateLicense = (data, setSubmitting) => {
        serverServices.updateLicense(serverUuid, license?.uuid, data)
            .then(res => {
                flashMsg('Licenza Modificata', undefined, uuidv4(), true)
                onUpdate(res, serverUuid, siteId);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            site_id: siteId ?? null,
            name: values.name,
            key: values.key,
            note: values.note
        }

        license ? updateLicense(data, setSubmitting) : createLicense(data, setSubmitting)
    }


    const validationSchema = Yup.object().shape({
        site_id: Yup.string().nullable(),
        name: Yup.string().nullable().required(),
        key: Yup.string().nullable().required(),
        note: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
            <FormikProvider value={formik}>
                <Form>
                    <div className="space-y-8">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 pb-4">

                            <div
                                className="grid grid-cols-1 gap-x-2 gap-y-3 sm:grid-cols-6">

                                <div className="col-span-full">
                                    <SlideOverFormGroup required label='Nome' name='name'>
                                        <div className="mt-2">
                                            <Field as={SlideOverFormInput} required label='name'
                                                   name='name' value={formik.values.name} isDisabled={isDisabled}/>
                                        </div>
                                    </SlideOverFormGroup>
                                </div>

                                <div className="col-span-full">
                                    <SlideOverFormGroup required label='Chiave' name='key'>
                                        <div className="mt-2">
                                            <Field as={SlideOverFormInput} required label='key'
                                                   name='key' value={formik.values.key} isDisabled={isDisabled}/>
                                        </div>
                                    </SlideOverFormGroup>
                                </div>


                                <div className="col-span-full">
                                    <SlideOverFormGroup label='Note' name='note'>
                                        <div className="mt-2">
                                            <Field as={SlideOverFormInput} label='note'
                                                   name='note' value={formik.values.note} type='textarea' isDisabled={isDisabled}/>
                                        </div>
                                    </SlideOverFormGroup>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!isDisabled && <div className="mt-4 flex items-center justify-end gap-x-6">
                        <SubmitButton label={license ? 'Salva' : 'Crea'}/>
                    </div>}
                </Form>
            </FormikProvider>
    )
}

export default LicenseForm