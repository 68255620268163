import React from "react";
import Projects from "../../pages/Projects/Projects";
import ProjectShow from "../../pages/Projects/ProjectShow";
import NewProject from "../../pages/Projects/NewProject";
import EditProject from "../../pages/Projects/EditProject";
import {PageBreadcrumbLink} from "../../components/Theme/PageHeader/PageBreadcrumbLink";
import ProjectsWrapper from "../../components/Wrappers/ProjectsWrapper";
import ProjectWrapper from "../../components/Wrappers/ProjectWrapper";
import PermissionChecker from "../../components/Theme/PermissionChecker";

export const projectRoutes = [
    {
        path: '/projects',
        element: <ProjectsWrapper/>,
        handle: {crumb: () => <PageBreadcrumbLink to='/projects' label='Progetti'/>},
        children: [{
            path: ':uuid',
            element: <ProjectWrapper/>,
            handle: {crumb: () => <PageBreadcrumbLink label='Show'/>},
            children: [{
                path: 'edit',
                element: <PermissionChecker permissions={['projects:edit']} navigateTo={'/404'}><EditProject/></PermissionChecker>,
                handle: {crumb: () => <PageBreadcrumbLink label='Modifica'/>},
            }, {
                path: '',
                element: <ProjectShow/>,

            }]
        }, {
            path: 'create',
            element: <PermissionChecker permissions={['projects:edit']} navigateTo={'/404'}><NewProject/></PermissionChecker>,
            handle: {crumb: () => <PageBreadcrumbLink label='Crea'/>},
        },
            {
                path: '',
                element: <Projects/>,
            }]
    },
];