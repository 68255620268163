import api from '../configs/api.config';

const projectsUrl = '/projects';

const projects = params => api.get(projectsUrl, {params}).then(res => res.data);

const projectOptions = (params) => api.get(`/options${projectsUrl}`, {params}).then(res => res.data);

const projectShow = (id, params) => api.get(projectsUrl + '/' + id).then(res => res.data);

const createProject = params => api.post(projectsUrl, params).then(res => res.data);
const updateProject = (uuid, params) => api.put(`${projectsUrl}/${uuid}`, params).then(res => res.data);

const deleteProject = (id, params) => api.delete(`${projectsUrl}/${id}`, params).then(res => res.data);


const projectServices = {
    projects,
    projectShow,
    createProject,
    updateProject,
    projectOptions,
    deleteProject
}

export default projectServices;