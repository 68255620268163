import {format} from "date-fns";
import React from "react";
import {parseISO} from "rsuite/esm/utils/dateUtils";

const DateFormat = ({value}) => {
    if (!value) return <></>;

    return <span>{format(parseISO(value), "dd/MM/yyyy")}</span>;
};

export default DateFormat;