import {Outlet} from "react-router-dom";
import PermissionChecker from "../Theme/PermissionChecker";

const ServicesWrapper = () => {
    return (
        <PermissionChecker permissions={['services:view']}>
            <Outlet/>
        </PermissionChecker>
    )
}

export default ServicesWrapper