import React from "react";
import InfoTableField from "../../../components/UI/Fields/InfoTableField";
import StateDropDown from "../../../components/UI/StateDropDown";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {useParams} from "react-router-dom";
import packageServices from "../../../services/PackageService";
import {InfoNoteField} from "../../../components/UI/Fields/InfoNoteField";
import InfoCard from "../../../components/UI/Cards/InfoCard";
import DateFormat from "../../../components/UI/DateFormat";

export default function PackageShowInfo({data, onStateUpdate, onDelete}) {
    const {flashMsg} = useFlashMessage();
    const {uuid} = useParams();
    const changeStateHandler = (value) => {
        packageServices.updateState(uuid, {
            state: value
        })
            .then(res => {
                flashMsg('Stato Cambiato Con Successo', null, uuidv4(), true);
                onStateUpdate(res);
            })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false);
            });
    }

    return (
        <InfoCard onDelete={onDelete} permissions={['packages:edit']}>
            <InfoTableField title={'Azienda'} value={data?.company?.business_name}
                            link={`/companies/${data?.company?.uuid}`}/>
            <InfoTableField title={'Progetto'} value={data?.project?.name}
                            link={`/projects/${data?.project?.uuid}`}/>
            <InfoTableField title={'Ore Utilizzate'} value={data?.used_amount}/>
            <InfoTableField title={'Ore Riamanenti'} value={data?.remaining_amount}/>
            <InfoTableField title={'Ore Totali'} value={data?.amount}/>
            <InfoTableField title={'Inizia il'} value={<DateFormat value={data?.start_at}/>}/>
            <InfoTableField title={'Exp. Data'} value={<DateFormat value={data?.expiration_date}/>}/>
            <InfoTableField title={'Giorni Rimanenti'} value={data?.expiration_days_left}/>
            <InfoTableField title={'Prezzo Orario'} value={data?.price_each}/>
            <InfoTableField title={'Prezzo Totale'} value={data?.price_total}/>
            <div
                className="flex justify-center border-t border-gray-100 px-4 py-4 sm:col-span-full sm:px-0 me-5">
                <StateDropDown available_states={data?.states?.available_states}
                               value={data?.states?.state}
                               onStateUpdate={changeStateHandler}
                               permissions={['packages:edit']}/>
            </div>

            {data?.note ?
                <InfoNoteField value={data?.note}/>
                : <div className='mt-12'></div>
            }
        </InfoCard>
    )
}