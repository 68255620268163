import React, { useState } from 'react';
import * as Yup from 'yup'
import { Form, FormikProvider, useFormik } from 'formik';
import { AuthLabel } from './components/AuthLabel';
import { AuthInput } from './components/AuthInput';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import { ErrorAlert } from '../../components/UI/Alerts/ErrorAlert';
import { SuccessAlert } from '../../components/UI/Alerts/SuccessAlert';

export const ForgotPassword = () => {

  const [forgotPasswordResponse, setForgotPasswordResponse] = useState(undefined)
  const initialValues = { email: '' }
  const validationSchema = Yup.object().shape({ email: Yup.string().email().required() })

  const handleSubmit = (values, { setSubmitting, resetForm }) => AuthService.requestPasswordLink(values.email)
  .then(res => {
    setForgotPasswordResponse(res)
    resetForm()
  }).catch(err => setForgotPasswordResponse(err))
  .finally(() => setSubmitting(false))

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit
  })

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6">
        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">Forgot password?</h2>
        <div>
          <AuthLabel htmlFor='email' label='Email'/>
          <AuthInput
            name='email'
            required
            type='email'
            autoComplete='email'
            value={formik.values.email}
            onChange={event => formik.setFieldValue('email', event.target.value)}
            error={_.get(formik.errors, 'email')}
          />
        </div>

        <div className="flex items-center justify-end">
          <div className="text-sm leading-6">
            <Link to='/auth/login' className="font-semibold text-indigo-600 hover:text-indigo-500">
              Back to sign in
            </Link>
          </div>
        </div>

        <div>
          <button
            disabled={formik.isSubmitting ?? !formik.isValid}
            type="submit"
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {formik.isSubmitting ? 'Generating reset link' : 'Send reset link'}
          </button>
        </div>


        {forgotPasswordResponse
          ? _.get(forgotPasswordResponse, 'errors')
            ? <ErrorAlert title={forgotPasswordResponse.message}/>
            : <SuccessAlert title={forgotPasswordResponse.message}/>
          : <></>}

      </Form>
    </FormikProvider>

  )

}
