import React, {Fragment, useState} from 'react'
import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import ContactForm from "./ContactForm";
import ActionButtonsSlideOver from "../../../../components/UI/Buttons/ActionButtonsSlideOver";
import DeleteModal from "../../../../components/UI/DeleteModal";

export default function ContactSlideOver({contact = undefined, model, modelUuid, onCreate, onUpdate, onDelete, onClose, state}) {
    const [isDisabled, setIsDisabled] = useState(true)
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <SlideOver title={contact ? 'Informazioni Contatto' : 'Nuovo Contatto'}
                       subtitle={contact ? 'Modifica le informazioni del contatto' : 'Inserisci le informazioni del contatto'}
                       onClose={onClose}
                       state={state}
                       //size='max-w-md'
            >
                <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                    {contact ? <ActionButtonsSlideOver editAction={() => setIsDisabled(false)}
                                                       deleteAction={() => setShowModal(true)}/> : <></>}
                    <ContactForm contact={contact} model={model} modelUuid={modelUuid} onCreate={onCreate} onUpdate={onUpdate} isDisabled={contact ? isDisabled : false}/>
                </div>
            </SlideOver>

            <DeleteModal title={contact?.label} onClose={() => setShowModal(false)}
                         onDelete={() => onDelete(model, modelUuid, contact?.uuid)} state={showModal}/>
        </>
    )
}