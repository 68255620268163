import PermissionChecker from "../PermissionChecker";

export const SectionHeader = ({title, subtitle, actions}) => {
    return (
        <>
            <div className="-ml-4 -mt-4 mb-6 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-4">
                    <div className="flex items-center">
                        <div className="ml-4">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
                            <p className="text-sm text-gray-500">
                                {subtitle}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="ml-4 mt-4 flex flex-shrink-0">
                    {actions?.map(action =>
                        <PermissionChecker key={action?.action} permissions={action?.permissions}>
                            <button
                                type="button"
                                key={action?.action}
                                onClick={action.action}
                                className={`relative ml-3 inline-flex items-center rounded-md bg-${action.color ? action.color : 'white'} px-3 py-2 text-sm font-semibold text-${action.textColor ? action.textColor : 'gray-900'} shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-${action.hoverColor ? action.hoverColor : 'gray-100'}`}
                            >
                                {action.icon ? <action.icon
                                    className={`-ml-0.5 mr-1.5 h-5 w-5 ${action.textColor ? action.textColor : 'text-gray-400'}`}
                                    aria-hidden="true"/> : <></>}
                                <span>{action.title}</span>
                            </button>
                        </PermissionChecker>
                    )}
                </div>

            </div>
        </>
    )
}

export default SectionHeader;