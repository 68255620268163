import React, {useState} from "react";
import {useRequest} from "ahooks";
import {offerServices} from "../../services/OfferService";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import OffersTable from "./components/OffersTable";
import OffersFilter from "./components/Table/OffersFilter";
import {useNavigate} from "react-router-dom";
import {createButtonConfig} from "../../components/UI/Configs/createButtonConfig";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import {v4 as uuidv4} from "uuid";
import {billServices} from "../../services/BillService";
import useFlashMessage from "../../components/FlashMessages/useFlashMessage";

export const Offers = () => {
    const navigation = useNavigate();
    const {flashMsg} = useFlashMessage();

    const actions = [{
        ...createButtonConfig,
        action: (() => navigation('create')),
        permissions: ['offers:edit'],
    }]

    const {data = [], loading, mutate} = useRequest(offerServices.offers, {
        manual: false
    });

    const handleBillUpdate = (updatedBill, offerUuid) => {

        const updatedData = data.data.map(offer => {
            if (offer.uuid === offerUuid) {
                return {
                    ...offer,
                    bills: offer.bills.map(b => (b.uuid === updatedBill.uuid) ? updatedBill : b)
                };
            } else {
                return offer;
            }
        });

        mutate({...data, data: updatedData});
    }

    const handleBillDelete = (offerUuid, billUuid) => {
        billServices.deleteBill(offerUuid, billUuid).then(res => {
                flashMsg(res.message, null, uuidv4(), true);

                const updatedData = data.data.map(offer => {
                    if (offer.uuid === offerUuid) {
                        return {
                            ...offer,
                            bills: offer.bills.filter(b => b.uuid !== billUuid)
                        };
                    } else {
                        return offer;
                    }
                });

                mutate({...data, data: updatedData});
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })


    }

    const handleBillCreate = (offerUuid, bill) => {
        const updatedData = data.data.map(offer => {
            if (offer.uuid === offerUuid) {
                return {
                    ...offer,
                    bills: [...offer?.bills, bill]
                };
            } else {
                return offer;
            }
        });

        mutate({...data, data: updatedData});
    }


    /*const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedEndCustomers, setSelectedEndCustomers] = useState([])
    const [selectedProjects, setSelectedProjects] = useState([]);*/

    const [filter, setFilter] = useState('')

    /*const [filter, setFilter] = useState({
        companies: selectedCompanies,
        end_customers: selectedEndCustomers,
        projects: selectedProjects
    })*/

    /*useEffect(() => {
        setFilter({companies: selectedCompanies, end_customers: selectedEndCustomers, projects: selectedProjects})
    }, [selectedCompanies, selectedProjects, selectedProjects]);*/


    return (
        <>
            <LoadingSpinner loading={loading}>
                <SectionHeader
                    title={'Fatturazione'}
                    subtitle={'Elenco di tutti le fatturazioni'}
                    actions={actions}/>
                <div
                    className="relative overflow-hidden rounded-lg bg-white pb-6  shadow sm:px-6 pt-6 text-center items-center justify-center">
                    {/*<TableFilters
                        selectedCompanies={selectedCompanies} setSelectedCompanies={setSelectedCompanies}
                        selectedEndCustomers={selectedEndCustomers} setSelectedEndCustomers={setSelectedEndCustomers}
                        selectedProjects={selectedProjects} setSelectedProjects={setSelectedProjects}
                    />*/}
                    <OffersFilter setFilter={setFilter}/>
                    {/*<PaginatedTable
                        data={data}
                        columns={columns}
                        onParamsChange={run}
                        options={tableOptions}
                        isLoading={loading}
                    />*/}
                    <OffersTable data={data.data} filter={filter} onUpdate={handleBillUpdate}
                                 onCreate={handleBillCreate} onDelete={handleBillDelete}
                    permissions={['offers:edit']}/>
                </div>
            </LoadingSpinner>
        </>
    )
}

export default Offers;