import {Outlet} from "react-router-dom";
import PermissionChecker from "../Theme/PermissionChecker";

export const ProductsWrapper =()=>{
    return(
        <PermissionChecker permissions={['products:view']} navigateTo={'/404'}>
            <Outlet/>
        </PermissionChecker>

    )
}
export default ProductsWrapper