import React from "react";

export const SlideOverDetails =({children})=>{
    return(
        <>
            <div>
                <h3 className="font-medium text-gray-900">Dettagli</h3>
                <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                    {children}
                </dl>
            </div>
        </>
    )
}
export default SlideOverDetails