import ServiceForm from "./components/ServiceForm";
import {useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import serviceService from "../../services/ServiceService";
import LoadingSpinner from "../../components/UI/LoadingSpinner";

export const EditService = () => {
    const {uuid} = useParams();
    const {data = [], loading} = useRequest(serviceService.serviceShow, {
        defaultParams: [uuid]
    })

    return (
        <>
            <LoadingSpinner loading={loading}>
                <ServiceForm service={data}/>
            </LoadingSpinner>

        </>
    )
}

export default EditService;