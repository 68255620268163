import * as Yup from "yup";
import {Form, FormikProvider, useFormik} from "formik";
import _ from "lodash";
import {FormGroup} from "../../../components/UI/Forms/FormGroup";
import {Input} from "../../../components/UI/Forms/Input";
import {FormInput} from "../../../components/UI/Fields/FormInput";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import {useRequest} from "ahooks";
import CustomerService from "../../../services/CustomerService";
import {Select} from "../../../components/UI/Forms/Select";
import projectService from "../../../services/ProjectService";
import CancelButton from "../../../components/UI/Buttons/CancelButton";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";

export default function ProjectForm({project = undefined}) {
    const {uuid} = useParams();
    const location = useLocation();
    const {data = []} = useRequest(CustomerService.companies);
    const navigation = useNavigate();
    const {flashMsg} = useFlashMessage();

    const createProject = (data, setSubmitting) => {
        projectService.createProject(data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Progetto Creato', null, myuuid, true);
                navigation(-1);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const updateProject = (data, setSubmitting) => {
        projectService.updateProject(uuid, data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Progetto Modificato con Successo', null, myuuid, true);
                navigation(-1);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const handleSubmit = (values, {setSubmitting}) => {
        const data = {
            name: values.name,
            company_id: values.company_id,
            end_customer_id: values.end_customer_id,
            note: values.note
        }
        project ? updateProject(data, setSubmitting) : createProject(data, setSubmitting)
    }

    const initialValues = {
        name: project?.name ?? '',
        company_id: project?.company?.id ?? location?.state?.company_id ?? '',
        end_customer_id: project?.end_customer?.id ?? '',
        note: project?.note ?? '',
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().nullable().required(),
        company_id: Yup.number().nullable().required(),
        end_customer_id: Yup.number().nullable().required(),
        note: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <div className="space-y-12">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div className='sm:ms-4'>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">{project ? 'Modifica' : 'Nuovo'} Progetto</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">{project ? 'Modifica' : 'Inserisci'} le
                                    informazioni del progetto</p>
                            </div>

                            <div
                                className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                <div className="sm:col-span-full">
                                    <FormGroup required label='Nome' name='name'>
                                        <Input reuired label='name' name='name' value={formik.values.name}/>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required label='Cliente' name='company_id'>
                                        <div className="mt-2">
                                            <Select
                                                value={formik.values.company_id}
                                                name="company_id"
                                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                            >
                                                {data.data ?
                                                    <>
                                                        <option value='' className='text-center'>--SELEZIONA
                                                            AZIENDA---
                                                        </option>
                                                        {data.data.map(item => (
                                                            <option key={item.id}
                                                                    value={item.id}>{item.business_name}</option>
                                                        ))}
                                                    </>

                                                    : <option>Loading</option>
                                                }

                                            </Select>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required label='Cliente Finale' name='end_customer_id'>
                                        <div className="mt-2">
                                            <Select
                                                value={formik.values.end_customer_id}
                                                name="end_customer_id"
                                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                            >
                                                {data.data ?
                                                    <>
                                                        <option value='' className='text-center'>--SELEZIONA
                                                            AZIENDA---
                                                        </option>
                                                        {data.data.map(item => (
                                                            <option key={item.id}
                                                                    value={item.id}>{item.business_name}</option>
                                                        ))}
                                                    </>

                                                    : <option>Loading</option>
                                                }

                                            </Select>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="note"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Note
                                    </label>
                                    <FormInput
                                        name='note'
                                        type='textarea'
                                        autoComplete=''
                                        value={formik.values.note}
                                        onChange={event => formik.setFieldValue('note', event.target.value)}
                                        error={_.get(formik.errors, 'note')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <CancelButton/>
                        <SubmitButton label={project ? 'Salva' : 'Crea'}/>
                    </div>
                </Form>
            </FormikProvider>
        </>
    )
}
