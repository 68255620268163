import InfoTableField from "../../../components/UI/Fields/InfoTableField";
import React from "react";
import StateDropDown from "../../../components/UI/StateDropDown";
import {v4 as uuidv4} from "uuid";
import {offerServices} from "../../../services/OfferService";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {useParams} from "react-router-dom";
import {InfoNoteField} from "../../../components/UI/Fields/InfoNoteField";
import CardHeader from "../../../components/CardHeader";
import EuroFormat from "../../../components/UI/EuroFormat";
import DateFormat from "../../../components/UI/DateFormat";

export const OfferShowInfo = ({data, onStateUpdate, onDelete}) => {
    const {flashMsg} = useFlashMessage();
    const {uuid} = useParams();

    const changeStateHandler = (value) => {
        let myuuid = uuidv4();
        offerServices.updateState(uuid, {
            state: value
        })
            .then(res => {
                flashMsg('Stato Cambiato Con Successo', null, myuuid, true);
                onStateUpdate(res);
            })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false);
            })
    }

    return (
        <div>
            <CardHeader title={data?.billable?.label?.toUpperCase()} onDelete={onDelete} permissions={['offers:edit']}/>
            <div className="mt-6">
                <div className="grid grid-cols-1 sm:grid-cols-2">
                    <InfoTableField title='Cliente'
                                    value={data?.company.business_name ? data?.company.business_name : undefined}
                                    link={`/companies/${data?.company.uuid}`}
                    />
                    <InfoTableField title='Cliente Finale'
                                    value={data?.end_customer?.business_name ? data?.end_customer?.business_name : undefined}
                                    link={`/companies/${data?.end_customer?.uuid}`}
                    />
                    <InfoTableField title='Progetto'
                                    value={data?.project?.name ? data?.project?.name : undefined}
                                    link={`/projects/${data?.project?.uuid}`}
                    />
                    <InfoTableField title='Numero' value={data?.full_number}/>
                    <InfoTableField title='Prezzo Proposto' value={<EuroFormat value={data?.proposed_price}/>}/>
                    <InfoTableField title='Prezzo Accettato' value={<EuroFormat value={data?.accepted_price}/>}/>
                    <InfoTableField title='Data' value={<DateFormat value={data?.date}/> }/>
                    <InfoTableField title='Stato Cambiato il' value={<DateFormat value={data?.state_changed_at}/>}/>
                    {/*<InfoTableStatusField state={props?.data?.state}/>*/}
                    {/*<div className="flex items-center justify-center border-t border-gray-100 px-4 py-4 sm:col-span-full sm:px-0">*/}
                    <div
                        className="flex justify-center border-t border-gray-100 px-4 py-4 sm:col-span-full sm:px-0 me-5">
                        <StateDropDown available_states={data?.states?.available_states}
                                       value={data?.states?.state}
                                       onStateUpdate={changeStateHandler} permissions={['offers:edit']}/>
                    </div>
                    {/*</div>*/}
                    {/*{props?.data?.state.value === 'active' ? <SuspendButton/> : (props?.data?.state.value === 'draft' ? <ActivateButton/> : null)}*/}
                    {data?.description ?
                        <InfoNoteField title={'Descrizione'} value={data?.description}/>
                        : null
                    }
                    {data?.note ?
                        <InfoNoteField value={data?.note}/>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default OfferShowInfo;