import React, {useState} from "react";
import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import DeleteModal from "../../../../components/UI/DeleteModal";
import RevisionProductPriceForm from "./RevisionProductPriceForm";
import ActionButtonsSlideOver from "../../../../components/UI/Buttons/ActionButtonsSlideOver";

export const ShowRevisionPriceItemSlideOver = (props) => {
    const {state, onClose, price, revisionUuid, productUuid, onProductPriceDelete, onProductPriceUpdate} = props;
    const [showModal, setShowModal] = useState(false);
    const [disabled, setDisabled] = useState(true)

    const handleUpdatePrice = res => {
        onProductPriceUpdate(res, productUuid);
    }


    return (
        <>
            <SlideOver title={'Informazioni Prezzo'}
                       subtitle={'Modifica le informazioni di questo prezzo per questa revisione'}
                       state={state}
                       size={'max-w-md'}
                       onClose={onClose}>

                <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                    <ActionButtonsSlideOver editAction={e => setDisabled(false)} deleteAction={e => setShowModal(true)}/>
                    <RevisionProductPriceForm price={price}
                                              revisionUuid={revisionUuid}
                                              productUuid={productUuid}
                                              onPriceUpdated={handleUpdatePrice}
                                              isDisabled={disabled}/>
                </div>

            </SlideOver>
            <DeleteModal title={price.description}
                         onClose={() => setShowModal(false)}
                         onDelete={e => onProductPriceDelete(revisionUuid, productUuid, price?.uuid)}
                         state={showModal}
            />
        </>
    )
}

export default ShowRevisionPriceItemSlideOver;