import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";
import EuroFormat from "../../../components/UI/EuroFormat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGripVertical} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import PriceSlideOver from "./PriceSlideOver";
import PermissionChecker from "../../../components/Theme/PermissionChecker";

export const PriceItem = ({price, is_default = false, onPriceDelete, onPriceUpdated, permissions, onClick}) => {
    const [showEditSlideOver, setShowEditSlideOver] = useState(false)

    return (
        <>
            <div className='flex items-center min-w-full'>
                <PermissionChecker permissions={permissions}>
                    <button className='me-3'>
                        <FontAwesomeIcon icon={faGripVertical} className="-ml-0.5 h-4 w-4" color={'gray'}/>
                        {/*<HandRaisedIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>*/}
                    </button>
                </PermissionChecker>
                <div
                    className={`relative overflow-hidden border ${is_default ? 'border-black' : 'border-gray-300'} rounded-lg bg-white shadow-md px-3 py-2 w-full`}>
                    <div className='flex items-center'>
                        <p className='mr-6 text-sm basis-1/6 text-left flex items-center'><EuroFormat
                            value={price?.unit_price}/></p>
                        <p className='text-sm flex-1 text-left items-center'>{price?.description}</p>
                        <PermissionChecker permissions={permissions}>
                            <button
                                onClick={()=>onClick(price)}
                                className='ml-auto inline-flex ps-3 items-center gap-x-2 rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600'>
                                <ArrowTopRightOnSquareIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </PermissionChecker>
                    </div>
                </div>
            </div>
            {/*<PriceSlideOver state={showEditSlideOver}
                            price={price}
                            onClose={() => setShowEditSlideOver(false)}
                            onPriceUpdated={onPriceUpdated}
                            onPriceDelete={onPriceDelete}/>*/}
        </>
    )
}