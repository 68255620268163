import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import React from "react";
import StateDropDown from "../../../components/UI/StateDropDown";
import {PrinterIcon} from "@heroicons/react/24/outline";
import {revisionServices} from "../../../services/RevisionService";
import {v4 as uuidv4} from "uuid";
import {useParams} from "react-router-dom";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import quoteServices from "../../../services/QuoteService";
import TableCardHeader from "../../../components/TableCardHeader";
import DateFormat from "../../../components/UI/DateFormat";

export const RevisionHeader = ({revision, onNew, onChange}) => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();
    const handleNewRevision = () => {
        revisionServices.storeRevision(uuid).then(res => {
            let myuuid = uuidv4();
            flashMsg('Nuova Revisione Creata', undefined, myuuid, true)
            onNew(res)
        })
            .catch(err => {
                let myuuid = uuidv4();
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
            });
    }

    const handlePrint = () => {
        quoteServices.quotePrint(uuid, revision?.uuid)
            .then(res => {
                window.open(URL.createObjectURL(res));
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
    }


    return (
        <>
            <TableCardHeader title={'Revisioni'} action={{'action': handleNewRevision, 'permissions': ['quotes:edit']}}/>
            <div className='flex mt-6'>
                <div className="flex justify-between sm:justify-start">
                    <button
                        disabled={revision?.previous_revision?.uuid ? false : true}
                        onClick={e => onChange(revision?.previous_revision?.uuid)}
                        className={`relative inline-flex items-center rounded-full bg-white px-1 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 ${revision?.previous_revision?.uuid ? 'hover:text-white hover:bg-green-700' : ''} focus-visible:outline-offset-0`}
                    >
                        <ChevronLeftIcon className='h-5 w-5'/>
                    </button>
                    <div
                        className="relative ml-3 w-16 items-center text-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 focus-visible:outline-offset-0"
                    >
                        N. {revision?.incremental_number}
                    </div>
                    <button
                        disabled={revision?.next_revision?.uuid ? false : true}
                        onClick={e => onChange(revision?.next_revision?.uuid)}
                        className={`relative ml-3 inline-flex items-center rounded-full bg-white px-1 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 ${revision?.next_revision?.uuid ? 'hover:text-white hover:bg-green-700' : ''} focus-visible:outline-offset-0`}
                    >
                        <ChevronRightIcon className='h-5 w-5'/>
                    </button>
                </div>
                <div className='md:ml-4 sm:ml-4 ml-8 flex items-center justify-left text-center flex-1'>
                    <h1>{revision?.subject.toUpperCase()}</h1>
                </div>
                <div className='flex items-center text-right'>
                    <h1 className='text-sm'>{<DateFormat value={revision?.revision_date}/>}</h1>
                    <div className="ml-3 flex justify-center items-center md:w-40">
                        <StateDropDown available_states={revision?.states?.available_states}
                                       value={revision?.states?.state}
                                       type={'revision'} /*onStateUpdate={props.onStateUpdate}*//>
                    </div>
                </div>
                <div className='flex flex-row gap-x-5 gap-y-2 pl-5'>
                    {/*<div className='flex items-center'>
                        <button
                            onClick={handleNewRevision}
                            className='rounded-md border border-black px-1 py-1 hover:bg-green-600 hover:text-white hover:border-white'>
                            <ClipboardDocumentIcon className='h-6 w-6'/>
                        </button>
                    </div>*/}
                    <div className='flex items-center'>
                        <button
                            onClick={handlePrint}
                            className='rounded-md border border-black px-1 py-1 hover:bg-green-600 hover:text-white hover:border-white'>
                            <PrinterIcon className='h-6 w-6'/>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}