import {Field, Form, FormikProvider, useFormik} from "formik";
import SlideOverFormInput from "../../../Packages/components/SlideOverFormInput";
import slideOverFormInput from "../../../Packages/components/SlideOverFormInput";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";
import * as Yup from "yup";
import {FormGroup} from "../../../../components/UI/Forms/FormGroup";
import {revisionServices} from "../../../../services/RevisionService";
import {v4 as uuidv4} from "uuid";
import {useParams} from "react-router-dom";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import {useRequest} from "ahooks";
import productServices from "../../../../services/ProductService";
import {Select} from "../../../../components/UI/Forms/Select";
import LoadingSpinner from "../../../../components/UI/LoadingSpinner";

export const RevisionProductForm = ({product, revisionUuid, onProductUpdate}) => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();

    const {data: areas, loading} = useRequest(productServices.productAreas);


    const initialValues = {
        area: product?.area?.value ?? '',
        qty: product?.qty ?? '',
        note: product?.note ?? '',
        description: product?.description ?? '',
    }

    const validationSchema = Yup.object().shape({
        area: Yup.string().nullable(),
        qty: Yup.number().nullable().required(),
        description: Yup.string().required(),
        note: Yup.string().nullable(),
    })

    const handleSubmit = (values, {setSubmitting}) => {
        const data = {
            area: values.area,
            qty: values.qty,
            note: values.note,
            description: values.description,
        }

        revisionServices.updateProduct(uuid, revisionUuid, product?.uuid, data).then(res => {
            let myuuid = uuidv4();
            setSubmitting(false);
            flashMsg('Prodotto Aggiornato', undefined, myuuid, true);
            onProductUpdate(res);
        })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <>
            <LoadingSpinner loading={loading}>
                <FormikProvider value={formik}>
                    <Form>
                        <FormGroup required={true} label='Quantità' name='qty'>
                            <div className="mt-2">
                                <Field as={slideOverFormInput} required type='number' label='qty'
                                       name='qty' value={formik.values.qty}/>
                            </div>
                        </FormGroup>
                        <div className='mt-6'>
                            {areas ?
                                <FormGroup required={true} label='Area' name='area'>
                                    <Field as={Select} value={formik.values.area} name="area">
                                        <>
                                            <option className='text-center' value=''>--SELEZIONA AREA---</option>
                                            {areas?.map(area => (
                                                <option key={area.value}
                                                        value={area.value}>{area.label}</option>
                                            ))}
                                        </>


                                    </Field>
                                </FormGroup>
                                :
                                <>
                                </>
                            }
                        </div>
                        <div className='mt-6'>
                            <FormGroup label='Descrizione' name='description' required>
                                <Field as={SlideOverFormInput} label='description' type='textarea'
                                       name='description' value={formik.values.description}
                                       onChange={event => formik.setFieldValue('description', event.target.value)}
                                />
                            </FormGroup>
                        </div>
                        <div className='mt-6'>
                            <FormGroup label='Note' name='note'>
                                <Field as={SlideOverFormInput} label='note' type='textarea'
                                       name='note' value={formik.values.note}
                                       onChange={event => formik.setFieldValue('note', event.target.value)}
                                />
                            </FormGroup>
                        </div>
                        <div className='mt-2 text-right'>
                            <SubmitButton label={product ? 'Aggiorna' : 'Aggiungi'}/>
                        </div>

                    </Form>
                </FormikProvider>

            </LoadingSpinner>
        </>
    )
}