import React, {useState} from "react";
import NewElement from "../../../../components/UI/Buttons/NewElement";
import TableCardHeader from "../../../../components/TableCardHeader";
import PermissionChecker from "../../../../components/Theme/PermissionChecker";
import ContactTableField from "./ContactTableField";
import ContactSlideOver from "./ContactSlideOver";

export const ContactsTable = ({contacts, model, modelUuid, onCreate, onUpdate, onDelete}) => {
    const [showSlideOver, setShowSlideOver] = useState(false);
    const [selectedContact, setSelectedContact] = useState()


    return (
        <>
            {contacts?.length > 0 ? (
                    <>
                        <TableCardHeader title={'Contatti'} action={{
                            'action': () => (setSelectedContact() & setShowSlideOver(true)),
                            'permissions': ['contacts:edit']
                        }}/>
                        <PermissionChecker permissions={['contacts:view']}
                                           message={'NON HAI IL PERMESSO DI VISUALIZZARE I RECAPITI'}>
                            <ul className="divide-y divide-gray-100">
                                {contacts?.map((contact) => (
                                    <ContactTableField contact={contact} key={contact.id}
                                                       onClick={() => (setSelectedContact(contact) & setShowSlideOver(true))}/>
                                ))}
                            </ul>
                        </PermissionChecker>
                    </>
                )
                :
                <PermissionChecker permissions={['contacts:edit']} message={'NON HAI IL PERMESSO DI AGGIUNGERE NUOVI RECAPITI'}>
                    <NewElement type={'contact'}
                                onClick={() => (setSelectedContact() & setShowSlideOver(true))}/>
                </PermissionChecker>
            }
            <ContactSlideOver state={showSlideOver} model={model} modelUuid={modelUuid} contact={selectedContact}
                              onCreate={onCreate}
                              onUpdate={onUpdate}
                              onDelete={onDelete}
                              onClose={() => setShowSlideOver(false)}/>
        </>
    )
}

export default ContactsTable;
