import InfoTableField from "../../../components/UI/Fields/InfoTableField";
import React from "react";
import {v4 as uuidv4} from "uuid";
import {CircleStackIcon, ComputerDesktopIcon, EnvelopeIcon, ServerStackIcon} from "@heroicons/react/24/outline";
import CreateOfferButton from "./CreateOfferButton";
import StateDropDown from "../../../components/UI/StateDropDown";
import serviceService from "../../../services/ServiceService";
import {useParams} from "react-router-dom";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {InfoNoteField} from "../../../components/UI/Fields/InfoNoteField";
import InfoCard from "../../../components/UI/Cards/InfoCard";
import PermissionChecker from "../../../components/Theme/PermissionChecker";
import DateFormat from "../../../components/UI/DateFormat";
import TrueFalseBadge from "../../../components/UI/TrueFalseBadge";
import EuroFormat from "../../../components/UI/EuroFormat";

const typeIcon = {
    hosting: <ServerStackIcon className='h-6 w-6'/>,
    newsletter: <EnvelopeIcon className='h-6 w-6'/>,
    storage: <CircleStackIcon className='h-6 w-6'/>,
    vps: <ComputerDesktopIcon className='h-6 w-6'/>
}

export const ServiceShowInfo = ({data, onStateUpdate, onDelete}) => {
    const {flashMsg} = useFlashMessage();
    const {uuid} = useParams();

    const changeStateHandler = (value) => {
        serviceService.updateState(uuid, {
            state: value
        })
            .then(res => {
                flashMsg('Stato Cambiato Con Successo', null, uuidv4(), true);
                onStateUpdate(res);
            })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false);
            })
    }

    return (
        <InfoCard title={data?.type?.label?.toUpperCase()} onDelete={onDelete} icon={typeIcon[data?.type?.value]} permissions={['services:edit']}>
            <InfoTableField title='Relativo a'
                            value={data?.company.business_name ? data?.company.business_name : undefined}
                            link={`/companies/${data?.company.uuid}`}
            />
            <InfoTableField title='Amount' value={<EuroFormat value={data?.amount}/>}/>
            <InfoTableField title='Inizia il' value={<DateFormat value={data?.start_at}/>}/>
            <InfoTableField title='Durata' value={data?.duration + ' giorni'}/>
            <InfoTableField title='Auto Renew' value={<TrueFalseBadge value={data?.is_auto_renew}/>}/>
            <InfoTableField title='Numero Di Rinnovi' value={data?.renewal_count}/>
            <div className="flex justify-center border-t border-gray-100 px-4 py-4 sm:col-span-full sm:px-0">
                <StateDropDown available_states={data?.states?.available_states}
                               value={data?.states?.state}
                               onStateUpdate={changeStateHandler}
                               permissions={['services:edit']}
                />
            </div>
            {data?.states?.state?.value !== 'terminated' && data?.states?.state?.value !== 'draft'
                ? <PermissionChecker permissions={['offers:edit']}>
                    <CreateOfferButton service={data}/>
                </PermissionChecker> : <></>}
            {data?.note ?
                <InfoNoteField value={data?.note}/>
                : null
            }
        </InfoCard>
    )
}

export default ServiceShowInfo;