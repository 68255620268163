import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

export const PageBreadcrumbLink = ({ to = undefined, label }) => (
  <div className="flex items-center">
    <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
      {to ? <Link
          to={to}
          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
          {label}
      </Link>
      : <label className="ml-4 text-sm font-medium text-gray-500">{label}</label>
      }
  </div>
)
