import TableCardHeader from "../../../../components/TableCardHeader";
import PermissionChecker from "../../../../components/Theme/PermissionChecker";
import NewElement from "../../../../components/UI/Buttons/NewElement";
import React, {useEffect, useState} from "react";
import SiteTableItem from "./SiteTableItem";
import SiteSlideOver from "./SiteSlideOver";
import serverServices from "../../../../services/ServerService";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";

export const SitesTable = ({
                               sites,
                               serverUuid
                           }) => {

    const [showSiteSlideOver, setShowSiteSlideOver] = useState(false);
    const [updatedSites, setUpdatedSites] = useState(sites);

    const {flashMsg} = useFlashMessage();

    useEffect(() => {
        setUpdatedSites(sites)
    }, [sites]);

    const addSiteHandler=(res)=>{
        setUpdatedSites(updatedSites ? [...updatedSites, res] : [res])
    }

    const updateSiteHandler = (res)=>{
        setUpdatedSites(updatedSites.map(s => s.uuid !== res.uuid ? s : res))
    }

    const deleteSiteHandler = (modelUuid, siteUuid) => {
        serverServices.deleteSite(modelUuid, siteUuid).then(res => {
                flashMsg(res.message, null, uuidv4(), true)
                setUpdatedSites(updatedSites.filter(site => site.uuid !== siteUuid));
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
    }

    return (
        <>
            {updatedSites?.length > 0 ?
                <>
                    <TableCardHeader title='Siti' action={{
                        'action': e => (setShowSiteSlideOver(true)),
                        //permissions: ['sites:edit']
                    }}/>
                    <PermissionChecker /*permissions={['sites:view']}*/
                        message={'NON HAI IL PERMESSO DI VISUALIZZARE I SITI'}>
                        <ul className="divide-y divide-gray-100">
                            {updatedSites?.map(site =>
                                <SiteTableItem key={site?.id}
                                               site={site}
                                               onUpdate={updateSiteHandler}
                                               onDelete={deleteSiteHandler}
                                               serverUuid={serverUuid}
                                />
                            )}
                        </ul>
                    </PermissionChecker>
                </>
                :
                <PermissionChecker /*permissions={['databases:edit']}*/
                    message={'NON HAI IL PERMESSO DI AGGIUNGERE NUOVI SITI'}>
                    <NewElement type='site'
                                onClick={() => setShowSiteSlideOver(true)}/>
                </PermissionChecker>
            }

            <SiteSlideOver serverUuid={serverUuid} state={showSiteSlideOver} onCreate={addSiteHandler}
                           onClose={() => setShowSiteSlideOver(false)}/>

        </>
    )
}

export default SitesTable