import { PageHeader } from './PageHeader/PageHeader';
import { PageContent } from './PageContent/PageContent';
import { MainNavbar } from './MainNavbar/MainNavbar';
import { Outlet } from 'react-router-dom';
import FlashMessagesArea from "./FlashMessagesArea";

export const Layout = () => {
  return (
    <div className="min-h-full">
      <MainNavbar/>
      <PageHeader/>
      <PageContent>
        <Outlet/>
      </PageContent>
    <FlashMessagesArea/>
    </div>
  )
}
