import React, {useEffect, useState} from "react";
import {useRequest} from "ahooks";
import packageServices from "../../../../services/PackageService";
import {useNavigate} from "react-router-dom";
import PackageTableStatus from "./PackageTableStatus";
import PackageTableCol from "./PackageTableCol";
import {DetailsButton} from "./DetailsButton";
import LoadingSpinner from "../../../../components/UI/LoadingSpinner";
import NewElement from "../../../../components/UI/Buttons/NewElement";
import TableCardHeader from "../../../../components/TableCardHeader";
import PermissionChecker from "../../../../components/Theme/PermissionChecker";

export const PackagesTable = ({project_uuid, company_uuid, company_id, project_id = undefined}) => {
    const navigation = useNavigate();
    const [extraParams] = useState({
        filter: {
            company_uuid: company_uuid ?? '',
            project_uuid: project_uuid ?? '',
        },
    });

    const {data: packages = [], loading, run} = useRequest(packageServices.packages, {
        manual: true,
        ready: company_id !== undefined
    });

    useEffect(() => {
        run(extraParams)
    }, [extraParams, run]);

    /*  const columnsPack = useMemo(() => PackagesTableConfig, []);*/


    return (
        <>
            <LoadingSpinner loading={loading}>
                {packages?.data?.length > 0 ?
                    <>
                        <TableCardHeader title='Pacchetti' action={{
                            'action': e => navigation('/packages/create', {
                                state: {
                                    company_id: company_id,
                                    project_id: project_id
                                }
                            }), 'permissions': ['packages:edit']
                        }}/>
                        <PermissionChecker permissions={['packages:view']} message={'NON HAI IL PERMESSO DI VISUALIZZARE I PACCHETTI'}>
                        <table className="min-w-full">
                            <thead>
                            <tr>
                                <th scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Stato
                                </th>
                                <th scope="col" className="py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Ore Utilizzate
                                </th>
                                <th scope="col" className="py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Giorni Rimasti
                                </th>
                                <th scope="col" className="py-3.5 text-center text-sm font-semibold text-gray-900">
                                    Progetto
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span className="sr-only">Details</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                            {packages?.data?.map((pack) => (
                                <tr key={pack.id} /*className="items-center justify-between gap-x-6 py-4"*/>
                                    <td className='text-center'><PackageTableStatus pack={pack}/></td>
                                    <td className='text-center'><PackageTableCol
                                        field={`${pack?.used_amount}/${pack.amount}`}/></td>
                                    <td className='text-center'><PackageTableCol field={pack?.expiration_days_left}/>
                                    </td>
                                    <td className='text-center'><PackageTableCol field={pack?.project?.name}/></td>
                                    <td className='py-4 text-right'><DetailsButton uuid={pack?.uuid}/>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </PermissionChecker>
                    </>
                    :
                    <PermissionChecker permissions={['packages:edit']}
                                       message={'NON HAI IL PERMESSO DI AGGIUNGERE NUOVI PACCHETTI'}>
                        <NewElement type={'package'} onClick={() => navigation('/packages/create', {
                            state: {
                                company_id: company_id,
                                project_id: project_id
                            }
                        })}/>
                    </PermissionChecker>
                }
            </LoadingSpinner>


        </>

    )
}