import React from "react";
import {useFormikContext} from "formik";

export const DiscountInput = ({
                              name,
                              type = "number",
                              value,
                                isDisabled=false,
                          }) => {
    const {setFieldValue} = useFormikContext()
    return (
        <>
            <div className="relative mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">%</span>
                </div>
                <input
                    id={name}
                    name={name}
                    type={type}
                    value={value}
                    disabled={isDisabled}
                    placeholder='0'
                    onChange={event => setFieldValue(name, event.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
            </div>
        </>
    )
}