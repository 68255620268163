import React from 'react';
import { Link } from 'react-router-dom';

export const MainNavbarMenuPopoverItem = ({ item }) => {

  return (
    <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
      <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" aria-hidden="true"/>
      </div>
      <div>
        <Link to={item.action} className="font-semibold text-gray-900 hover:text-green-600">
          {item.name}
          <span className="absolute inset-0"/>
        </Link>
        <p className="mt-1 text-gray-600">{item.description}</p>
      </div>
    </div>
  )


}
