import TableLink from "../../Buttons/TableLink";

const QuotesTableConfig = [
    {
        Header: 'N.',
        accessor: 'number',
        Cell: ({ row, value }) => <div className='mt-2 text-green-600 text-sm'>
            <TableLink to={row.original.uuid} value={value}/>
        </div>
    },{
    Header: 'Cliente',
    accessor: 'company.business_name',
    Cell: ({ row, value }) =>
        <div className='text-gray-500 mt-2 text-sm'>
            <TableLink to={`/companies/${row.original.company.uuid}`} value={value}/>
        </div>

},
]

export default QuotesTableConfig;