import {useNavigate} from "react-router-dom";

const CancelButton = ({action}) => {
    const navigate = useNavigate();

    return (
        <>
            <button
                type="button"
                onClick={action ? (action) : (() => navigate(-1))}
                className="text-sm font-semibold leading-6 text-gray-900"
            >
                Annulla
            </button>

        </>
    )
}

export default CancelButton;