import api from '../configs/api.config';

const vehicleUrl = '/vehicles';
const vehicles = params => api.get(vehicleUrl, {params}).then(res => res.data);
const createVehicle = params => api.post(vehicleUrl, params).then(res => res.data);
const updateVehicle = (uuid, params) => api.put(`${vehicleUrl}/${uuid}`, params).then(res => res.data);
const deleteVehicle = (id, params) => api.delete(`${vehicleUrl}/${id}`, params).then(res => res.data);
const vehicleOptions = params => api.get('/options/vehicles', {params}).then(res => res.data);


const vehicleServices = {
    vehicles,
    createVehicle,
    updateVehicle,
    deleteVehicle,
    vehicleOptions
}

export default vehicleServices