import CompanyServiceTableItem from "./CompanyServiceTableItem";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import serviceService from "../../../../services/ServiceService";
import LoadingSpinner from "../../../../components/UI/LoadingSpinner";
import NewElement from "../../../../components/UI/Buttons/NewElement";
import TableCardHeader from "../../../../components/TableCardHeader";
import PermissionChecker from "../../../../components/Theme/PermissionChecker";

export default function CompanyServiceTable({company_id, services_count}) {
    const {uuid} = useParams();
    const navigation = useNavigate();
    const [extraParams] = useState({
        filter: {
            company_uuid: uuid,
        },
    });

    useEffect(() => {
        run(extraParams)
    }, [extraParams.filter]);

    const {data: services = [], loading, run} = useRequest(serviceService.services, {
        manual: true,
    });


    /*    const columns = useMemo(() => [{
            Header: <h2 className='font-bold text-center'>Servizi</h2>,
            accessor: 'type',
            Cell: ({ row, value }) => <li className="flex items-center justify-between gap-x-6 py-4">
                <CompanyServiceTableItem service={row.original}/>
            </li>
        }], []);*/

    return (
        <>
            {services_count > 0 ?
                <>
                    {/*<h2 className='font-bold text-left'>Servizi</h2>*/}
                    <TableCardHeader title='Servizi' action={{
                        'action': () => navigation('/services/create', {state: {company_id: company_id}}),
                        permissions: ['services:edit']
                    }}/>
                    <PermissionChecker permissions={['services:view']} message={'NON HAI IL PERMESSO DI VISUALIZZARE I SERVIZI'}>
                        <LoadingSpinner loading={loading}>
                            <ul className="divide-y divide-gray-100">
                                {services?.data?.map((service) => (
                                    <li key={service.id} className="flex items-center justify-between gap-x-6 py-3">
                                        <CompanyServiceTableItem service={service}/>
                                    </li>
                                ))}
                            </ul>
                        </LoadingSpinner>
                    </PermissionChecker>
                </>
                :
                <PermissionChecker permissions={['services:edit']} message={'NON HAI IL PERMESSO DI AGGIUNGERE NUOVI SERVIZI'}>
                    <NewElement type='service'
                                onClick={() => navigation('/services/create', {state: {company_id: company_id}})}/>
                </PermissionChecker>

            }
        </>


    )
}
