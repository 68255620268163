import {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'
import LoadingSpinner from "../LoadingSpinner";

export default function SlideOver({state, onClose, children, title, loading, subtitle = undefined, size = 'max-w-screen-sm'}) {
    //const [open, setOpen] = useState(false);

    /*useEffect(() => {
        setTimeout(setOpen(state), 1000)
    }, [state]);*/

    const closeHandler = () => {
        //setOpen(false);
        onClose();
        //setTimeout(onClose, 1000);
    }

    return (


        <Transition.Root show={state} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeHandler}>
                <div className="fixed inset-0"/>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className={`pointer-events-auto w-screen ${size}`}>

                                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                        <div className="bg-green-700 px-4 py-6 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title
                                                    className="text-base font-semibold leading-6 text-white">
                                                    {title}
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md bg-green-700 text-green-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        onClick={closeHandler}
                                                    >
                                                        <span className="absolute -inset-2.5"/>
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                            {subtitle ? (
                                                    <div className="mt-1">
                                                        <p className="text-sm text-green-300">
                                                            {subtitle}
                                                        </p>
                                                    </div>)
                                                :
                                                undefined}
                                        </div>
                                        {/* <div className="relative flex-1 px-4 py-6 sm:px-6">*/}
                                        <LoadingSpinner loading={loading}>
                                            <div className="h-full overflow-y-auto bg-white p-8">
                                                <div className="space-y-6 pb-16">
                                                    {children}
                                                </div>
                                            </div>
                                        </LoadingSpinner>
                                    </div>
                                    {/*</div>*/}

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}