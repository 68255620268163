import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";
import SlideOverFormInput from "../../../Packages/components/SlideOverFormInput";
import {SlideOverFormGroup} from "../../../../components/UI/Forms/SlideOverFormGroup";
import {personServices} from "../../../../services/PersonService";


const validationSchema = Yup.object().shape({
    first_name: Yup.string().nullable().required(),
    last_name: Yup.string().nullable().required(),
    note: Yup.string().nullable(),
})

export const PersonForm = ({person, companyUuid, onCreate, onUpdate, isDisabled}) => {
    const {flashMsg} = useFlashMessage();

    const initialValues = {
        first_name: person?.first_name ?? '',
        last_name: person?.last_name ?? '',
        note: person?.note ?? '',
    }

    const createPerson = (data, setSubmitting) => {
        personServices.createPerson(companyUuid, data)
            .then(res => {
                flashMsg('Persona Creata', undefined, uuidv4(), true)
                onCreate(res);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const updatePerson = (data, setSubmitting) => {
        personServices.updatePerson(companyUuid, person?.uuid, data)
            .then(res => {
                flashMsg('Persona Modificata', undefined, uuidv4(), true)
                onUpdate(res);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            first_name: values.first_name,
            last_name: values.last_name,
            note: values.note
        }

        person ? updatePerson(data, setSubmitting) : createPerson(data, setSubmitting)
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <FormikProvider value={formik}>
            <Form>
                <div className="space-y-8">
                    <div
                        className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 pb-4">

                        <div
                            className="grid grid-cols-1 gap-x-2 gap-y-3 sm:grid-cols-6">

                            <div className="col-span-full">
                                <SlideOverFormGroup required label='Nome' name='first_name'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} required label='first_name'
                                               name='first_name' value={formik.values.first_name} isDisabled={isDisabled}/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>

                            <div className="col-span-full">
                                <SlideOverFormGroup required label='Cognome' name='last_name'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} required label='last_name'
                                               name='last_name' value={formik.values.last_name} isDisabled={isDisabled}/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>


                            <div className="col-span-full">
                                <SlideOverFormGroup label='Note' name='note'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} label='note'
                                               name='note' value={formik.values.note} type='textarea' isDisabled={isDisabled}/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                {!isDisabled && <div className="mt-4 flex items-center justify-end gap-x-6">
                    <SubmitButton label={person ? 'Salva' : 'Inserisci'}/>
                </div>}
            </Form>
        </FormikProvider>
    )
}

export default PersonForm