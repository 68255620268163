import React from "react";
import {PageBreadcrumbLink} from "../../components/Theme/PageHeader/PageBreadcrumbLink";
import {Outlet} from "react-router-dom";
import Users from "../../pages/Users/Users";
import UserShow from "../../pages/Users/UserShow";
import {NewUser} from "../../pages/Users/NewUser";
import EditUser from "../../pages/Users/EditUser";
import {UsersWrapper} from "../../components/Wrappers/UsersWrapper";
import PermissionChecker from "../../components/Theme/PermissionChecker";

export const userRoutes = [
    {
        path: '/users',
        element: <UsersWrapper/> ,
        handle: {
            crumb: () => <PageBreadcrumbLink to="/users" label="Utenti"/>
        },
        children: [{
            path: ':uuid',
            element: <><Outlet/></>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Dettagli'/>
            },
            children: [{
                path: 'edit',
                element: <PermissionChecker permissions={['users:edit']} navigateTo={'/404'}> <EditUser/></PermissionChecker>,
                handle: {
                    crumb: () => <PageBreadcrumbLink label='Modifica'/>
                }
            }, {
                path: '',
                element: <UserShow/>,
            }]
        }, {
            path: 'create',
            element: <PermissionChecker permissions={['users:edit']} navigateTo={'/404'}><NewUser/></PermissionChecker>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Crea'/>
            }
        }, {
            path: '',
            element: <><Users/></>,
        }]
    },
];