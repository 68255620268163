import TableCardHeader from "../../../../components/TableCardHeader";
import React, {useState} from "react";
import ExpenseEntryTableItem from "./ExpenseEntryTableItem";
import ExpenseEntrySlideOver from "./ExpenseEntrySlideOver";
import DeleteModal from "../../../../components/UI/DeleteModal";

export const ExpenseEntriesTable = ({entries, expenseUuid, onCreate, onUpdate, onDelete})=>{
    const [showSlideOver, setShowSlideOver] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [selectedEntry, setSelectedEntry] = useState();

    return(
        <>
            <TableCardHeader title={'Spese'} action={{'action': ()=>(setSelectedEntry() & setShowSlideOver(true))}}/>
            <ul className="flex flex-col divide-y divide-gray-100 mt-4">
                {entries?.map(entry =>
                    <ExpenseEntryTableItem key={entry?.id}
                                           entry={entry}
                                           onClick={()=>setSelectedEntry(entry) & setShowModal(true)}
                    />
                )}
            </ul>
            <ExpenseEntrySlideOver
                entry={selectedEntry}
                expenseUuid={expenseUuid}
                state={showSlideOver}
                onCreate={onCreate}
                onClose={()=> setShowSlideOver(false)}/>

            <DeleteModal title={'questa spesa'}
                         onClose={() => setShowModal(false)}
                         onDelete={() => onDelete(expenseUuid, selectedEntry)}
                         state={showModal}/>
        </>
    )
}

export default ExpenseEntriesTable;