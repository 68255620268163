import {useRequest} from "ahooks";
import quoteServices from "../../services/QuoteService";
import {useParams} from "react-router-dom";
import React from "react";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import QuoteShowInfo from "./components/QuoteShowInfo";
import UserInfo from "./components/UserInfo";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import SectionContent from "../../components/Theme/PageSections/SectionContent";
import {Revision} from "./Revision/Revision";
import Card from "../../components/UI/Cards/Card";

export const QuoteShow = () => {
    const {uuid} = useParams();

    const {data: quote = [], loading} = useRequest(quoteServices.quoteShow, {
        defaultParams: [uuid]
    })

    return (
        <>
            <LoadingSpinner loading={loading}>
                <SectionHeader title={'Preventivo'}
                               subtitle={`Preventivo N: ${quote?.number}`}/>
                <SectionContent
                    left={
                        <>
                            <div className='flex flex-col gap-x-4 gap-y-4'>
                                <QuoteShowInfo data={quote}/>
                                <div
                                    className="flex relative max-h-20 overflow-hidden rounded-lg bg-white px-4 pb-6 shadow sm:px-6 sm:pt-6 py-6">
                                    <UserInfo user={quote?.user}/>
                                </div>
                            </div>
                        </>}
                    right={
                        <>
                            <Card>
                                <Revision revision={quote?.current_revision}/>
                            </Card>

                        </>
                    }


                />
            </LoadingSpinner>
        </>
    )
}

export default QuoteShow