import CardHeader from "../../CardHeader";
import React from "react";

export const InfoCard = ({
                             title,
                             onDelete,
                             canDelete = true,
                             children,
                             icon = undefined,
                             permissions
                         }) => {
    return (
        <>
            <div className='relative overflow-hidden rounded-lg bg-white px-4 pb-6 shadow sm:px-4 py-6'>
                <CardHeader title={title} onDelete={onDelete} canDelete={canDelete} icon={icon}
                            permissions={permissions}/>
                <div className='mt-6'>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default InfoCard