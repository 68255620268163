import LoadingSpinner from "../../components/UI/LoadingSpinner";
import React from "react";
import {useRequest} from "ahooks";
import serviceService from "../../services/ServiceService";
import {useNavigate, useParams} from "react-router-dom";
import ServiceShowInfo from "./components/ServiceShowInfo";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../components/FlashMessages/useFlashMessage";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";

export const ServiceShow = () => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();
    const navigation = useNavigate();
    const {data: service = undefined, loading, mutate} = useRequest(serviceService.serviceShow, {
        defaultParams: [uuid],
    })

    const handleStateUpdate = state => {
        mutate({
            ...service,
            states: {
                available_states: state.available_states,
                state: state.state
            }
        })
    }

    const deleteHandler = () => {
        serviceService.deleteService(uuid).then(res => {
                flashMsg(res.message, null, uuidv4(), true)
                navigation(-1)
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
    }

    return (
        <>
            <LoadingSpinner loading={loading}>
                <SectionHeader title={'Dettagli Servizio'}/>
                <ServiceShowInfo data={service} onStateUpdate={handleStateUpdate} onDelete={deleteHandler}/>
            </LoadingSpinner>
        </>
    )
}