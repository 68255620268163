import api from '../configs/api.config';

const productsUrl = '/products';
const umUrl = '/options/um-types'

const products = params => api.get(productsUrl, params).then(res => res.data);

const productsOption = params =>api.get('/options/products', params).then(res=>res.data);
const productShow = (id, params) => api.get(productsUrl + '/' + id, params).then(res => res.data)
const createProduct = params => api.post(productsUrl, params).then(res => res.data);
const updateProduct = (uuid, params) => api.put(`${productsUrl}/${uuid}`, params).then(res => res.data);
const deleteProduct = (id, params) => api.delete(`${productsUrl}/${id}`, params).then(res => res.data);
const umTypes = params => api.get(umUrl, params).then(res => res.data);

const productAreas = params => api.get ('/options/area-types').then(res=> res.data);


const productServices = {
    products,
    productShow,
    umTypes,
    deleteProduct,
    createProduct,
    updateProduct,
    productsOption,
    productAreas
}

export default productServices;