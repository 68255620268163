import {CircleStackIcon, EnvelopeIcon, ServerStackIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import EuroFormat from "../../../../components/UI/EuroFormat";
import DateFormat from "../../../../components/UI/DateFormat";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    active: 'text-gray-600 bg-green-200 ring-gray-500/10',
    suspended: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    terminated: 'text-indigo-600 bg-white ring-indigo-600/20',
    expired: 'text-red-600 bg-red-50 ring-red-600/20',
    draft: 'text-dark bg-gray-300 ring-black/20',
}

const typeIcon = {
    hosting: <ServerStackIcon className='h-5 w-5'/>,
    newsletter: <EnvelopeIcon className='h-5 w-5'/>,
    storage: <CircleStackIcon className='h-5 w-5'/>
}


const CompanyServiceTableItem = ({service}) => {
    return (
        <>
            <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                    {typeIcon[service.type]}
                    <p className="text-sm font-semibold leading-6 text-gray-900">{service.type.label}</p>
                    <p
                        className={classNames(
                            statuses[service.state.value],
                            'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                        )}
                    >
                        {service.state.label.toUpperCase()}
                    </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p className="whitespace-nowrap">
                        <time dateTime={service.start_at}><DateFormat value={service?.start_at}/> </time>
                    </p>
                    {service.is_auto_renew ?
                        <>
                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                <circle cx={1} cy={1} r={1}/>
                            </svg>
                            <p className="truncate">Auto Renew</p>
                        </>
                        :
                        <></>

                    }
                </div>
            </div>

            <div className="flex flex-none items-center gap-x-6">
                <div className="items-center text-sm">
                    <EuroFormat value={service?.amount}/>
                </div>
                {/*<button
                    //href=''
                    onClick={event => setShowSlideOver(true)}
                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-green-900 sm:block"
                >
                    Dettagli<span className="sr-only">, {service.uuid}</span>
                </button>*/}
                <div className="ml-4 flex-shrink-0">
                    <Link to={`/services/${service.uuid}`} className='font-medium text-sm text-green-600 hover:text-green-900'>
                        Apri
                    </Link>
                </div>
            </div>
        </>
    )
}

export default CompanyServiceTableItem