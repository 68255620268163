import React from 'react';
import { Link } from 'react-router-dom';

const TableLink = props => {

    const { value, to, children } = props;

    return (
        <Link to={to} className={`fw-bold ${props?.className} hover:text-green-600 hover:underline`}>
            {value}
            {children ? children : null}
        </Link>
    );
}

export default TableLink;