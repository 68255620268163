import {useNavigate, useParams} from "react-router-dom";
import useFlashMessage from "../../components/FlashMessages/useFlashMessage";
import {useRequest} from "ahooks";
import roleServices from "../../services/RoleService";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import RoleForm from "./components/RoleForm";
import {v4 as uuidv4} from "uuid";
import ActionButtonsSlideOver from "../../components/UI/Buttons/ActionButtonsSlideOver";
import {useState} from "react";
import DeleteModal from "../../components/UI/DeleteModal";
import PermissionChecker from "../../components/Theme/PermissionChecker";

export const RoleShow = () => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();
    const navigation = useNavigate();
    const [showModal, setShowModal] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const {data = undefined, loading} = useRequest(roleServices.roleShow, {
        defaultParams: [uuid],
    })

    const deleteRoleHandler = () => {
        let myuuid = uuidv4();
        roleServices.deleteRole(uuid).then(res => {
                flashMsg('Role successfully deleted', null, myuuid, true)
                navigation(-1)
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
            });
    }

    return (
        <>
            <LoadingSpinner loading={loading}>
                <PermissionChecker permissions={['roles:edit']}>
                    <ActionButtonsSlideOver editAction={e => setIsDisabled(false)} deleteAction={e => setShowModal(true)}/>
                </PermissionChecker>
                <RoleForm role={data} isDisabled={isDisabled}/>
            </LoadingSpinner>
            <DeleteModal title={data?.name} onDelete={deleteRoleHandler} onClose={e => setShowModal(false)} state={showModal}/>
        </>
    )
}