function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const statuses = {
    active: 'text-gray-600 bg-green-200 ring-gray-500/10',
    suspended: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    terminated: 'text-indigo-600 bg-white ring-indigo-600/50',
    overflow: 'text-red-600 bg-red-50 ring-red-600/20',
    draft: 'text-dark bg-gray-300 ring-black/20',
}

/*const pack =

    {
        id: 3,
        uuid: "c3f073cd-d5e3-49c1-9b64-d117b92e1761",
        amount: 414,
        price_each: 33.03,
        start_at: "2023-10-10T07:26:09.000000Z",
        expiration_date: "2024-12-10T07:26:09.000000Z",
        state: {
            label: "Attivo",
            value: "active",
            color: "bg-emerald-500 text-neutral-50"
        },
        price_tota: 13674.42,
        expiration_days_left: 427,
        remaining_amount: 315,
        used_amount: 99,
        note: "Sunt sapiente sapiente ullam voluptatem quia.",
        company: {
            id: 1,
            uuid: "38ca397a-6b00-4c0f-9fae-e64af3a9adf8",
            business_name: "Price Group",
            sdi: "ZTSSLK",
            vat_number: "EWMMNTZSDGDJY",
            fiscal_code: "CNMDZOMZWRBBBXEU"
        },
        project: {
            id: 3,
            uuid: "c3f073cd-d5e3-49c1-9b64-d117b92e1761",
            name: "Nasir Boyer",
            company: {
                id: 1,
                uuid: "38ca397a-6b00-4c0f-9fae-e64af3a9adf8",
                business_name: "Price Group",
                sdi: "ZTSSLK",
                vat_number: "EWMMNTZSDGDJY",
                fiscal_code: "CNMDZOMZWRBBBXEU"
            }
        }
    }*/


export const PackageTableStatus = ({pack}) => {
    return (
        <div className="min-w-0">
            <div className=" flex items-center gap-x-3">
                <p
                    className={classNames(
                        statuses[pack?.state.value],
                        'rounded-md whitespace-nowrap  px-1.5 py-1 text-xs font-normal ring-1 ring-inset'
                    )}
                >
                    {pack?.state.label.toUpperCase()}
                </p>
            </div>
        </div>
    )
}

export default PackageTableStatus;