import {useNavigate, useParams} from "react-router-dom";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {useEffect, useState} from "react";
import {useRequest} from "ahooks";
import CustomerService from "../../../services/CustomerService";
import projectService from "../../../services/ProjectService";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {FormGroup} from "../../../components/UI/Forms/FormGroup";
import {Select} from "../../../components/UI/Forms/Select";
import {FormInput} from "../../../components/UI/Fields/FormInput";
import _ from "lodash";
import CancelButton from "../../../components/UI/Buttons/CancelButton";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import quoteServices from "../../../services/QuoteService";

export const QuoteForm = ({quote}) => {
    const {uuid} = useParams();
    const navigation = useNavigate();
    const {flashMsg} = useFlashMessage();

    const [extraParams, setExtraParams] = useState({
        filter: {
            company_id: quote?.company?.id ?? '',
        },
    });

    const {data: companies = []} = useRequest(CustomerService.companiesOptions);
    const {data: projects = [], run} = useRequest(projectService.projectOptions, {
        manual: true,
    });

    const createQuote = (data, setSubmitting) => {
        quoteServices.createQuote(data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Preventivo Creato', undefined, myuuid, true)
                navigation(`/quotes/${res.uuid}`);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    const updateQuote = (data, setSubmitting) => {
        quoteServices.updateQuote(uuid, data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Preventivo Aggiornato', undefined, myuuid, true)
                navigation(-1);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    const handleSubmit = (values, {setSubmitting}) => {
        const data = {
            company_id: values.company_id,
            project_id: values.project_id,
            quote_date: values.quote_date,
            subject: values.subject,
            note: values.note
        }

        quote ? updateQuote(data, setSubmitting) : createQuote(data, setSubmitting)
    }

    const initialValues = {
        company_id: quote?.company?.id ?? '',
        project_id: quote?.project?.id ?? '',
        quote_date: quote?.quote_date?.substring(0, 10) ?? '',
        subject: quote?.subject ?? '',
        note: quote?.note ?? '',
    }

    const validationSchema = Yup.object().shape({
        company_id: Yup.string().nullable().required(),
        project_id: Yup.string().nullable().required(),
        quote_date: Yup.string().nullable().required(),
        subject: Yup.string().nullable().required(),
        note: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    useEffect(() => {
            formik.values.company_id !== '' ?
                setExtraParams(prevExtraParams => ({
                    ...prevExtraParams,
                    filter: {
                        ...prevExtraParams.filter,
                        company_id: formik.values.company_id
                    }
                }))

                :
                <></>
    }, [formik.values.company_id]);

    useEffect(() => {
            extraParams.filter.company_id !== '' ?
                run(extraParams)
                :
                <></>;
    }, [extraParams, run]);


    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <div className="space-y-12">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div className='sm:ms-4'>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">{quote ? "Modifica Preventivo" : 'Inserisci Nuovo Preventivo'}</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">{quote ? 'Modifica' : 'Inserisci'} le
                                    informazioni del preventivo</p>
                            </div>

                            <div
                                className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                <div className="sm:col-span-3">
                                    <FormGroup required={true} label='Azienda' name='company_id'>
                                        <div className="mt-2">
                                            <Field as={Select} value={formik.values.company_id} name="company_id">
                                                {companies ?
                                                    <>
                                                        <option value='' className='text-center'>--SELEZIONA
                                                            AZIENDA---
                                                        </option>
                                                        {companies.map(item => (
                                                            <option key={item.value}
                                                                    value={item.value}
                                                            >{item.label}</option>
                                                        ))}
                                                    </>

                                                    : <option>Loading</option>
                                                }
                                            </Field>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup label='Progetto' name='project_id'>
                                        <div className="mt-2">
                                            <Field as={Select} value={formik.values.project_id} name="project_id">
                                                {formik.values.company_id ? (
                                                    <>
                                                        <option value='' className='text-center'>--SELEZIONA
                                                            PROGETTO---
                                                        </option>
                                                        {projects ? (
                                                            projects?.map(item => (
                                                                <option key={item.value}
                                                                        value={item.value}>{item.label}</option>
                                                            ))
                                                        ) : (
                                                            <option>Loading</option>
                                                        )}
                                                    </>
                                                ) : (
                                                    <option className='text-center' value=''
                                                            disabled={true}>--SELEZIONA PRIMA L'AZIENDA---</option>
                                                )}

                                            </Field>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required label='Soggetto' name='subject'>
                                        <FormInput reuired label='subject' name='subject'
                                                   value={formik.values.subject}/>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required={true} label='Data' name='quote_date'>
                                        <div className="mt-2">
                                            <Field as={FormInput} required={true} label='quote_date'
                                                   type='date'
                                                   name='quote_date' value={formik.values.quote_date}/>
                                        </div>
                                    </FormGroup>
                                </div>


                                <div className="col-span-full">
                                    <label htmlFor="note"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Note
                                    </label>
                                    <FormInput
                                        name='note'
                                        type='textarea'
                                        autoComplete=''
                                        value={formik.values.note}
                                        onChange={event => formik.setFieldValue('note', event.target.value)}
                                        error={_.get(formik.errors, 'note')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <CancelButton/>
                        <SubmitButton label={quote ? 'Salva' : 'Crea'}/>
                    </div>
                </Form>
            </FormikProvider>
        </>
    )
}

export default QuoteForm