import React from 'react';

export const MainNavbarMenuItem = ({ item }) => {

  return (
    <a
      key={item.name}
      href={item.href}
      className={`rounded-md px-3 py-2 text-sm font-medium ${item.current
        ? 'bg-gray-900 text-white'
        : 'text-gray-300 hover:bg-gray-700 hover:text-white'}`}
      aria-current={item.current ? 'page' : undefined}
    >
      {item.name}
    </a>
  )

}

