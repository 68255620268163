import EuroFormat from "../../../../components/UI/EuroFormat";
import React, {useState} from "react";
import EditBillSlideOver from "../EditBillSlideOver";
import {useSanctum} from "react-sanctum";

export const BillBadge = ({
                              color = 'blue',
                              value = 0,
                              clickable = false,
                              bill = undefined,
                              offer,
                              onUpdate,
                              onDelete
                          }) => {
    const {user} = useSanctum();
    const [showSlideOver, setShowSlideOver] = useState(false)

    const getDaFatturare = (offer) => {
        let somma = 0;

        offer?.bills?.forEach(bill => {
            somma += bill.amount
        });

        return Math.abs(offer?.accepted_price - somma);
    };

    if (user.permissions.some(permission => ['offers:edit']?.includes(permission))) {
        return <>{
            clickable ?
                <button
                    className={`inline-flex items-center rounded-full bg-${color}-100 px-2 py-1 text-xs font-medium text-${color}-700`}
                    onClick={() => setShowSlideOver(true)}
                >
                    <EuroFormat value={value}/>
                </button>
                :
                <span
                    className={`inline-flex items-center rounded-full bg-${color}-100 px-2 py-1 text-xs font-medium text-${color}-700`}
                >
                        <EuroFormat value={value}/>
                    </span>

        }
            <EditBillSlideOver state={showSlideOver} onClose={() => setShowSlideOver(false)}
                               bill={bill} offerUuid={offer?.uuid} onUpdate={onUpdate}
                               onDelete={onDelete} remainingAmount={getDaFatturare(offer)}/>
        </>;
    } else {
        return (
            <span
                className={`inline-flex items-center rounded-full bg-${color}-100 px-2 py-1 text-xs font-medium text-${color}-700`}
            >
                        <EuroFormat value={value}/>
                </span>
        )
    }
}

export default BillBadge;