import {Outlet} from "react-router-dom";

export const CompanyWrapper = () => {
    return (
        <>
            <Outlet/>
        </>
    )
}

export default CompanyWrapper