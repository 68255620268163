import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

export const MainNavbarProfileMenu = ({ user, userNavigation }) => {

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button
          className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
          <span className="absolute -inset-1.5"/>
          <span className="sr-only">{user.full_name}</span>
          <img className="h-8 w-8 rounded-full" src={user.avatar} alt=""/>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <button
                  onClick={item.action}
                  className={`block px-4 py-2 text-left text-sm text-gray-700 ${active ? 'bg-gray-100' : ''} w-full`}
                >
                        {item.name}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )

}
