import React from 'react';
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";

export const Label = ({label, name, required = false, error}) => {

    return (
            <label htmlFor={name} className="flex items-center justify-between text-sm font-medium leading-6 text-gray-900">
                {label} {required ? '*' : ''}
                {error ?
                    <div className='flex items-center justify-between'>
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                    </div>


                    : <></>
                }
            </label>
    )

}
