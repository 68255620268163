import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import projectService from "../../../../services/ProjectService";
import CompanyProjectTableItem from "./CompanyProjectTableItem";
import LoadingSpinner from "../../../../components/UI/LoadingSpinner";
import NewElement from "../../../../components/UI/Buttons/NewElement";
import TableCardHeader from "../../../../components/TableCardHeader";
import PermissionChecker from "../../../../components/Theme/PermissionChecker";

export const ProjectsTable = ({projects_count, company_id}) => {
    const {uuid} = useParams();
    const navigation = useNavigate();
    const [extraParams] = useState({
        filter: {
            company_uuid: uuid,
        },
    });

    const {data: projects = [], loading, run} = useRequest(projectService.projects, {
        manual: true,
        //defaultParams: extraParams.filter,
    });

    useEffect(() => {
        run(extraParams)
    }, [extraParams, run]);



    return (
        <>
            {projects_count > 0 ? (
                    <>
                        <TableCardHeader title={'Progetti'} action={{
                            'action': () => navigation('/projects/create', {state: {company_id: company_id}}),
                            'permissions': ['projects:edit']
                        }}/>
                        <PermissionChecker permissions={['projects:view']}
                                           message={'NON HAI IL PERMESSO DI VISUALIZZARE I PROGETTI'}>
                            <LoadingSpinner loading={loading}>
                                <ul className="divide-y divide-gray-100">
                                    {projects?.data?.map((project) => (
                                            <CompanyProjectTableItem name={project.name} uuid={project.uuid} key={project.uuid}/>
                                    ))}
                                </ul>
                            </LoadingSpinner>
                        </PermissionChecker>
                    </>
                )
                :
                <PermissionChecker permissions={['projects:edit']} message={'NON HAI IL PERMESSO DI AGGIUNGERE NUOVI PROGETTI'}>
                    <NewElement type={'project'}
                                onClick={() => navigation('/projects/create', {state: {company_id: company_id}})}/>
                </PermissionChecker>
            }
        </>
    )
}

export default ProjectsTable;