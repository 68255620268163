import {Field, Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import {SlideOverFormGroup} from "../../../components/UI/Forms/SlideOverFormGroup";
import SlideOverFormInput from "./SlideOverFormInput";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import activityServices from "../../../services/ActivityService";
import {useParams} from "react-router-dom";
import {Select} from "../../../components/UI/Forms/Select";
import {useRequest} from "ahooks";
import projectService from "../../../services/ProjectService";
import {useEffect, useState} from "react";

const validationSchema = Yup.object().shape({
    amount: Yup.number().nullable().required(),
    date: Yup.string().nullable().required(),
    note: Yup.string().nullable(),
})

export const ActivityForm = ({isDisabled = false, company_id, package_id, project_id, activity, onCreate, onUpdate}) => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();

    const {data: projects = [], run} = useRequest(projectService.projectOptions, {
        manual: true,
    });

    const [extraParams, setExtraParams] = useState({
        filter: {
            company_id: company_id ?? '',
        },
    });


    const insertActivity = (data, setSubmitting) => {
        activityServices.createActivity(uuid, data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Attività Inserita', undefined, myuuid, true)
                onCreate(res);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    const updateActivity = (data, setSubmitting) => {
        activityServices.updateActivity(uuid, activity.uuid, data)
            .then(res => {
                setSubmitting(false);
                onUpdate(res);
                flashMsg('Attività Modificata', undefined, uuidv4(), true)
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const initialValues = {
        date: activity?.date?.substring(0, 10) ?? '',
        amount: activity?.amount ?? '',
        note: activity?.note ?? '',
    }

    const handleSubmit = (values, {setSubmitting}) => {
        const data = {
            package_id: package_id,
            project_id: project_id ?? values.project_id,
            date: values.date,
            amount: values.amount,
            note: values.note
        }
        activity ? updateActivity(data, setSubmitting) : insertActivity(data, setSubmitting)
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    useEffect(() => {
        {
            company_id !== '' ?
                setExtraParams(prevExtraParams => ({
                    ...prevExtraParams,
                    filter: {
                        ...prevExtraParams.filter,
                        company_id: company_id
                    }
                }))
                :
                <></>
        }
    }, [company_id]);

    useEffect(() => {
        {
            extraParams.filter.company_id !== '' ?
                run(extraParams)
                :
                <></>;
        }
    }, [extraParams]);

    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <div className="sm:col-span-3">
                        <SlideOverFormGroup required label='Data' name='date'>
                            <Field as={SlideOverFormInput} required={true} label='date' type='date'
                                   name='date' value={formik.values.date} isDisabled={isDisabled}
                            />
                        </SlideOverFormGroup>
                        <SlideOverFormGroup required label='Ore' name='amount'>
                            <Field as={SlideOverFormInput} required label='amount' type='number'
                                   name='amount' value={formik.values.amount} isDisabled={isDisabled}
                            />
                        </SlideOverFormGroup>
                        {!activity && !project_id ?
                            <SlideOverFormGroup required={true} label='Progetto' name='project_id'>
                                <Field as={Select} value={formik.values.project_id} name="project_id">
                                    <>
                                        <option className='text-center' value=''>--SELEZIONA PROGETTO---</option>
                                        {projects?.map(item => (
                                            <option key={item.value}
                                                    value={item.value}>{item.label}</option>
                                        ))}
                                    </>
                                </Field>
                            </SlideOverFormGroup>
                            :
                            <>
                            </>
                        }
                        <SlideOverFormGroup label='Note' name='note'>
                            <Field as={SlideOverFormInput} label='note' type='textarea'
                                   name='note' value={formik.values.note}
                                   onChange={event => formik.setFieldValue('note', event.target.value)}
                                   isDisabled={isDisabled}
                            />
                        </SlideOverFormGroup>
                    </div>
                    {!isDisabled ? <div className="mt-6 flex items-center justify-end gap-x-6">
                        <SubmitButton label={activity ? 'Salva' : 'Inserisci'}/>
                    </div> : <></>}
                </Form>
            </FormikProvider>
        </>
    )
}

export default ActivityForm