import InfoCardField from "../../../../components/UI/Fields/InfoCardField";
import InfoCardGroup from "../../../../components/UI/Fields/InfoCardGroup";

export const DatabaseInfo = ({database}) => {
    return (
        <InfoCardGroup>
            <InfoCardField title={'Nome'} value={database?.name}/>
            <InfoCardField title={'Indirizzo'} value={database?.address} canCopy/>
            <InfoCardField title={'Porta'} value={database?.port} canCopy/>
            <InfoCardField title={'Tipo'} value={database?.type?.label}/>
            {database?.note ? <InfoCardField title={'Note'} value={database?.note}/> : <></>}
        </InfoCardGroup>
    )
}

export default DatabaseInfo