import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import {useRequest} from "ahooks";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import serverServices from "../../../../services/ServerService";
import {FormGroup} from "../../../../components/UI/Forms/FormGroup";
import {FormInput} from "../../../../components/UI/Fields/FormInput";
import {Select} from "../../../../components/UI/Forms/Select";
import CancelButton from "../../../../components/UI/Buttons/CancelButton";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";

const validationSchema = Yup.object().shape({
    site_id: Yup.string().nullable(),
    name: Yup.string().nullable().required(),
    address: Yup.string().matches(
        /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        'Please insert a valid ip address'
    ).nullable().required(),
    port: Yup.number().nullable().required(),
    type: Yup.string().nullable().required(),
    note: Yup.string().nullable(),
})
export const DatabaseForm = ({database, siteId, serverUuid, onCancel, onCreate, onUpdate}) => {
    const {flashMsg} = useFlashMessage();

    const {data: types = []} = useRequest(serverServices.databaseTypeOptions);

    const initialValues = {
        name: database?.name ?? '',
        address: database?.address ?? '',
        port: database?.port ?? '',
        type: database?.type?.value ?? 'my_sql',
        note: database?.note ?? '',
    }

    const createDatabase = (data, setSubmitting) => {
        serverServices.createDatabase(serverUuid, data)
            .then(res => {
                flashMsg('Database Creato', undefined, uuidv4(), true)
                onCreate(res, serverUuid, siteId);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const updateDatabase = (data, setSubmitting) => {
        serverServices.updateDatabase(serverUuid, database?.uuid, data)
            .then(res => {
                flashMsg('Database Modificato', undefined, uuidv4(), true)
                onUpdate(res, serverUuid, siteId);
                onCancel();
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            site_id: siteId ?? null,
            name: values.name,
            address: values.address,
            port: values.port,
            type: values.type,
            note: values.note
        }

        database ? updateDatabase(data, setSubmitting) : createDatabase(data, setSubmitting)
    }


    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <FormikProvider value={formik}>
            <Form>
                <div className="space-y-8">
                    <div
                        className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 pb-4">

                        <div
                            className="grid grid-cols-1 gap-x-2 gap-y-3 sm:grid-cols-6">

                            <div className="col-span-full">
                                <FormGroup required label='Nome' name='name'>
                                    <div className="mt-2">
                                        <Field as={FormInput} required label='name'
                                               name='name' value={formik.values.name}/>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-span-full">
                                <FormGroup required label='Indirizzo' name='address'>
                                    <div className="mt-2">
                                        <Field as={FormInput} required label='address'
                                               name='address' value={formik.values.address}/>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-span-full">
                                <FormGroup required label='Porta' name='port'>
                                    <div className="mt-2">
                                        <Field as={FormInput} required type='number' label='port'
                                               name='port' value={formik.values.port}/>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="sm:col-span-full">
                                <FormGroup required={true} label='Tipo' name='type'>
                                    <div className="mt-2">
                                        <Field as={Select} value={formik.values.type} name="type">
                                            {types ?
                                                <>
                                                    <option value='' className='text-center'>--SELEZIONA
                                                        TIPO---
                                                    </option>
                                                    {types.map(item => (
                                                        <option key={item.value}
                                                                value={item.value}
                                                        >{item.label}</option>
                                                    ))}
                                                </>

                                                : <option>Loading</option>
                                            }
                                        </Field>
                                    </div>
                                </FormGroup>
                            </div>


                            <div className="col-span-full">
                                <FormGroup label='Note' name='note'>
                                    <div className="mt-2">
                                        <Field as={FormInput} type='textarea' label='note'
                                               name='note' value={formik.values.note}/>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flex items-center justify-end gap-x-6">
                    {onCancel ? <CancelButton action={onCancel}/> : <></>}
                    <SubmitButton label={database ? 'Salva' : 'Crea'}/>
                </div>
            </Form>
        </FormikProvider>
    )
}

export default DatabaseForm;