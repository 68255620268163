import PackageForm from "./components/PackageForm";
import {useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import packageServices from "../../services/PackageService";

export const EditPackage = () => {
    const {uuid} = useParams();
    const {data = [], loading} = useRequest(packageServices.packageShow, {
        defaultParams: [`${uuid}`]
    })
    return (
            <LoadingSpinner loading={loading}>
                <PackageForm pack={data}/>
            </LoadingSpinner>
    )
}

export default EditPackage