import React from "react";
import InfoPopOver from "../../../../components/UI/InfoPopOver";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";

export const LicenseTableItem = ({license, onClick}) => {

    return (
        <li key={license.id}
            className="flex flex-row justify-between gap-x-6 py-3 w-full">


            <div className="flex items-center min-w-0 gap-x-4 basis-2/3">
                <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">{license?.name}</p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">{license?.key}</p>
                </div>
            </div>
            <div className='flex items-center'>
                {license?.note ? <InfoPopOver>
                    {license?.note}
                </InfoPopOver> : <></>}
            </div>
            <div className="flex items-center pb-2">
                <button
                    onClick={onClick}
                    className="rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                    <ArrowTopRightOnSquareIcon className="h-5 w-5" aria-hidden="true"/>
                </button>

            </div>
        </li>
    )
}

export default LicenseTableItem