import React from 'react';
import { Disclosure } from '@headlessui/react';

export const MainNavabrMobileProfileMenu = ({ user, userNavigation }) => {

  return (
    <div className="border-t border-gray-700 pb-3 pt-4">
      <div className="flex items-center px-5">
        <div className="flex-shrink-0">
          <img className="h-10 w-10 rounded-full" src={user.avatar} alt=""/>
        </div>
        <div className="ml-3">
          <div className="text-base font-medium text-white">{user.full_name}</div>
          <div className="text-sm font-medium text-gray-400">{user.email}</div>
        </div>
      </div>
      <div className="mt-3 space-y-1 px-2">
        {userNavigation.map((item) => (
          <Disclosure.Button
            key={item.name}
            as="span"
            onClick={() => console.log('clicked')}
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
          >
            {item.name}
          </Disclosure.Button>
        ))}
      </div>
    </div>
  )

}
