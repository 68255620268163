import {FolderIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";

const CompanyProjectTableItem = ({name, uuid}) => {
    return (
        <>
            <li key={uuid}
                className="flex items-center justify-between py-4 text-sm leading-6">
                <div className="flex w-0 flex-1 items-center">
                    <FolderIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                        <span className="truncate font-medium">{name}</span>
                    </div>
                </div>
                <div className="ml-4 flex-shrink-0">
                    <Link to={`/projects/${uuid}`} className='font-medium text-green-600 hover:text-green-900'>
                        Apri
                    </Link>
                </div>
            </li>
        </>
    )
}

export default CompanyProjectTableItem;