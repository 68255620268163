import * as Yup from "yup";
import {Form, FormikProvider, useFormik} from "formik";
import authService from "../../../../services/AuthService";
import {FormGroup} from "../../../../components/UI/Forms/FormGroup";
import {FormInput} from "../../../../components/UI/Fields/FormInput";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from 'uuid';


export default function ResetPasswordForm() {
    const {flashMsg} = useFlashMessage();

    const updatePassword = (data, setSubmitting) => {
        authService.updatePassword(data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Password Modificata Con Successo', null, myuuid, true);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const handleSubmit = (values, {setSubmitting}) => {
        const data = {
            current_password: values.current_password,
            password: values.password,
            password_confirmation: values.password_confirmation,
        }
        updatePassword(data, setSubmitting)
    }


    const validationSchema = Yup.object().shape({
        current_password: Yup.string().nullable().required(),
        password: Yup.string()
            .required()
            .min(8, "Pasword must be 8 or more characters"),
        //.matches(/(?=.*[a-z])(?=.*[A-Z])\w+/, "Password ahould contain at least one uppercase and lowercase character")
        //.matches(/\d/, "Password should contain at least one number")
        //.matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password should contain at least one special character"),
        password_confirmation: Yup.string().when("password", (password, field) => {
            if (password) {
                return field.required("The passwords do not match").oneOf([Yup.ref("password")], "The passwords do not match");
            }
        }),
    })

    const initialValues = {
        current_password: '',
        password: '',
        password_confirmation: '',
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });


    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <div className="space-y-12">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-6">
                            <div
                                className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 lg:grid-cols-2">

                                <div className="col-span-full lg:col-span-1">
                                    <FormGroup required label='Password' name='password'>
                                        <FormInput label='password' name='password'
                                                   type='password'
                                                   value={formik.values.password}/>
                                    </FormGroup>
                                </div>
                                <div className="col-span-full lg:col-span-1">
                                    <FormGroup required label='Conferma Password' name='password_confirmation'>
                                        <FormInput label='password_confirmation' name='password_confirmation'
                                                   type='password'
                                                   value={formik.values.password_confirmation}/>
                                    </FormGroup>
                                </div>
                                <div className="col-span-full lg:col-span-2">
                                    <FormGroup required label='Password Attuale' name='current_password'>
                                        <FormInput label='current_password' name='current_password'
                                                   type='password'
                                                   value={formik.values.current_password}/>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3 flex items-center justify-end gap-x-6">
                        <SubmitButton label={'Aggiorna'}/>
                    </div>
                </Form>
            </FormikProvider>
        </>
    )
}