import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {FormGroup} from '../../../components/UI/Forms/FormGroup';
import {SubmitButton} from '../../../components/UI/Forms/SubmitButton';
import CancelButton from "../../../components/UI/Buttons/CancelButton";
import {useNavigate, useParams} from "react-router-dom";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from 'uuid';
import {FormInput} from "../../../components/UI/Fields/FormInput";
import roleServices from "../../../services/RoleService";
import {useRequest} from "ahooks";
import {useEffect, useState} from "react";
import SelectAllButton from "./SelectAllButton";


export default function RoleForm({role = undefined, isDisabled}) {
    const {flashMsg} = useFlashMessage();
    const {uuid} = useParams();
    const navigation = useNavigate();
    const {data: permissions = []} = useRequest(roleServices.permissionOptions);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        role?.permissions.length === permissions.length ? setSelectAll(true) : <></>
    }, [role, permissions]);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        formik.setFieldValue('permissions', selectAll ? [] : permissions.map(p => p.value));
    }

    const createRole = (data, setSubmitting) => {
        roleServices.createRole(data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Ruolo Creato', null, myuuid, true);
                navigation(-1);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const updateRole = (data, setSubmitting) => {
        roleServices.updateRole(uuid, data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Ruolo Modificato Con Successo', null, myuuid, true);
                navigation(-1);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const initialValues = {
        name: role?.name ?? '',
        permissions: role?.permissions.map(p => p?.value) ?? []
    }


    const handleSubmit = (values, {setSubmitting}) => {
        const data = {
            name: values.name,
            permissions: values.permissions,
        }
        role ? updateRole(data, setSubmitting) : createRole(data, setSubmitting)
    }


    const validationSchema = Yup.object().shape({
        name: Yup.string().nullable().required(),
        permissions: Yup.array().required()
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <>
            {/*<div className='mx-auto items-center justify-center max-w-7xl px-4'>*/}

            <FormikProvider value={formik}>
                <Form>
                    <div className="space-y-12">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div className='flex flex-col ms-4'>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">{role ? "Modifica Ruolo" : 'Inserisci Nuovo Ruolo'}</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">{role ? 'Modifica' : 'Inserisci'} le
                                    informazioni del ruolo</p>
                                <div className="flex items-center justify-center mt-6">
                                    <img
                                        className="h-25 w-25 rounded-full"
                                        src={role?.avatar}
                                        alt=""
                                    />
                                </div>

                            </div>

                            <div
                                className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                <div className="sm:col-span-full">
                                    <FormGroup required label='Nome' name='name'>
                                        <FormInput isDisbaled={isDisabled} reuired label='name' name='name'
                                                   value={formik.values.name}/>
                                    </FormGroup>
                                </div>


                                <div className="sm:col-span-full">

                                    <FormGroup required label='Permessi' name='permissions' className=''>
                                        <div className='flex items-center flex-row gap-x-3 my-3'>
                                            <SelectAllButton selectAll={selectAll} onChange={handleSelectAll} isDisabled={isDisabled}/>
                                        </div>
                                        <div className=' grid grid-cols-1 sm:grid-cols-2'>
                                            {permissions?.map(permission => (
                                                <div key={permission?.value} className='flex items-center flex-row gap-x-3 my-1'>
                                                    <Field
                                                        key={permission?.value}
                                                        type='checkbox'
                                                        name='permissions'
                                                        disabled={isDisabled}
                                                        //checked={initialValues?.permissions?.filter(p => (p.name == permission.value)).length > 0 ? true : false}
                                                        //onChange={e => (formik.setFieldValue('permissions', e.target.value, e.target.label))}
                                                        value={permission?.value}
                                                        className='h-4 w-4 accent-green-600'
                                                    />
                                                    <p>{permission?.label}</p>
                                                </div>

                                            ))}
                                        </div>
                                    </FormGroup>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <CancelButton/>
                        {!isDisabled && <SubmitButton label={role ? 'Salva' : 'Inserisci'}/>}
                    </div>
                </Form>
            </FormikProvider>
        </>
    )
}