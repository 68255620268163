import {Outlet} from "react-router-dom";
import PermissionChecker from "../Theme/PermissionChecker";

const ProjectsWrapper =()=>{
    return(
        <PermissionChecker permissions={['projects:view']} navigateTo={'/404'}>
            <Outlet/>
        </PermissionChecker>

    )
}

export default ProjectsWrapper