import TableLink from "../../Buttons/TableLink";
import TrueFalseBadge from "../../TrueFalseBadge";

const ServersTableConfig = [
    {
        Header: 'Nome',
        accessor: 'name',
        Cell: ({ row, value }) =>
            <div className='mt-2 text-green-600'>
                <TableLink to={`/servers/${row.original.uuid}`} value={value}/>
            </div>
    },{
    Header: 'Tipo',
    accessor: 'type.label',
        Cell: ({value}) => <div className='text-gray-500 text-sm'>
            {value}
        </div>
},{
    Header: 'Indirizzo Di Login',
    accessor: 'login_address',
    Cell: ({ row, value }) => <div className='text-gray-500 text-sm'>
        {value}
    </div>
},{
        Header: 'Provider',
        accessor: 'provider',
        Cell: ({value}) => <div className='text-gray-500 text-sm'>
            <TableLink to={`/companies/${value?.uuid}`} value={value?.business_name}/>
        </div>
    },/*{
    Header: 'Indirizzo IP',
    accessor: 'ip_address',
    Cell: ({ row, value }) => <div className='text-gray-500 text-sm'>
        {value}
    </div>
},{
    Header: 'Indirizzo IP Privato',
    accessor: 'private_address',
    Cell: ({ row, value }) => <div className='text-gray-500 text-sm'>
        {value}
    </div>
},{
    Header: 'Provider',
    accessor: 'provider',
    Cell: ({ row, value }) => <div className='text-gray-500 text-sm'>
        {value}
    </div>
},*/{
    Header: 'Plesk',
    accessor: 'is_plesk',
    Cell: ({value }) => <div className='text-center'>
        <TrueFalseBadge value={value}/>
    </div>
}
]

export default ServersTableConfig;