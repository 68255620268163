import React, {useState} from "react";
import {useRequest} from "ahooks";
import packageServices from "../../../../services/PackageService";
import PackageSlideOver from "./PackageSlideOver";

export const DetailsButton = ({uuid}) => {
    const [showSlideOver, setShowSlideOver] = useState(false)

    const {data: pack = undefined, loading} = useRequest(packageServices.packageShow, {
        defaultParams: [uuid],
    })

    return (
        <>
            <div className='text-right items-center'>
                <button onClick={() => setShowSlideOver(true)}
                        className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-green-900 sm:inline-block">
                    Dettagli
                    <span className="sr-only">{uuid}</span>
                </button>
            </div>
            <PackageSlideOver uuid={uuid} pack={pack} loading={loading} state={showSlideOver} onClose={() => setShowSlideOver(false)}/>
        </>
    )
}