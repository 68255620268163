const tableOptions = {
    pagination: {
        buttonIcons: true,
        nextButtonText: '>',
        prevButtonText: '<',
        containerClass: 'flex items-center justify-center border-t border-gray-200 px-4 sm:px-0',
        nextButtonClass: 'inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-green-700',
        prevButtonClass: 'inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-green-700',
        numberButtonClass: 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:text-xl hover:border-gray-300 hover:text-green-700',
        numberClass: '',
        activeClass: 'inline-flex items-center border-t-2 border-green-600 px-4 pt-4 text-sm font-extrabold text-green-600 hover:border-green-600 underline',
    },
    defaultPerPageOption: 10,
    perPageOptions: [10, 15, 30, 50, 100],
    table: {
        tableClass: 'w-full text-left divide-y divide-gray-300',
    },
};


export default tableOptions;