import api from '../configs/api.config';

const serviceUrl = '/services';

const typeEnum = params => api.get('/options/service-types', params).then(res => res.data);
const services = params => api.get(serviceUrl, {params}).then(res => res.data);
const serviceShow = (id, params) => api.get(`${serviceUrl}/${id}`, params).then(res => res.data);
const deleteService = (id, params) => api.delete(`${serviceUrl}/${id}`, params).then(res => res.data);
const updateService = (uuid, params) => api.put(`${serviceUrl}/${uuid}`, params).then(res => res.data);
const updateState = (uuid, params) => api.post(`${serviceUrl}/${uuid}/state`, params).then(res => res.data);

/*const updateState = (params) => api.post(`${serviceUrl}/${params.url}/state`, params).then(res => res.data);*/
const createService = params => api.post(serviceUrl, params).then(res => res.data);

const serviceService = {
    services,
    serviceShow,
    createService,
    updateService,
    deleteService,
    typeEnum,
    updateState,
}

export default serviceService;