import React from "react";
import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import {RevisionProductForm} from "./RevisionProductForm";

export const EditProductSlideOver = ({product, onClose, onProductUpdate, revisionUuid, state}) => {
    return (
        <>
            <SlideOver title={'Informazioni Prodotto'}
                       subtitle={'Modifica le informazioni del prodotto'}
                       size={'max-w-md'}
                       state={state}
                       onClose={onClose}>
                <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                    <RevisionProductForm product={product} onProductUpdate={onProductUpdate} revisionUuid={revisionUuid}/>
                </div>
            </SlideOver>
        </>
    )
}

export default EditProductSlideOver;