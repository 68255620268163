import InfoCardField from "../../../../components/UI/Fields/InfoCardField";
import InfoCardGroup from "../../../../components/UI/Fields/InfoCardGroup";
import StatusBadge from "../../../../components/UI/StatusBadge";

export const SiteInfo = ({site}) => {
    return (
        <InfoCardGroup>
            <InfoCardField title={'Dominio'} value={site?.domain} canCopy/>
            {/*<InfoCardField title={'Stato'} value={site?.stage?.label}/>*/}
            <InfoCardField title={'Stage'} value={<StatusBadge value={site?.stage?.label} color={site?.stage?.color}/>}/>
            {/*<InfoTableStatusField title={'Stage'} state={site?.stage}/>*/}
            {site?.note ? <InfoCardField title={'Note'} value={site?.note}/> : <></>}
        </InfoCardGroup>
    )
}

export default SiteInfo