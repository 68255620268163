import {Field, Form, FormikProvider, useFormik} from "formik";
import {FormGroup} from "../../../components/UI/Forms/FormGroup";
import {FormInput} from "../../../components/UI/Fields/FormInput";
import _ from "lodash";
import CancelButton from "../../../components/UI/Buttons/CancelButton";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import * as Yup from "yup";
import {Select} from "../../../components/UI/Forms/Select";
import {useRequest} from "ahooks";
import CustomerService from "../../../services/CustomerService";
import projectService from "../../../services/ProjectService";
import {v4 as uuidv4} from "uuid";
import packageServices from "../../../services/PackageService";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {useEffect, useState} from "react";
import {PriceInput} from "../../../components/UI/Forms/PriceInput";

export const PackageForm = ({pack = undefined}) => {
    const {uuid} = useParams();
    const navigation = useNavigate();
    const {flashMsg} = useFlashMessage();
    const location = useLocation();

    const [extraParams, setExtraParams] = useState({
        filter: {
            company_id: pack?.company?.id ?? '',
        },
    });

    const {data: companies = []} = useRequest(CustomerService.companiesOptions);
    const {data: projects = [], run} = useRequest(projectService.projectOptions, {
        manual: true,
    });

    const createPackage = (data, setSubmitting) => {
        packageServices.createPackage(data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Pacchetto Creato', undefined, myuuid, true)
                navigation(-1);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
    const updatePackage = (data, setSubmitting) => {
        packageServices.updatePackage(uuid, data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Pacchetto Modificato con Successo', undefined, myuuid, true)
                navigation(-1);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
    const handleSubmit = (values, {setSubmitting}) => {
        const data = {
            company_id: values.company_id,
            project_id: values.project_id,
            price_total: values.price_total,
            amount: values.amount,
            start_at: values.start_at,
            expiration_date: values.expiration_date,
            can_overflow: values.can_overflow,
            note: values.note
        }

        pack ? updatePackage(data, setSubmitting) : createPackage(data, setSubmitting)
    }

    const initialValues = {
        company_id: pack?.company?.id ?? location?.state?.company_id ?? '',
        project_id: pack?.project?.id ?? location?.state?.project_id ?? '',
        price_total: pack?.price_total ?? '',
        amount: pack?.amount ?? '',
        start_at: pack?.start_at?.substring(0, 10) ?? '',
        expiration_date: pack?.expiration_date?.substring(0, 10) ?? '',
        can_overflow: pack?.can_overflow ? (pack.can_overflow ? '1' : '0') : '0',
        note: pack?.note ?? '',
    }

    const validationSchema = Yup.object().shape({
        company_id: Yup.string().nullable().required(),
        project_id: Yup.string().nullable(),
        price_total: Yup.number().nullable().required(),
        amount: Yup.number().nullable().required(),
        start_at: Yup.string().nullable().required(),
        expiration_date: Yup.string().nullable(),
        can_overflow: Yup.string().nullable().required(),
        note: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    useEffect(() => {
        {
            formik.values.company_id !== '' ?
                setExtraParams(prevExtraParams => ({
                    ...prevExtraParams,
                    filter: {
                        ...prevExtraParams.filter,
                        company_id: formik.values.company_id
                    }
                }))

                :
                <></>
        }
    }, [formik.values.company_id]);

    useEffect(() => {
        {
            extraParams.filter.company_id !== '' ?
                run(extraParams)
                :
                <></>;
        }
    }, [extraParams]);

    return (
        <>
                <FormikProvider value={formik}>
                    <Form>
                        <div className="space-y-12">
                            <div
                                className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                <div className='sm:ms-4'>
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">{pack ? "Modifica Pacchetto" : 'Inserisci Nuovo Pacchetto'}</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">{pack ? 'Modifica' : 'Inserisci'} le
                                        informazioni del pacchetto</p>
                                </div>

                                <div
                                    className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Azienda' name='company_id'>
                                            <div className="mt-2">
                                                <Field as={Select} value={formik.values.company_id} name="company_id">
                                                    {companies ?
                                                        <>
                                                            <option value='' className='text-center'>--SELEZIONA
                                                                AZIENDA---
                                                            </option>
                                                            {companies.map(item => (
                                                                <option key={item.value}
                                                                        value={item.value}
                                                                >{item.label}</option>
                                                            ))}
                                                        </>

                                                        : <option>Loading</option>
                                                    }
                                                </Field>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <FormGroup label='Progetto' name='project_id'>
                                            <div className="mt-2">
                                                <Field as={Select} value={formik.values.project_id} name="project_id">
                                                    {formik.values.company_id ? (
                                                        <>
                                                            <option value='' className='text-center'>--SELEZIONA
                                                                PROGETTO---
                                                            </option>
                                                            {projects ? (
                                                                projects?.map(item => (
                                                                    <option key={item.value}
                                                                            value={item.value}>{item.label}</option>
                                                                ))
                                                            ) : (
                                                                <option>Loading</option>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <option className='text-center' value=''
                                                                disabled={true}>--SELEZIONA PRIMA L'AZIENDA---</option>
                                                    )}

                                                </Field>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Prezzo' name='price_total'>
                                            <div className="mt-2">
                                                <Field as={PriceInput} required={true} label='price_total'
                                                       name='price_total' value={formik.values.price_total}/>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Ore Totali' name='amount'>
                                            <div className="mt-2">
                                                <Field as={FormInput} required={true} label='amount' type='number'
                                                       name='amount' value={formik.values.amount}/>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Inizia il' name='start_at'>
                                            <div className="mt-2">
                                                <Field as={FormInput} required={true} label='start_at' type='date'
                                                       name='start_at' value={formik.values.start_at}/>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Finisce il' name='expiration_date'>
                                            <div className="mt-2">
                                                <Field as={FormInput} required={true} label='expiration_date'
                                                       type='date'
                                                       name='expiration_date' value={formik.values.expiration_date}/>
                                            </div>
                                        </FormGroup>
                                    </div>


                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Può Eccedere' name='can_overflow'
                                                   className=''>
                                            <div className="mt-3" role='group'>
                                                <div
                                                    className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                                    <div key='1' className="flex items-center">
                                                        <Field type="radio" name="can_overflow" value='1'
                                                               className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 accent-green-600"
                                                        />
                                                        <label
                                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                            Sì
                                                        </label>
                                                    </div>
                                                    <div key='0' className="flex items-center">
                                                        <Field type="radio" name="can_overflow" value='0'
                                                               className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 accent-green-600"/>
                                                        <label
                                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="note"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Note
                                        </label>
                                        <FormInput
                                            name='note'
                                            type='textarea'
                                            autoComplete=''
                                            value={formik.values.note}
                                            onChange={event => formik.setFieldValue('note', event.target.value)}
                                            error={_.get(formik.errors, 'note')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <CancelButton/>
                            <SubmitButton label={pack ? 'Salva' : 'Inserisci'}/>
                        </div>
                    </Form>
                </FormikProvider>
        </>
    )
}

export default PackageForm