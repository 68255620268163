import LoadingSpinner from "../../components/UI/LoadingSpinner";
import React from "react";
import {useRequest} from "ahooks";
import {useNavigate, useParams} from "react-router-dom";
import productServices from "../../services/ProductService";
import ProductPriceTable from "./components/ProductPriceTable";
import useFlashMessage from "../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";
import productPriceServices from "../../services/ProductPriceService";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import SectionContent from "../../components/Theme/PageSections/SectionContent";
import InfoCard from "../../components/UI/Cards/InfoCard";
import InfoTableField from "../../components/UI/Fields/InfoTableField";
import {InfoNoteField} from "../../components/UI/Fields/InfoNoteField";
import Card from "../../components/UI/Cards/Card";

export const ProductShow = () => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();
    const navigation = useNavigate();

    const {data: product = undefined, loading, mutate} = useRequest(productServices.productShow, {
        defaultParams: [uuid],
    })

    const handlePriceCreated = price => {
        mutate({
            ...product,
            product_prices: price?.is_default
                ? [...product.product_prices.map(p => ({...p, is_default: false})), price]
                : [...product.product_prices, price]
        })
    }

    const handlePriceUpdated = price => {
        console.log('ciao', price)
        mutate({
            ...product,
            product_prices: product.product_prices.map(p =>
                price.is_default ?
                    (p.uuid === price.uuid ? price : {...p, is_default: false}) :
                    (p.uuid === price.uuid ? price : p)
            )
        })
    }
    const handlePriceDelete = (priceUuid) => {
        productPriceServices.deletePrice(uuid, priceUuid).then(res => {
                flashMsg(res.message, null, uuidv4(), true)
                mutate({
                    ...product,
                    product_prices: product.product_prices.filter(p => p.uuid !== priceUuid)
                })
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })

    }

    const deleteHandler = () => {
        productServices.deleteProduct(uuid).then(res => {
                flashMsg(res.message, null, uuidv4(), true)
                navigation(-1)
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
    }

    return (
        <>
            <LoadingSpinner loading={loading}>
                <SectionHeader title={`Dettagli Prodotto`} subtitle={`${product?.code} - ${product?.description}`}/>
                <SectionContent
                    left={
                        <InfoCard title={product?.description} onDelete={deleteHandler} permissions={['products:edit']}>
                            <InfoTableField title={'Codice'} value={product?.code}/>
                            <InfoTableField title={'Unità di Misura'} value={product?.um?.label}/>
                            <InfoTableField title={'Tipo'} value={product?.type?.label}/>
                            <InfoNoteField title={'Descrizione'} value={product?.description}/>
                            <InfoNoteField value={product?.note}/>
                        </InfoCard>
                    }
                    right={
                        <Card>
                            <ProductPriceTable prices={product?.product_prices}
                                               onPriceCreate={handlePriceCreated}
                                               onPriceDelete={handlePriceDelete}
                                               onPriceUpdate={handlePriceUpdated}
                            />
                        </Card>
                    }

                />
            </LoadingSpinner>

            {/*<NewPriceSlideOver state={showSlideOver} onClose={e => setShowSlideOver(false)}
                               onPriceCreated={handlePriceCreated}/>*/}

        </>
    )
}