import LoadingSpinner from "../../components/UI/LoadingSpinner";
import ProjectForm from "./components/ProjectForm";
import {useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import projectServices from "../../services/ProjectService";

export const EditProject =()=>{
    const {uuid} = useParams();
    const {data = [], loading} = useRequest(projectServices.projectShow, {
        defaultParams: [uuid]
    })
    return(
        <LoadingSpinner loading={loading}>
            <ProjectForm project={data}/>
        </LoadingSpinner>
    )
}

export default EditProject;