import {useSanctum} from "react-sanctum";
import {Navigate} from "react-router-dom";

export const PermissionChecker = ({canView = true, permissions, navigateTo = undefined, children, message = undefined}) => {
    const {user} = useSanctum();

    if (canView && (user.permissions.some(permission => permissions?.includes(permission)) || !permissions)) {
        return <>{children}</>;
    } else {
        if (navigateTo !== undefined) {
            return <Navigate to={navigateTo}/>
        }

        return message !== undefined ? <div className='text-center'>{message}</div> : <></>
    }

};

export default PermissionChecker;