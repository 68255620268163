import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import expenseServices from "../../../services/ExpenseService";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {SlideOverFormGroup} from "../../../components/UI/Forms/SlideOverFormGroup";
import SlideOverFormInput from "../../Packages/components/SlideOverFormInput";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import * as Yup from "yup";
import {v4 as uuidv4} from "uuid";
import {useRequest} from "ahooks";
import {Select} from "../../../components/UI/Forms/Select";

const validationSchema = Yup.object().shape({
    year: Yup.string().nullable().required(),
    month: Yup.string().nullable().required(),
    note: Yup.string().nullable(),
})
export const ExpenseForm = ({expense, onCreate, onUpdate}) => {
    const {flashMsg} = useFlashMessage();

    const {data: months} = useRequest(expenseServices.monthOptions);

    const initialValues = {
        year: expense?.year ?? '',
        month: expense?.month?.value ?? '',
        note: expense?.note ?? '',
    }

    const createExpense = (data, setSubmitting) => {
        expenseServices.createExpense(data)
            .then(res => {
                flashMsg('Rimborso Inserito', undefined, uuidv4(), true)
                onCreate(res);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const updateExpense = (data, setSubmitting) => {
        expenseServices.updateExpense(expense?.uuid, data)
            .then(res => {
                flashMsg('Rimboro Modificato', undefined, uuidv4(), true)
                onUpdate(res);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            year: values.year,
            month: values.month,
            note: values.note
        }

        expense ? updateExpense(data, setSubmitting) : createExpense(data, setSubmitting)
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <FormikProvider value={formik}>
            <Form>
                <div className="space-y-8">
                    <div
                        className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 pb-4">

                        <div
                            className="grid grid-cols-1 gap-x-2 gap-y-3 sm:grid-cols-6">

                            <div className="col-span-full">
                                <SlideOverFormGroup required label='Anno' name='year'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} required label='year'
                                               name='year' value={formik.values.year}/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>

                            <div className="col-span-full">
                                <SlideOverFormGroup required={true} label='Mese' name='month'>
                                    <div className="mt-2">
                                        <Field as={Select} value={formik.values.month} name='month'>
                                            {months ?
                                                <>
                                                    <option className='text-center'>--SELEZIONA MESE---</option>
                                                    {months?.map(item => (
                                                        <option key={item.value}
                                                                value={item.value}>{item.label}</option>
                                                    ))}
                                                </>

                                                : <option>Loading</option>
                                            }
                                        </Field>
                                    </div>
                                </SlideOverFormGroup>
                            </div>


                            <div className="col-span-full">
                                <SlideOverFormGroup label='Note' name='note'>
                                    <div className="mt-2">
                                        <Field as={SlideOverFormInput} label='note'
                                               name='note' value={formik.values.note} type='textarea'/>
                                    </div>
                                </SlideOverFormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flex items-center justify-end gap-x-6">
                    <SubmitButton label={expense ? 'Salva' : 'Inserisci'}/>
                </div>
            </Form>
        </FormikProvider>
    )
}

export default ExpenseForm