import api from '../configs/api.config';

const quotesUrl = '/quotes';
const revisionUrl = '/quote-revisions'

const quotes = params => api.get(quotesUrl, {params}).then(res => res.data);

const createQuote = params =>api.post(quotesUrl, params).then(res=>res.data);

const updateQuote = (id, params) =>api.put(`${quotesUrl}/${id}`, params).then(res=>res.data);
const quoteShow = (id, params) => api.get(quotesUrl + '/' + id, params).then(res => res.data);
const quotePrint = (id, revisionUuid) => api.get(`${quotesUrl}/${id}/print/${revisionUuid}`, {responseType: 'blob'}).then(res =>  res.data);
const quoteItemsSwap = (id, revisionUuid, itemUuid, params) => api.post(`${quotesUrl}/${id}${revisionUrl}/${revisionUuid}/quote-items/${itemUuid}/quote-item-prices/swap`, params).then(res => res.data);

const quoteServices = {
    quotes,
    quoteShow,
    quoteItemsSwap,
    quotePrint,
    createQuote,
    updateQuote,
}
export default quoteServices;