import {CurrencyEuroIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";
import {offerServices} from "../../../services/OfferService";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";

const CreateOfferButton = ({service}) => {
    const navigation = useNavigate();
    const {flashMsg} = useFlashMessage();
    const createOfferHandler = () => {
        const data = {
            billable_id: service?.id,
            billable_type: service?.model
        }

        offerServices.createOffer(data).then(res => {
            let myuuid = uuidv4();
            flashMsg('Offerta Creata', null, myuuid, true);
            navigation(`/offers/${res.uuid}`)
        }).catch(err => {
            console.log(err);
            let myuuid = uuidv4();
            flashMsg('Error', err?.response?.data?.message, myuuid, false)
        })
    }


    return (
        <>
            <div className="felx border-t border-gray-100 px-4 py-4 sm:col-span-full sm:px-0">
                <div className="my-6 flex items-center justify-center text-sm leading-6 text-gray-700">
                    <button
                        onClick={() => createOfferHandler()}
                        className='flex rounded-xl bg-white px-2.5 py-1.5 text-sm text-green-600 font-semibold shadow-sm ring-1 ring-inset ring-green-600 hover:bg-green-100 hover:text-green-600 gap-x-2'>
                        <CurrencyEuroIcon className='h-5 w-5'/>Crea Offerta
                    </button>
                </div>
            </div>
        </>

    )
}

export default CreateOfferButton;