import {Menu, Transition} from "@headlessui/react";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import {Fragment} from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export const VerticalMenu = ({actions})=>{
    return(
        <Menu as="div" className="relative flex-none">
            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {actions?.map(action => (
                        <Menu.Item key={action?.label}>
                            {({ active }) => (
                                <button
                                    onClick={action?.action}
                                    className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'block px-3 py-1 text-sm leading-6 text-left text-gray-900 w-full'
                                    )}
                                >
                                    {action?.label}<span className="sr-only">, {action?.label}</span>
                                </button>
                            )}
                        </Menu.Item>
                    ))}

                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default VerticalMenu;