import React from "react";
import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import StorageForm from "./StorageForm";

export const StorageSlideOver = ({storage, siteId, serverUuid, onClose, onCreate, state}) => {

    return (
        <SlideOver title={storage ? 'Informazioni Storage' : 'Nuovo Storage'}
                   subtitle={storage ? 'Modifica le informazioni dello Storage' : 'Inserisci le informazioni dello storage'}
                   state={state}
                   onClose={onClose}>

            <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                <StorageForm serverUuid={serverUuid} siteId={siteId} onCreate={onCreate}/>
            </div>
        </SlideOver>
    )
}

export default StorageSlideOver;