import React, {useEffect, useState} from 'react';
import productPriceServices from "../../../services/ProductPriceService";
import {v4 as uuidv4} from "uuid";
import {useParams} from "react-router-dom";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import TableCardHeader from "../../../components/TableCardHeader";
import PermissionChecker from "../../../components/Theme/PermissionChecker";
import ProductPricesDragNDrop from "./ProductPricesDragNDrop";
import PriceSlideOver from "./PriceSlideOver";

const ProductPriceTable = ({prices = undefined, onPriceDelete, onPriceUpdate, onPriceCreate}) => {
    const [currentPrices, setCurrentPrices] = useState(prices);
    const [showSlideOver, setShowSlideOver] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState()
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();

    useEffect(() => {
        setCurrentPrices(prices)
    }, [prices]);


    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const updatedPrices = [...currentPrices];
        const [reorderedItem] = updatedPrices.splice(result.source.index, 1);
        updatedPrices.splice(result.destination.index, 0, reorderedItem);

        setCurrentPrices(updatedPrices);

        const data = {
            current_prices: updatedPrices.map(item => (item.id))
        };

        productPriceServices.swapPrices(uuid, data).then(res => {
        })
            .catch(err => {
                let myuuid = uuidv4();
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
            });
    };

    return (
        <>
            <TableCardHeader title={'Prezzi'} action={{'action': () => (setShowSlideOver(true) & setSelectedPrice()), 'permissions': ['product_prices:edit']}}/>
            <div className='mt-4'>
                <PermissionChecker permissions={['product_prices:view']}
                                   message={'NON HAI IL PERMESSO DI VEDERE I PREZZI DEI PRODOTTI'}>
                    <ProductPricesDragNDrop prices={currentPrices} onDrag={handleDragEnd}
                                            onClick={(price)=>(setSelectedPrice(price) & setShowSlideOver(true))}
                                            onPriceUpdate={onPriceUpdate} onPriceDelete={onPriceDelete}/>
                </PermissionChecker>
            </div>

            <PriceSlideOver price={selectedPrice} state={showSlideOver} onClose={() => setShowSlideOver(false)}
                            onPriceUpdate={onPriceUpdate}
                            onPriceCreate={onPriceCreate}/>

        </>

    );
};

export default ProductPriceTable

