import TableCardHeader from "../../../components/TableCardHeader";
import {useRequest} from "ahooks";
import expenseService from "../../../services/ExpenseService";
import expenseServices from "../../../services/ExpenseService";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";
import React, {useState} from "react";
import ExpenseTableItem from "./ExpenseTableItem";
import DeleteModal from "../../../components/UI/DeleteModal";
import ExpenseSlideOver from "./ExpenseSlideOver";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";

export const ExpensesTable = () => {
    const {data = [], loading, mutate} = useRequest(expenseService.expenses)
    const [showModal, setShowModal] = useState(false)
    const [showSlideOver, setShowSlideOver] = useState(false)
    const [selectedExpense, setSelectedExpense] = useState();

    const {flashMsg} = useFlashMessage();

    const action = {
        action: () => (setSelectedExpense() & setShowSlideOver(true)),
        /*vertical_actions: [{
            label: 'Nuovo Rimborso Km',
            action: () => console.log('bella')
        }, {
            label: 'Nuovo Rimborso Spese',
            action: () => console.log('ciao2')
        }]*/
    }

    const onCreate = (res) => {
        console.log(res)
        const updatedData = {...data, data: data.data ? [...data.data, res] : [res]};
        mutate(updatedData);
    }

    const onUpdate = (res) => {
        const updatedData = {...data, data: data.data?.map(e => e.uuid !== res.uuid ? e : res)}
        mutate(updatedData);
    }
    const onDelete = (expenseUuid) => {
        expenseServices.deleteExpense(expenseUuid)
            .then(res => {
                flashMsg('Rimborso Eliminato', undefined, uuidv4(), true)
                const updatedData = {...data, data: data.data?.filter(e => e.uuid !== expenseUuid)}
                mutate(updatedData)
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
    }

    return (
        <>
            <TableCardHeader title={'Rimborsi'} action={action}/>
            <LoadingSpinner loading={loading}>
                <ul className="divide-y divide-gray-100">
                    {data?.data?.map(expense =>
                        <ExpenseTableItem expense={expense}
                                          key={expense?.id}
                                          editAction={() => (setSelectedExpense(expense) & setShowSlideOver(true))}
                                          deleteAction={() => (setSelectedExpense(expense) & setShowModal(true))}
                        />
                    )}
                </ul>
            </LoadingSpinner>


            <ExpenseSlideOver expense={selectedExpense} state={showSlideOver}
                              onCreate={onCreate}
                              onUpdate={onUpdate}
                              onClose={() => setShowSlideOver(false)}/>

            <DeleteModal title={selectedExpense?.month?.label}
                         state={showModal}
                         onDelete={() => onDelete(selectedExpense.uuid)}
                         onClose={() => setShowModal(false)}/>
        </>
    )
}