import React, {useState} from "react";
import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import LicenseForm from "./LicenseForm";
import ActionButtonsSlideOver from "../../../../components/UI/Buttons/ActionButtonsSlideOver";
import DeleteModal from "../../../../components/UI/DeleteModal";

export const LicenseSlideOver = ({license, siteId, serverUuid, onClose, onCreate, onUpdate, onDelete, state}) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <SlideOver title={license ? 'Informazioni Licenza' : 'Nuova Licenza'}
                       subtitle={license ? 'Modifica le informazioni della licenza' : 'Inserisci le informazioni della licenza'}
                       state={state}
                       onClose={onClose}>

                <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                    {license ? <ActionButtonsSlideOver editAction={() => setIsDisabled(false)}
                                                       deleteAction={() => setShowModal(true)}/> : <></>}
                    <LicenseForm license={license}
                                 siteId={siteId}
                                 serverUuid={serverUuid}
                                 onUpdate={onUpdate}
                                 onCreate={onCreate}
                                 isDisabled={license ? isDisabled : false}
                    />

                </div>

            </SlideOver>

            <DeleteModal title={license?.name}
                         onClose={() => setShowModal(false)}
                         onDelete={() => onDelete(serverUuid, license?.uuid, siteId)}
                         state={showModal}/>

        </>
    )
}