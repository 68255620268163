import Services from "../../pages/Services/Services";
import NewService from "../../pages/Services/NewService";
import React from "react";
import {ServiceShow} from "../../pages/Services/ServiceShow";
import EditService from "../../pages/Services/EditService";
import {PageBreadcrumbLink} from "../../components/Theme/PageHeader/PageBreadcrumbLink";
import ServicesWrapper from "../../components/Wrappers/ServicesWrapper";
import ServiceWrapper from "../../components/Wrappers/ServiceWrapper";
import PermissionChecker from "../../components/Theme/PermissionChecker";

export const serviceRoutes = [
    {
        path: '/services',
        element: <ServicesWrapper/>,
        handle: {crumb: () => <PageBreadcrumbLink to='/services' label='Servizi'/>},
        children: [{
            path: ':uuid',
            element: <ServiceWrapper/>,
            handle: {crumb: () => <PageBreadcrumbLink label='Show'/>},
            children: [{
                path: 'edit',
                element: <PermissionChecker permissions={'services:edit'} navigateTo={'/404'}>
                    <EditService/></PermissionChecker>,
                handle: {crumb: () => <PageBreadcrumbLink label='Modifica'/>},
            }, {
                path: '',
                element: <ServiceShow/>,
            }]
        }, {
            path: 'create',
            element: <PermissionChecker permissions={'services:edit'}
                                        navigateTo={'/404'}><NewService/></PermissionChecker>,
            handle: {crumb: () => <PageBreadcrumbLink label='Crea'/>},
        },
            {
                path: '',
                element: <Services/>,
            }]
    },
]