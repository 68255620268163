import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {PriceInput} from "../../../components/UI/Forms/PriceInput";
import {FormGroup} from "../../../components/UI/Forms/FormGroup";
import {FormInput} from "../../../components/UI/Fields/FormInput";
import _ from "lodash";
import {Select} from "../../../components/UI/Forms/Select";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import {billServices} from "../../../services/BillService";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import {offerServices} from "../../../services/OfferService";

const validationSchema = Yup.object().shape({
    state: Yup.string().nullable().required(),
    amount: Yup.number().nullable().required(),
    note: Yup.string().nullable(),
})

export const BillForm = ({bill, offerUuid, onBillUpdated, onBillCreated, remainingAmount}) => {
    const {flashMsg} = useFlashMessage();
    const {uuid} = useParams();
    const {data: billStatesEnums = undefined} = useRequest(offerServices.offerStates);

    const handleSubmit = (values, {setSubmitting}) => {
        let myuuid = uuidv4();
        const data = {
            state: values.state,
            amount: values.amount,
            note: values.note,
        }

        {
            data.amount <= remainingAmount ?
                (bill ? updateBill(data, setSubmitting) : addBill(data, setSubmitting))
                :
                (flashMsg('Error', "L'Importo della bill non può essere superiore del rimanente da fatturare", myuuid, false))
            setSubmitting(false)
        }

    }
    const updateBill = (data, setSubmitting) => {

        billServices.updateBill(uuid ?? offerUuid, bill.uuid, data).then(res => {
            setSubmitting(false);
            onBillUpdated(res);
        })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const addBill = (data, setSubmitting) => {
        billServices.createBill(uuid ?? offerUuid, data).then(res => {
            let myuuid = uuidv4();
            setSubmitting(false);
            onBillCreated(res);
            flashMsg('Riga Creata', undefined, myuuid, true)
        })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const initialValues = {
        state: bill?.states.state.value ?? '',
        amount: bill?.amount ?? '',
        note: bill?.note ?? '',
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <FormGroup required={true} label='Importo' name='amount'>
                        <div className="mt-2">
                            <Field as={PriceInput} required={true} label='amount'
                                   name='amount' value={formik.values.amount}/>
                        </div>
                    </FormGroup>
                    <div className='mt-2'>
                        <FormGroup required={true} label='Stato' name='state'>
                            <div className="mt-2">
                                <Field as={Select} value={formik.values.state} name="state">
                                    {bill ?
                                        <>
                                            <option className='text-center' value=''>--CAMBIA STATO---</option>
                                            {bill?.states.available_states.map(item => (
                                                <option key={item.value}
                                                        value={item.value}>{item.label}</option>
                                            ))}
                                        </>

                                        :
                                        <>
                                            <option className='text-center' value=''>--SELEZIONA STATO---</option>
                                            {billStatesEnums?.map(item => (
                                                <option key={item.value}
                                                        value={item.value}>{item.label}</option>
                                            ))}
                                        </>
                                    }
                                </Field>
                            </div>
                        </FormGroup>
                    </div>
                    <div className='mt-2'>
                        <FormGroup label='Note' name='note'>
                            <div className="mt-2">
                                <FormInput
                                    name='note'
                                    type='textarea'
                                    autoComplete=''
                                    value={formik.values.note}
                                    onChange={event => formik.setFieldValue('note', event.target.value)}
                                    error={_.get(formik.errors, 'note')}
                                />
                            </div>
                        </FormGroup>
                    </div>
                    <div className='mt-2 text-right'>
                        <SubmitButton label={bill ? 'Aggiorna' : 'Aggiungi'}/>
                    </div>

                </Form>
            </FormikProvider>
        </>
    )
}

export default BillForm