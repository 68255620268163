import api from '../configs/api.config';

const forgotUrl = '/forgot-password';
const resetUrl = '/reset-password';
const userUrl = 'user';

const requestPasswordLink = (email) => api.post(forgotUrl, { email })
.then(res => res.data)
.catch(err => err.response.data);

const resetPassword = (email, token, password, password_confirmation) => api.post(resetUrl, {
  email,
  token,
  password,
  password_confirmation,
})
.then(res => res.data)
.catch(err => err.response.data);

const authUser = () => api.get(userUrl).then((res) => res.data);

const updatePassword = (params) => api.put(`${userUrl}/password`, params).then((res) => res.data);

const updateUser = (params) => api.put(`${userUrl}/account`, params).then((res) => res.data);

export default {
  authUser,
  updatePassword,
  requestPasswordLink,
  resetPassword,
  updateUser
};
