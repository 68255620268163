export const SlideOverInfoFiled =(props)=>{
    const {title, value} = props;
    return(

            <div
                className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-gray-500">{title}</dt>
                <dd className="text-gray-900">{value ? value : '---'}</dd>
            </div>
    )
}