import React, {useState} from "react";
import SlideOver from "../../../components/UI/SlideOvers/SlideOver";
import DeleteModal from "../../../components/UI/DeleteModal";
import ProductPriceForm from "./ProductPriceForm";
import ActionButtonsSlideOver from "../../../components/UI/Buttons/ActionButtonsSlideOver";

export const PriceSlideOver = ({state, onClose, price, onPriceDelete, onPriceCreate, onPriceUpdate}) => {
    const [showModal, setShowModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    return (
        <>
            <SlideOver title={price ? 'Informazioni Prezzo' : 'Nuovo Prezzo'}
                       subtitle={price ? 'Modifica le informazioni di questo prezzo' : 'Inserisci le informazioni del prezzo'}
                       state={state}
                       size={'max-w-md'}
                       onClose={onClose}>

                <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                    {price ? <ActionButtonsSlideOver editAction={() => setIsDisabled(false)} deleteAction={() => setShowModal(true)}/> : <></>}
                    <ProductPriceForm price={price}
                                      onPriceCreate={onPriceCreate}
                                      onPriceUpdate={onPriceUpdate}
                                      isDisabled={price ? isDisabled : false}
                                     />
                </div>

            </SlideOver>
            <DeleteModal title={price?.description}
                         onClose={() => setShowModal(false)}
                         onDelete={() => onPriceDelete(price?.uuid)}
                         state={showModal}
            />
        </>
    )
}

export default PriceSlideOver;