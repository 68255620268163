import React, {useMemo, useState} from "react";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import {useRequest} from "ahooks";
import activityServices from "../../../services/ActivityService";
import tableOptions from "../../../configs/tableOptions";
import TableCardHeader from "../../../components/TableCardHeader";
import PermissionChecker from "../../../components/Theme/PermissionChecker";

export const ActivitiesTable = ({activities, package_id, project_id, tableConfig, action}) => {
    const {data = [], loading, run} = useRequest(activityServices.activities, {
        manual: true,
        //ready: company?.id !== undefined
    });


    const [extraParams] = useState({
        filter: {
            package_id: package_id ?? '',
            project_id: project_id ?? '',
        },
        sort: {date: '-date'}
    });

    // eslint-disable-next-line
    const columns = useMemo(() => tableConfig, []);
    return (
        <>
            <TableCardHeader title='Attività' action={action}/>
            <PermissionChecker permissions={['activities:view']}
                               message={'NON HAI IL PERMESSO DI VISUALIZZARE LE ATTIVITà'}>
                <div className='py-3'>
                    <PaginatedTable
                        data={activities ?? data ?? []}
                        options={tableOptions}
                        columns={columns}
                        onParamsChange={params => !!params.filter ? run(params) : undefined}
                        extraParams={extraParams}
                        isLoading={loading}
                        //sortable={true}
                    />
                </div>
            </PermissionChecker>
            {activities?.data?.length === 0 ? <h2 className='text-center'>NON CI SONO ATTIVITÀ</h2> : undefined}
        </>
    )
}
export default ActivitiesTable