import ResponseNotify from "../UI/Notifications/ResponseNotify";
import {useContext} from "react";
import {FlashMessagesContext} from "../FlashMessages/FlashMessagesContext";

const FlashMessagesArea = () => {
    const {flashMessages, deleteMessage} = useContext(FlashMessagesContext);
    return (
        <section className='absolute sm:top-4 right-0 px-4 py-4 sm:z-40'>
            {flashMessages.map(item => (
                <ResponseNotify key={item.uuid} title={item.title} subtitle={item.description} state={item.state} success={item.success} uuid={item.uuid}
                                onClose={() => deleteMessage(item.uuid)}/>
            ))}
        </section>
    )
}

export default FlashMessagesArea;