import {useSanctum} from "react-sanctum";
import PopOver from "../../../components/UI/PopOver";
import BillForm from "./BillForm";
import React from "react";

export const BillsTableItem = ({bill, onBillUpdate, state, remainingAmount}) => {
    const {user} = useSanctum();

    if (user.permissions.some(permission => ['bills:edit']?.includes(permission))) {
        return <>{bill?.states?.state?.value === state ?
            <PopOver title={bill?.amount}><BillForm bill={bill}
                                                    onBillUpdated={onBillUpdate} remainingAmount={remainingAmount}/></PopOver> : '---'}</>;
    } else {
        return <>{bill?.states?.state?.value === state ? bill?.amount : '---'}</>
    }

};

export default BillsTableItem;