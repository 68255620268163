import {useRequest} from "ahooks";
import React from "react";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import CustomerService from "../../services/CustomerService";
import customerService from "../../services/CustomerService";
import {useNavigate, useParams} from "react-router-dom";
import CompanyServiceTable from "./components/Services/CompanyServiceTable";
import {PackagesTable} from "./components/Packages/PackagesTable";
import ProjectsTable from "./components/Projects/ProjectsTable";
import useFlashMessage from "../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import SectionContent from "../../components/Theme/PageSections/SectionContent";
import Card from "../../components/UI/Cards/Card";
import InfoTableField from "../../components/UI/Fields/InfoTableField";
import {InfoNoteField} from "../../components/UI/Fields/InfoNoteField";
import InfoCard from "../../components/UI/Cards/InfoCard";
import PeopleTable from "./components/People/PeopleTable";
import ContactsTable from "./components/Contacts/ContactsTable";
import {contactServices} from "../../services/ContactService";
import {personServices} from "../../services/PersonService";
import TrueFalseBadge from "../../components/UI/TrueFalseBadge";
import ServerTable from "./components/Servers/ServerTable";

const CompanyShow = () => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();
    const navigation = useNavigate();
    const {data: company = undefined, loading, mutate} = useRequest(CustomerService.companyShow, {
        defaultParams: [uuid],
    })

    const deleteHandler = () => {
        customerService.deleteCompany(uuid).then(res => {
                flashMsg(res.message, null, uuidv4(), true)
                navigation(-1)
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
    }

    const addContactHandler = (res) => {
        const updatedData = {
            ...company, contacts: [...company.contacts, res]
        }
        mutate(updatedData)
    }

    const updateContactHandler = (res) => {
        const updatedData = {
            ...company, contacts: company.contacts?.map(c => c.uuid !== res.uuid ? c : res)
        }
        mutate(updatedData)
    }

    const deleteContactHandler = (model, modelUuid, contactUuid) => {
        contactServices
            .deleteContact(model, modelUuid, contactUuid)
            .then(res => {
                flashMsg(res.message, null, uuidv4(), true);
                const updatedData = company?.contacts.filter(c => c.uuid !== contactUuid);
                mutate({...company, contacts: updatedData});
            })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false);
            });
    };

    const addPersonHandler = (res) => {
        const updatedData = {
            ...company, people: [...company.people, res]
        }
        mutate(updatedData)
    }

    const updatePersonHandler = (res) => {
        const updatedData = {
            ...company, people: company.people?.map(p => p.uuid !== res.uuid ? p : res)
        }
        mutate(updatedData)
    }

    const deletePersonHandler = (contactUuid) => {
        personServices
            .deletePerson(uuid, contactUuid)
            .then(res => {
                flashMsg(res.message, null, uuidv4(), true);
                const updatedData = company?.people.filter(p => p.uuid !== contactUuid);
                mutate({...company, people: updatedData});
            })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false);
            });
    };

    return (
        <>
            <LoadingSpinner loading={loading}>
                <SectionHeader title={`Dettagli Cliente`} subtitle={company?.business_name}/>

                <SectionContent

                    left={
                        <div className='flex flex-col gap-y-4'>
                            <InfoCard title={company?.business_name} onDelete={deleteHandler}
                                      permissions={['companies:edit']}>
                                <InfoTableField title='Ragione Sociale' value={company?.business_name}/>
                                <InfoTableField title='Partita IVA' value={company?.vat_number}/>
                                <InfoTableField title='Codice Fiscale' value={company?.fiscal_code}/>
                                <InfoTableField title='SDI' value={company?.sdi}/>
                                <InfoTableField title='Condizioni di Pagamento'
                                                value={company?.payment_condition ? company?.payment_condition.label : undefined}/>
                                <InfoTableField title={'Provider'} value={<TrueFalseBadge value={company?.is_provider}/>}/>
                                {company?.note ?
                                    <InfoNoteField value={company?.note}/>
                                    : null
                                }
                            </InfoCard>
                            <Card>
                                <ContactsTable contacts={company?.contacts} model={company?.model} modelUuid={company?.uuid}
                                               onCreate={addContactHandler}
                                               onUpdate={updateContactHandler}
                                               onDelete={deleteContactHandler}/>
                            </Card>
                            <Card>
                                <PeopleTable people={company?.people}
                                             onCreate={addPersonHandler}
                                             onUpdate={updatePersonHandler}
                                             onDelete={deletePersonHandler}
                                />
                            </Card>
                            {company?.is_provider ? <Card>
                                <ServerTable servers={company?.servers}/>
                            </Card> : <></>}
                        </div>
                    }
                    right={
                        <>
                            <div className='flex flex-col gap-x-4 gap-y-4'>
                                <div className='flex xl:flex-row flex-col gap-x-4 gap-y-4'>
                                    <Card flex='basis-1/2'>
                                        <CompanyServiceTable services_count={company?.services_count}
                                                             company_id={company?.id}/>
                                    </Card>
                                    <div className='flex-1'>
                                        <Card flex='flex-1'>
                                            <ProjectsTable projects_count={company?.projects_count}
                                                           company_id={company?.id}/>
                                        </Card>
                                    </div>

                                </div>

                                <Card flex='flex-1'>
                                    <PackagesTable packages_count={company?.packages_count} company_uuid={uuid}
                                                   company_id={company?.id}/>
                                </Card>
                            </div>
                        </>

                    }
                />
            </LoadingSpinner>
        </>
    )
}

export default CompanyShow;