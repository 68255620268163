export const InfoCardGroup = ({children}) => {
    return (
        <>
            <div className='flex flex-col gap-y-3'>
                {children}
            </div>
        </>
    )
}

export default InfoCardGroup;