import ActionButtonsSlideOver from "../../../../components/UI/Buttons/ActionButtonsSlideOver";
import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import React, {useState} from "react";
import DeleteModal from "../../../../components/UI/DeleteModal";
import {AccountForm} from "./AccountForm";

export const AccountSlideOver = ({account, model, siteId, modelUuid, onClose, onDelete, onCreate, onUpdate, state}) => {
    const [isDisabled, setIsDisabled] = useState(true)
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <SlideOver title={account ? 'Informazioni Account' : 'Nuovo Account'}
                       subtitle={account ? 'Modifica le informazioni dell\'account' : 'Inserisci le informazioni dell\'account'}
                       state={state}
                       onClose={onClose}>

                <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                    {account ? <ActionButtonsSlideOver editAction={() => setIsDisabled(false)}
                                                       deleteAction={() => setShowModal(true)}/> : <></>}
                    <AccountForm account={account} siteId={siteId} isDisabled={account ? isDisabled : false} model={model} modelUuid={modelUuid} onCreate={onCreate}
                                 onUpdate={onUpdate}/>
                </div>

            </SlideOver>

            <DeleteModal title={account?.username} onClose={() => setShowModal(false)}
                         onDelete={() => onDelete(model, modelUuid, account?.uuid, siteId)} state={showModal}/>
        </>
    )
}

export default AccountSlideOver;