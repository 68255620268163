import React, {Fragment} from 'react'
import {SlideOverInfoFiled} from "../../../../components/UI/SlideOvers/SlideOverInfoFiled";
import SlideOverDetails from "../../../../components/UI/SlideOvers/components/SlideOverDetails";
import SlideOverNote from "../../../../components/UI/SlideOvers/components/SlideOverNote";
import SlideOverLastActivities from "../../../../components/UI/SlideOvers/components/SlideOverLastActivities";
import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import DateFormat from "../../../../components/UI/DateFormat";
import EuroFormat from "../../../../components/UI/EuroFormat";

export default function PackageSlideOver({uuid, pack, loading, onClose, state}) {

    return(
        <>
            <SlideOver title={'Dettagli Pacchetto'} uuid={uuid} state={state} data={pack} loading={loading}
                       onClose={onClose}>
                <SlideOverDetails>
                    <SlideOverInfoFiled title={'Prezzo Orario'}
                                        value={<EuroFormat value={pack?.price_each}/>}/>
                    <SlideOverInfoFiled title={'Prezzo Totale'}
                                        value={<EuroFormat value={pack?.price_total}/>}/>
                    <SlideOverInfoFiled title={'Ore'} value={pack?.amount}/>
                    <SlideOverInfoFiled title={'Ore Rimanenti'}
                                        value={pack?.remaining_amount}/>
                    <SlideOverInfoFiled title={'Ore Utilizzate'}
                                        value={pack?.used_amount}/>
                    <SlideOverInfoFiled title={'Progetto'}
                                        value={pack?.project?.name}/>
                    <SlideOverInfoFiled title={'Scadenza'}
                                        value={<DateFormat value={pack?.expiration_date}/>}/>
                </SlideOverDetails>


                <SlideOverNote note={pack?.note}/>

                <SlideOverLastActivities pack={pack}/>
            </SlideOver>
        </>
    )
}