import React, {useMemo} from 'react';
import {useRequest} from "ahooks";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import tableOptions from "../../configs/tableOptions";
import {useNavigate} from "react-router-dom";
import ServicesTableConfig from "../../components/UI/Configs/Tables/ServicesTableConfig";
import serviceService from "../../services/ServiceService";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import {createButtonConfig} from "../../components/UI/Configs/createButtonConfig";

const Services = () => {
    const navigation = useNavigate();
    const actions = [{
        ...createButtonConfig,
        action: (e => navigation('create')),
        permissions: ['services:edit'],
    }]

    const columns = useMemo(() => ServicesTableConfig, []);
    const {data = [], loading, run} = useRequest(serviceService.services, {
        manual: true
    });

    return (
        <>
            <SectionHeader
                title={'Servizi'}
                subtitle={'Elenco di tutti i servizi registrati'}
                actions={actions}/>

            <div className="relative overflow-hidden rounded-lg bg-white pb-6 shadow sm:px-6 pt-6 text-center items-center justify-center">
                <PaginatedTable
                    data={data}
                    columns={columns}
                    onParamsChange={run}
                    options={tableOptions}
                    isLoading={loading}
                />
            </div>

        </>

    )

}

export default Services
