import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import {useRequest} from "ahooks";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import serverServices from "../../../../services/ServerService";
import {FormGroup} from "../../../../components/UI/Forms/FormGroup";
import {FormInput} from "../../../../components/UI/Fields/FormInput";
import CancelButton from "../../../../components/UI/Buttons/CancelButton";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";
import {Select} from "../../../../components/UI/Forms/Select";

const validationSchema = Yup.object().shape({
    domain: Yup.string().nullable().required(),
    stage: Yup.string().nullable().required(),
    note: Yup.string().nullable(),
})
export const SiteForm = ({site, serverUuid, onCancel, onCreate, onUpdate}) => {
    const {flashMsg} = useFlashMessage();

    const {data: types = []} = useRequest(serverServices.siteStageTypeOptions);

    const initialValues = {
        domain: site?.domain ?? '',
        stage: site?.stage?.value ?? '',
        note: site?.note ?? '',
    }

    const createSite = (data, setSubmitting) => {
        serverServices.createSite(serverUuid, data)
            .then(res => {
                flashMsg('Sito Creato', undefined, uuidv4(), true)
                onCreate(res, serverUuid);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const updateSite = (data, setSubmitting) => {
        serverServices.updateSite(serverUuid, site?.uuid, data)
            .then(res => {
                flashMsg('Sito Modificato', undefined, uuidv4(), true)
                onUpdate(res, serverUuid);
                onCancel();
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            domain: values.domain,
            stage: values.stage,
            note: values.note
        }

        site ? updateSite(data, setSubmitting) : createSite(data, setSubmitting)
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <FormikProvider value={formik}>
            <Form>
                <div className="space-y-8">
                    <div
                        className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 pb-4">

                        <div
                            className="grid grid-cols-1 gap-x-2 gap-y-3 sm:grid-cols-6">

                            <div className="col-span-full">
                                <FormGroup required label='Dominio' name='domain'>
                                    <div className="mt-2">
                                        <Field as={FormInput} required label='domain'
                                               name='domain' value={formik.values.domain}/>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="sm:col-span-full">
                                <FormGroup required={true} label='Stage' name='stage'>
                                    <div className="mt-2">
                                        <Field as={Select} value={formik.values.stage} name="stage">
                                            {types ?
                                                <>
                                                    <option value='' className='text-center'>--SELEZIONA
                                                        STAGE---
                                                    </option>
                                                    {types.map(item => (
                                                        <option key={item.value}
                                                                value={item.value}
                                                        >{item.label}</option>
                                                    ))}
                                                </>

                                                : <option>Loading</option>
                                            }
                                        </Field>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-span-full">
                                <FormGroup label='Note' name='note'>
                                    <div className="mt-2">
                                        <Field as={FormInput} type='textarea' label='note'
                                               name='note' value={formik.values.note}/>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flex items-center justify-end gap-x-6">
                    {onCancel ? <CancelButton action={onCancel}/> : <></>}
                    <SubmitButton label={site ? 'Salva' : 'Inserisci'}/>
                </div>
            </Form>
        </FormikProvider>
    )
}

export default SiteForm;