import {Outlet} from "react-router-dom";
import PermissionChecker from "../Theme/PermissionChecker";

export const QuotesWrapper = () => {
    return (
        <PermissionChecker permissions={['quotes:view', 'quotes:create']} navigateTo={'/404'}>
            <Outlet/>
        </PermissionChecker>
    )
}
export default QuotesWrapper