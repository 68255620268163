export const ProfileHeader = ({user})=>{
    return(
        <div className="flex items-center space-x-5 px-4">
            <div className="flex-shrink-0">
                <div className="relative">
                    <img
                        className="h-16 w-16 rounded-full"
                        src={user?.avatar}
                        alt=""
                    />
                    <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"/>
                </div>
            </div>
            <div>
                <h1 className="text-2xl font-bold text-gray-900">{user?.full_name}</h1>
                <p className="text-sm font-medium text-gray-500">
                    {user?.email}
                </p>
            </div>
        </div>
    )
}

export default ProfileHeader;