import {useNavigate, useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import serverServices from "../../services/ServerService";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import SectionContent from "../../components/Theme/PageSections/SectionContent";
import InfoTableField from "../../components/UI/Fields/InfoTableField";
import InfoCard from "../../components/UI/Cards/InfoCard";
import React from "react";
import {InfoNoteField} from "../../components/UI/Fields/InfoNoteField";
import Card from "../../components/UI/Cards/Card";
import AccountsTable from "./components/Accounts/AccountsTable";
import DatabasesTable from "./components/Databases/DatabasesTable";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../components/FlashMessages/useFlashMessage";
import LicensesTable from "./components/Licenses/LicensesTable";
import StoragesTable from "./components/Storages/StoragesTable";
import SitesTable from "./components/Sites/SitesTable";
import TrueFalseBadge from "../../components/UI/TrueFalseBadge";

export const ServerShow = () => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();
    const navigation = useNavigate();

    const {data, loading} = useRequest(serverServices.serverShow, {
        defaultParams: [uuid]
    })

    const deleteHandler = () => {
        serverServices.deleteServer(uuid).then(res => {
                flashMsg(res.message, null, uuidv4(), true)
                navigation(-1)
            }
        ).catch(err => {
            console.log(err);
            flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
        })
    }

    return (
        <LoadingSpinner loading={loading}>
            <SectionHeader title={'Dettagli Server'} subtitle={data?.name}/>
            <SectionContent
                left={
                    <div className='flex flex-col gap-y-4'>
                        <InfoCard title={data?.type?.label} onDelete={deleteHandler}
                            //permissions={['servers:edit']}
                        >
                            <InfoTableField title='Indirizzo di Login' value={data?.login_address} span={'full'}/>
                            <InfoTableField title='Indirizzo IP' value={data?.ip_address}/>
                            <InfoTableField title='Indirizzo Privato' value={data?.private_address}/>
                            <InfoTableField title='Provider' value={data?.provider?.business_name}/>
                            <InfoTableField title='Plesk' value={<TrueFalseBadge value={data?.is_plesk}/>}/>
                            {data?.note ?
                                <InfoNoteField value={data?.note}/>
                                : null
                            }
                        </InfoCard>
                        <Card flex='flex-1'>
                            <LicensesTable licenses={data?.licenses}
                                           serverUuid={data?.uuid}
                            />
                        </Card>
                    </div>
                }
                right={
                    <div className='flex flex-col gap-x-4 gap-y-4'>
                        <div className='flex xl:flex-row flex-col gap-x-4 gap-y-4'>
                            <div className='basis-1/2'>
                                <Card>
                                    <AccountsTable accounts={data?.accounts} model={data?.model}
                                                   modelUuid={data?.uuid}
                                    />
                                </Card>
                            </div>

                            <div className='flex-1'>
                                <Card>
                                    <StoragesTable storages={data?.storages}
                                                   serverUuid={data?.uuid}/>
                                </Card>
                            </div>

                        </div>

                        <div className='flex xl:flex-row flex-col gap-x-4 gap-y-4'>
                            <div className='basis-1/2'>
                                <Card>
                                    <DatabasesTable databases={data?.databases}
                                                    serverUuid={data?.uuid}/>
                                </Card>
                            </div>
                            <div className='flex-1'>
                                <Card>
                                    <SitesTable sites={data?.sites}
                                                serverUuid={data?.uuid}/>
                                </Card>
                            </div>
                        </div>

                    </div>
                }/>
        </LoadingSpinner>
    )
}