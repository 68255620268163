import TableCardHeader from "../../../../components/TableCardHeader";
import PermissionChecker from "../../../../components/Theme/PermissionChecker";
import NewElement from "../../../../components/UI/Buttons/NewElement";
import React, {useEffect, useState} from "react";
import StorageTableItem from "./StorageTableItem";
import StorageSlideOver from "./StorageSlideOver";
import serverServices from "../../../../services/ServerService";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";

export const StoragesTable = ({
                                  storages,
                                  siteId,
                                  serverUuid
                              }) => {

    const [showStorageSlideOver, setShowStorageSlideOver] = useState(false);
    const [updatedStorages, setUpdatedStorages] = useState(storages)

    const {flashMsg} = useFlashMessage();


    useEffect(() => {
        setUpdatedStorages(storages)
    }, [storages]);


    const addStorageHandler = (res) => {
        setUpdatedStorages(updatedStorages ? [...updatedStorages, res] : [res])
    }

    const updateStorageHandler = (res) => {
        setUpdatedStorages(updatedStorages.map(s => s.uuid !== res.uuid ? s : res))
    }

    const deleteStorageHandler = (modelUuid, storageUuid) => {
        serverServices.deleteStorage(modelUuid, storageUuid).then(res => {
                flashMsg(res.message, null, uuidv4(), true)
                setUpdatedStorages(updatedStorages.filter(s => s.uuid !== storageUuid))
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
    }


    return (
        <>
            {updatedStorages?.length > 0 ?
                <>
                    <TableCardHeader title='Storage' action={{
                        'action': e => setShowStorageSlideOver(true),
                        //permissions: ['storages:edit']
                    }}/>
                    <PermissionChecker /*permissions={['storages:view']}*/
                        message={'NON HAI IL PERMESSO DI VISUALIZZARE GLI STORAGE'}>
                        <ul className="divide-y divide-gray-100">
                            {updatedStorages?.map(storage =>
                                <StorageTableItem
                                    key={storage?.id}
                                    storage={storage}
                                    siteId={siteId}
                                    onUpdate={updateStorageHandler}
                                    onDelete={deleteStorageHandler}
                                    serverUuid={serverUuid}
                                />
                            )}
                        </ul>
                    </PermissionChecker>
                </>
                :
                <PermissionChecker /*permissions={['storages:edit']}*/
                    message={'NON HAI IL PERMESSO DI AGGIUNGERE NUOVI STORAGE'}>
                    <NewElement type='storage'
                                onClick={e => setShowStorageSlideOver(true)}/>
                </PermissionChecker>
            }
            <StorageSlideOver serverUuid={serverUuid} siteId={siteId}
                              onCreate={addStorageHandler}
                              state={showStorageSlideOver}
                              onClose={e => setShowStorageSlideOver(false)}
            />

        </>
    )
}

export default StoragesTable