import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";
import InfoPopOver from "../../../../components/UI/InfoPopOver";
import React from "react";

export const ContactTableField = ({contact, onClick}) => {
    return (
        <>
            <li key={contact.id}
                className="flex items-center justify-between py-3">
                <div className="flex flex-col sm:flex-row md:flex-row xl:flex-row justify-between space-x-2 basis-4/5">

                    <p className="flextext-sm basis-1/3 font-medium">
                        {contact.label}
                    </p>
                    <p className="flex-1 text-sm font-medium text-gray-500">
                        {contact.value}
                    </p>
                </div>
                <div className='flex flex-1 flex-row gap-x-6 justify-end'>
                    {contact?.note ? <InfoPopOver>
                        {contact?.note}
                    </InfoPopOver> : <></>}
                    <div className="flex items-center pb-2">
                        <button
                            onClick={onClick}
                            className="rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            <ArrowTopRightOnSquareIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>

                    </div>
                </div>
            </li>
        </>
    )
}

export default ContactTableField