import React, {useState} from "react";
import {Disclosure} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import AccountsTable from "../Accounts/AccountsTable";
import ActionButtonsSlideOver from "../../../../components/UI/Buttons/ActionButtonsSlideOver";
import DeleteModal from "../../../../components/UI/DeleteModal";
import DatabaseInfo from "./DatabaseInfo";
import DatabaseForm from "./DatabaseForm";

export const DatabaseTableItem = ({
                                      database,
                                      siteId,
                                      onUpdate,
                                      onDelete,
                                      serverUuid
                                  }) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false)
    return (
        <>
            <li key={database.id}
                className="flex flex-col sm:flex-row items-center gap-x-6 py-3 w-full">
                <div className='flex w-full'>
                    <div className="w-full rounded-2xl bg-white basis-7/8 ">
                        <Disclosure>
                            {({open}) => (
                                <>
                                    <Disclosure.Button
                                        className="flex w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75">
                                        <span>{database?.name}</span>
                                        <ChevronDownIcon
                                            className={`${
                                                open ? 'rotate-180 transform' : ''
                                            } h-5 w-5 text-gray-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                        className="px-4 pb-2 pt-4 mt-1 border border-gray-500 text-sm rounded-lg">
                                        <div className='flex flex-col min-w-full mb-4'>
                                            <div className='text-right'>
                                                <ActionButtonsSlideOver editAction={() => setIsDisabled(!isDisabled)}
                                                                        deleteAction={() => setShowModal(true)}
                                                                        canDelete={true}/>
                                            </div>
                                            <div>
                                                {isDisabled ? <DatabaseInfo database={database}/> :
                                                    <DatabaseForm database={database}
                                                                  siteId={siteId}
                                                                  onUpdate={onUpdate}
                                                                  serverUuid={serverUuid}
                                                                  onCancel={() => setIsDisabled(true)}/>}
                                            </div>
                                        </div>

                                        <div className="relative mt-4 mb-8">
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="w-full border-t border-gray-200"/>
                                            </div>
                                        </div>
                                        <AccountsTable accounts={database?.accounts} siteId={siteId} model={database?.model}
                                                       modelUuid={database?.uuid}
                                        />
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </div>
                </div>
            </li>
            <DeleteModal title={database?.name} onClose={() => setShowModal(false)}
                         onDelete={() => onDelete(serverUuid, database?.uuid, siteId)} state={showModal}/>
        </>
    )
}

export default DatabaseTableItem;