import TableCardHeader from "../../../components/TableCardHeader";
import {useRequest} from "ahooks";
import LoadingSpinner from "../../../components/UI/LoadingSpinner";
import React, {useEffect, useState} from "react";
import vehicleServices from "../../../services/VehicleService";
import {useSanctum} from "react-sanctum";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import DeleteModal from "../../../components/UI/DeleteModal";
import VehicleSlideOver from "./VehicleSlideOver";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";

export const VehiclesTable = () => {
    const [showModal, setShowModal] = useState(false)
    const [showSlideOver, setShowSlideOver] = useState(false)
    const [selectedVehicle, setSelectedVehicle] = useState();

    const {user} = useSanctum();
    const {flashMsg} = useFlashMessage();


    const action = {
        action: () => (setSelectedVehicle() & setShowSlideOver(true)),
    }

    useEffect(() => {
        run({
            filter: {
                user_id: user?.id,
            }
        })
        // eslint-disable-next-line
    }, []);

    const {data: vehicles, mutate: mutateVehicles, loading, run} = useRequest(vehicleServices.vehicles, {
        manual: true
    });

    const createVehicle = (vehicle) => {
        mutateVehicles([...vehicles, vehicle])
    }

    const updateVehicle = (vehicle) => {
        mutateVehicles(vehicles?.map(v => v.uuid !== vehicle?.uuid ? v : vehicle))
    }

    const deleteVehicle = (vehicleUuid) => {

        vehicleServices.deleteVehicle(vehicleUuid)
            .then(res => {
                flashMsg(res?.message, undefined, uuidv4(), true)
                const updatedData = vehicles?.filter(v => v.uuid !== vehicleUuid)
                mutateVehicles(updatedData);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
    }


    return (
        <>
            <TableCardHeader title={'Veicoli'} action={action}/>
            <LoadingSpinner loading={loading}>
                {/*<ul className="divide-y divide-gray-100">

                </ul>*/}

                <table className="w-full text-left">
                    <thead className="bg-white">
                    <tr>
                        <th scope="col" className="relative py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                            Nome
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                            Targa
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                            Prezzo al Km
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                            Note
                        </th>

                        <th scope="col" className="relative py-3.5 pl-3">

                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {vehicles?.map((v) => (
                        <tr key={v.id} className='border-b border-b-gray-100'>
                            <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                                {v.name}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{v.license}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">{v.km_price}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">{v.note}</td>
                            <td className="relative py-4 pl-3 text-right text-sm font-medium">
                                <button
                                    onClick={() => (setSelectedVehicle(v) & setShowSlideOver(true))}
                                    className="inline-flex items-center gap-x-2 rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                >
                                    <PencilSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                </button>
                                <button
                                    onClick={() => (setSelectedVehicle(v) & setShowModal(true))}
                                    className="inline-flex ps-3 items-center gap-x-2 rounded-md text-xs font-semibold text-red-600 hover:text-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                >
                                    <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </LoadingSpinner>

            <VehicleSlideOver vehicle={selectedVehicle} state={showSlideOver}
                              onCreate={createVehicle}
                              onUpdate={updateVehicle}
                              onClose={() => setShowSlideOver(false)}/>

            <DeleteModal title={selectedVehicle?.license}
                         state={showModal}
                         onDelete={() => deleteVehicle(selectedVehicle?.uuid)}
                         onClose={() => setShowModal(false)}/>
        </>
    )
}