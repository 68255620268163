import React from 'react';
import {useFormikContext} from 'formik';
import _ from 'lodash';
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";

export const SlideOverFormGroup = ({label, name, required = false, children, className = "sm:col-span-2"}) => {

    const {errors} = useFormikContext()
    const error = _.get(errors, name)

    return (
        <>
            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                    <label
                        htmlFor="project-description"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >

                            <div className='flex items-center justify-between'>
                                {label}{required ? ' *' : ''}
                            </div>

                    </label>
                </div>
                <div className={className}>
                    {children}
                </div>
            </div>
            {error ? (<><div className='flex items-center'><ExclamationCircleIcon className="h-5 w-5 text-red-500"
                                                                                  aria-hidden="true"/> <p className="px-4 text-sm text-red-600">{error}</p></div></>) : <></>}
        </>
    )
}
