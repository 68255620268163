import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import _ from "lodash";
import {useRequest} from "ahooks";
import CustomerService from "../../../services/CustomerService";
import {FormGroup} from "../../../components/UI/Forms/FormGroup";
import {Select} from "../../../components/UI/Forms/Select";
import {FormInput} from "../../../components/UI/Fields/FormInput";
import CancelButton from "../../../components/UI/Buttons/CancelButton";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import serviceService from "../../../services/ServiceService";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {PriceInput} from "../../../components/UI/Forms/PriceInput";

const setAutoRenewState = (state) => {
    if (state) {
        return '1'
    } else {
        return '0'
    }
}
export default function ServiceForm({service = undefined}) {
    const {uuid} = useParams();
    const location = useLocation();

    const navigation = useNavigate();
    const {flashMsg} = useFlashMessage();

    const {data: companies = []} = useRequest(CustomerService.companiesOptions);

    const {data: typeEnum = []} = useRequest(serviceService.typeEnum);


    const initialValues = {
        type: service?.type?.value ?? '',
        company_id: service?.company_id ?? location?.state?.company_id ?? '',
        start_at: service?.start_at?.substring(0, 10) ?? '',
        amount: service?.amount ?? '',
        duration: service?.duration ?? '',
        is_auto_renew: setAutoRenewState(service?.is_auto_renew),
        note: service?.note ?? '',
    }

    const createService = (data, setSubmitting) => {
        serviceService.createService(data)
            .then(res => {
                flashMsg('Servizio Creato', undefined, uuidv4(), true)
                navigation(-1);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const updateService = (data, setSubmitting) => {
        serviceService.updateService(uuid, data)
            .then(res => {
                flashMsg('Servizio Modificato', undefined, uuidv4(), true)
                navigation(-1);
            })
            .catch(err => {
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
                console.log(err);
            })
            .finally(() => setSubmitting(false));
    }

    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            type: values.type,
            company_id: values.company_id,
            start_at: values.start_at,
            amount: values.amount,
            duration: values.duration,
            is_auto_renew: values.is_auto_renew,
            note: values.note
        }

        service ? updateService(data, setSubmitting) : createService(data, setSubmitting)
    }


    const validationSchema = Yup.object().shape({
        type: Yup.string().nullable().required(),
        company_id: Yup.number().nullable().required(),
        amount: Yup.number().nullable().required(),
        duration: Yup.number().nullable().required(),
        start_at: Yup.string().nullable().required(),
        is_auto_renew: Yup.string().nullable().required(),
        note: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });


    return (
        <>
                <FormikProvider value={formik}>
                    <Form>
                        <div className="space-y-12">
                            <div
                                className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                <div className='ms-4'>
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">{service ? 'Modifica' : 'Nuovo'} Servizio</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">{service ? 'Modifica' : 'Inserisci'} le
                                        informazioni del
                                        Servizio</p>
                                </div>

                                <div
                                    className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Tipo' name='type'>
                                            <div className="mt-2">
                                                <Field as={Select} value={formik.values.type} name='type'>
                                                    {typeEnum ?
                                                        <>
                                                            <option className='text-center'>--SELEZIONA TIPO---</option>
                                                            {typeEnum?.map(item => (
                                                                <option key={item.value}
                                                                        value={item.value}>{item.label}</option>
                                                            ))}
                                                        </>

                                                        : <option>Loading</option>
                                                    }
                                                </Field>
                                            </div>
                                        </FormGroup>
                                    </div>


                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Azienda' name='company_id'>
                                            <div className="mt-2">
                                                <Field as={Select} value={formik.values.company_id} name="company_id">
                                                    {companies ?
                                                        <>
                                                            <option className='text-center'>--SELEZIONA AZIENDA---
                                                            </option>
                                                            {companies.map(item => (
                                                                <option key={item.value}
                                                                        value={item.value}>{item.label}</option>
                                                            ))}
                                                        </>

                                                        : <option>Loading</option>
                                                    }
                                                </Field>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Importo' name='amount'>
                                            <div className="mt-2">
                                                <Field as={PriceInput} required={true} label='amount'
                                                       name='amount' value={formik.values.amount}/>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Inizia il' name='start_at'>
                                            <div className="mt-2">
                                                <Field as={FormInput} required={true} label='start_at' type='date'
                                                       name='start_at' value={formik.values.start_at}/>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Durata (giorni)' name='duration'>
                                            <div className="mt-2">
                                                <Field as={FormInput} required={true} label='duration' type='number'
                                                       name='duration' value={formik.values.duration}/>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <FormGroup required={true} label='Auto Renew' name='is_auto_renew' className=''>
                                            <div className="mt-3" role='group'>
                                                <div
                                                    className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                                    <div key='1' className="flex items-center">
                                                        <Field type="radio" name="is_auto_renew" value='1'
                                                               className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 accent-green-600"/>
                                                        <label
                                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                            Sì
                                                        </label>
                                                    </div>
                                                    <div key='0' className="flex items-center">
                                                        <Field type="radio" name="is_auto_renew" value='0'
                                                               className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 accent-green-600"/>
                                                        <label
                                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="note"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Note
                                        </label>
                                        <FormInput
                                            name='note'
                                            type='textarea'
                                            autoComplete=''
                                            value={formik.values.note}
                                            onChange={event => formik.setFieldValue('note', event.target.value)}
                                            error={_.get(formik.errors, 'note')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <CancelButton/>
                            <SubmitButton label={service ? 'Salva' : 'Inserisci'}/>
                        </div>
                    </Form>
                </FormikProvider>
        </>
    )
}
