export const PackageTableCol=({field = undefined})=>{
    return(
        <div className="text-sm font-normal gap-x-2 leading-5">
            <p className="whitespace-nowrap">
                {field ? field : '---'}
            </p>
        </div>
    )
}

export default PackageTableCol;