import React, {useState} from "react";
import VehicleForm from "./VehicleForm";
import SlideOver from "../../../components/UI/SlideOvers/SlideOver";

export const VehicleSlideOver = ({vehicle, state, onClose, onUpdate, onCreate, onDelete}) => {
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <SlideOver title={vehicle ? 'Modifica Veicolo' : 'Nuovo Veicolo'}
                       subtitle={vehicle ? 'Modifica i dettagli del veicolo' : 'Inserisci i dettagli del veicolo'}
                       state={state}
                       onClose={onClose}>
                <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                    <VehicleForm vehicle={vehicle}
                                 onCreate={onCreate}
                                 onUpdate={onUpdate}/>
                </div>
            </SlideOver>
        </>
    )
}

export default VehicleSlideOver