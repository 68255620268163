import api from '../configs/api.config';

const rolesUrl = '/roles';

const roles = params => api.get(rolesUrl, params).then(res => res.data);
const roleShow = (id, params) => api.get(rolesUrl + '/' + id, params).then(res => res.data)
const createRole = params => api.post(rolesUrl, params).then(res => res.data);
const updateRole = (uuid, params) => api.put(`${rolesUrl}/${uuid}`, params).then(res => res.data);
const deleteRole = (id, params) => api.delete(`${rolesUrl}/${id}`, params).then(res => res.data);
const roleOptions = params => api.get('/options/roles', params).then(res => res.data);

const permissionOptions = params => api.get('/options/permissions', params).then(res => res.data);


const roleServices = {
    roles,
    roleShow,
    createRole,
    updateRole,
    deleteRole,
    roleOptions,
    permissionOptions,
}

export default roleServices;