import React, {useEffect, useState} from "react";
import {Disclosure} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import AccountsTable from "../Accounts/AccountsTable";
import ActionButtonsSlideOver from "../../../../components/UI/Buttons/ActionButtonsSlideOver";
import StorageInfo from "./StorageInfo";
import StorageForm from "./StorageForm";
import DeleteModal from "../../../../components/UI/DeleteModal";
import {mergeArrays} from "../../../../configs/MergeArrays";

export const StorageTableItem = ({
                                     storage,
                                     siteId,
                                     onUpdate,
                                     onDelete,
                                     serverUuid
                                 }) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false)
    const [updatedAccounts, setUpdatedAccounts] = useState(storage?.accounts)


    useEffect(() => {
        setUpdatedAccounts(mergeArrays(updatedAccounts, storage?.accounts))
        // eslint-disable-next-line
    }, [storage]);


    return (
        <>
            <li key={storage.id}
                className="flex flex-col sm:flex-row items-center gap-x-6 py-3 w-full">
                <div className='flex w-full'>
                    <div className="w-full rounded-2xl bg-white basis-7/8 ">
                        <Disclosure>
                            {({open}) => (
                                <>
                                    <Disclosure.Button
                                        className="flex w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75">
                                        <span>{storage?.provider}</span>
                                        <ChevronDownIcon
                                            className={`${
                                                open ? 'rotate-180 transform' : ''
                                            } h-5 w-5 text-gray-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                        className="px-4 pb-2 pt-4 mt-1 border border-gray-500 text-sm rounded-lg">
                                        <div className='flex flex-col min-w-full mb-4'>
                                            <div className='text-right'>
                                                <ActionButtonsSlideOver editAction={e => setIsDisabled(!isDisabled)}
                                                                        deleteAction={e => setShowModal(true)}
                                                />
                                            </div>
                                            <div>
                                                {isDisabled ? <StorageInfo storage={storage}/>
                                                    :
                                                    <StorageForm
                                                        storage={storage}
                                                        serverUuid={serverUuid}
                                                        siteId={siteId}
                                                        onUpdate={onUpdate}
                                                        onCancel={e => setIsDisabled(true)}
                                                    />
                                                }
                                            </div>
                                        </div>

                                        <div className="relative mt-4 mb-8">
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="w-full border-t border-gray-200"/>
                                            </div>
                                        </div>
                                        <AccountsTable accounts={updatedAccounts} siteId={siteId} model={storage?.model}
                                                       modelUuid={storage?.uuid}
                                        />
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </div>
                </div>
            </li>
            <DeleteModal title={storage?.provider} onClose={() => setShowModal(false)}
                         onDelete={e => onDelete(serverUuid, storage?.uuid, siteId)} state={showModal}/>
        </>
    )
}

export default StorageTableItem