import React from 'react';
import ReactDOM from 'react-dom/client';
import './input.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Sanctum} from 'react-sanctum';
import {sanctumConfig} from './configs/sanctum.config';
import {RouterProvider} from 'react-router-dom';
import router from './routes/router';
import FlashMessagesContextProvider from "./components/FlashMessages/FlashMessagesContextProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Sanctum config={sanctumConfig}>
            <FlashMessagesContextProvider>
                <RouterProvider router={router}>
                    <App/>
                </RouterProvider>
            </FlashMessagesContextProvider>
    </Sanctum>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
