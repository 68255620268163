import React from 'react';
import AuthMiddleware from '../middlewares/AuthMiddleware';
import { Layout } from '../components/Theme/Layout';
import { AuthLayout } from '../components/Theme/AuthLayout';
import { publicRoutes } from './publicRoutes';
import { privateRoutes } from './privateRoutes';
import { createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthMiddleware auth redirect="auth/login"><Layout/></AuthMiddleware>,
    children: privateRoutes,
  }, {
    path: '/',
    element: <AuthMiddleware auth={false} redirect="/"><AuthLayout/></AuthMiddleware>,
    children: publicRoutes,
  }
]);

export default router;
