import * as Yup from "yup";
import {Form, FormikProvider, useFormik} from "formik";
import authService from "../../../../services/AuthService";
import {FormGroup} from "../../../../components/UI/Forms/FormGroup";
import {FormInput} from "../../../../components/UI/Fields/FormInput";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from 'uuid';


export default function UpdateUserInfoForm({user, onUpdate}) {
    const {flashMsg} = useFlashMessage();
    const updateUser = (data, setSubmitting) => {
        authService.updateUser(data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Utente Modificato Con Successo', null, myuuid, true);
                onUpdate(res)
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const handleSubmit = (values, {setSubmitting}) => {
        const data = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
        }
        updateUser(data, setSubmitting)
    }


    const validationSchema = Yup.object().shape({
        first_name: Yup.string().nullable().required(),
        last_name: Yup.string().required(),
        email: Yup.string().email(),
    })

    const initialValues = {
        first_name: user?.first_name ?? '',
        last_name: user?.last_name ?? '',
        email: user?.email ?? '',
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });


    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <div className="space-y-12">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-6">
                            <div
                                className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 lg:grid-cols-2">
                                <div className="col-span-full lg:col-span-1">
                                    <FormGroup required label='Nome' name='first_name'>
                                        <FormInput label='first_name' name='first_name'
                                                   value={formik.values.first_name}/>
                                    </FormGroup>
                                </div>
                                <div className="col-span-full lg:col-span-1">
                                    <FormGroup required label='Cognome' name='last_name'>
                                        <FormInput label='last_name' name='last_name'
                                                   value={formik.values.last_name}/>
                                    </FormGroup>
                                </div>
                                <div className="col-span-full lg:col-span-2">
                                    <FormGroup required label='Email' name='email'>
                                        <FormInput label='email' name='email'
                                                   value={formik.values.email}/>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3 flex items-center justify-end gap-x-6">
                        <SubmitButton label={'Aggiorna'}/>
                    </div>
                </Form>
            </FormikProvider>
        </>
    )
}