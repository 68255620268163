import React from "react";
import EuroFormat from "../../../components/UI/EuroFormat";

export const RevisionFooter = ({total}) => {
    return (
        <div className='flex justify-end'>
            <footer>
                {/*<tr>
                    <th
                        scope="row"
                        colSpan={3}
                        className="pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                    >
                        Sconto
                    </th>
                    <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-0">$1,760.00</td>
                </tr>
                <tr>
                    <th
                        scope="row"
                        colSpan={3}
                        className="pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                    >
                        Totale
                    </th>
                    <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0"><EuroFormat value={total}/> </td>
                </tr>*/}
                <div className='flex justify-between w-40'>
                    <div
                         className="pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0 col-span-3"
                    >
                        Totale:
                    </div>
                    <div className="pl-3 pr-4 pt-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"><EuroFormat
                        value={total}/></div>
                </div>
            </footer>
        </div>
    )
}

export default RevisionFooter