import {useRequest} from "ahooks";
import React from "react";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import {useNavigate, useParams} from "react-router-dom";
import useFlashMessage from "../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import SectionContent from "../../components/Theme/PageSections/SectionContent";
import InfoTableField from "../../components/UI/Fields/InfoTableField";
import InfoCard from "../../components/UI/Cards/InfoCard";
import userServices from "../../services/UserService";

const UserShow = () => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();
    const navigation = useNavigate();
    const {data = undefined, loading} = useRequest(userServices.userShow, {
        defaultParams: [uuid],
    })

    /*    useEffect(() => company?.id !== undefined
                ? setExtraParams(ep => {
                    return {...ep, filter: {...ep.filter, company_id: company?.id}}
                }) : undefined
            , [company?.id]);*/

    const deleteHandler = () => {
        let myuuid = uuidv4();
        userServices.deleteUser(uuid).then(res => {
                flashMsg(res.message, null, myuuid, true)
                navigation(-1)
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
            });
    }

    return (
        <>
            <LoadingSpinner loading={loading}>
                <SectionHeader title={`Dettagli Utente`} subtitle={data?.full_name}/>

                <SectionContent

                    left={
                        <InfoCard title={data?.full_name} onDelete={deleteHandler}
                                  icon={<img
                                      className="h-12 w-12 rounded-full"
                                      src={data?.avatar}
                                      alt=""
                                  />}
                                  permissions={['users:edit']}>
                            <InfoTableField title='Nome' value={data?.first_name}/>
                            <InfoTableField title='Cognome' value={data?.last_name}/>
                            <InfoTableField title='E-Mail' value={data?.email}/>
                            <InfoTableField title='Ruolo' value={data?.roles_label}/>
                        </InfoCard>
                    }
                    right={
                        <>
                        </>

                    }
                />
            </LoadingSpinner>
        </>
    )
}

export default UserShow;