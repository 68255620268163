import {useNavigate} from "react-router-dom";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";
import {useRequest} from "ahooks";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {accountServices} from "../../../../services/AccountService";
import {Select} from "../../../../components/UI/Forms/Select";
import {SubmitButton} from "../../../../components/UI/Forms/SubmitButton";
import {SlideOverFormGroup} from "../../../../components/UI/Forms/SlideOverFormGroup";
import SlideOverFormInput from "../../../Packages/components/SlideOverFormInput";


export const AccountForm = ({account, model, siteId, modelUuid, isDisabled, onCreate, onUpdate}) => {
    const navigation = useNavigate();
    const {flashMsg} = useFlashMessage();
    if (!model || !modelUuid) {
        navigation(-1)
    }


    const {data: types = []} = useRequest(accountServices.accountTypeOptions);

    const initialValues = {
        type: account?.type?.value ?? '',
        username: account?.username ?? '',
        password: account?.password ?? '',
        note: account?.note ?? '',
    }

    const validationSchema = Yup.object().shape({
        type: Yup.string().nullable().required(),
        username: Yup.string().nullable().required(),
        password: account ? Yup.string().nullable() : Yup.string().nullable().required(),
        note: Yup.string().nullable(),
    })

    const createAccount = (data, setSubmitting) => {
        accountServices.createAccount(model, modelUuid, data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Account Creato', undefined, myuuid, true)
                onCreate(res, modelUuid, siteId);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    const updateAccount = (data, setSubmitting) => {
        accountServices.updateAccount(model, modelUuid, account?.uuid, data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Account Modificato', undefined, myuuid, true)
                onUpdate(res, modelUuid, siteId);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            type: values.type,
            username: values.username,
            password: values.password,
            note: values.note
        }

        account ? updateAccount(data, setSubmitting) : createAccount(data, setSubmitting)
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });

    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <div className="sm:col-span-3">
                        <SlideOverFormGroup required label='Username' name='username'>
                            <Field as={SlideOverFormInput} required label='username'
                                   name='username' value={formik.values.username} isDisabled={isDisabled}
                            />
                        </SlideOverFormGroup>
                        <SlideOverFormGroup label={account ? 'Modifica Password' : 'Password'} name='password' required={account ? false : true}>
                            <Field as={SlideOverFormInput} label='password' type='password'
                                   name='password' value={formik.values.password} isDisabled={isDisabled}
                            />
                        </SlideOverFormGroup>
                        <SlideOverFormGroup required label='Tipo' name='type'>
                            <Field as={Select} value={formik.values.type} name="type" isDisabled={isDisabled}>
                                <>
                                    <option className='text-center' value=''>--SELEZIONA TIPO---</option>
                                    {types?.map(item => (
                                        <option key={item.value}
                                                value={item.value}>{item.label}</option>
                                    ))}
                                </>


                            </Field>
                        </SlideOverFormGroup>
                        <SlideOverFormGroup label='Note' name='note'>
                            <Field as={SlideOverFormInput} label='note' type='textarea'
                                   name='note' value={formik.values.note}
                                   onChange={event => formik.setFieldValue('note', event.target.value)}
                                   isDisabled={isDisabled}
                            />
                        </SlideOverFormGroup>

                    </div>
                    {!isDisabled ? <div className="mt-6 flex items-center justify-end gap-x-6">
                        <SubmitButton label={account ? 'Salva' : 'Inserisci'}/>
                    </div> : <></>}
                </Form>
            </FormikProvider>
        </>
    )
}