import React from "react";
import {useFormikContext} from "formik";

export const SlideOverFormInput = ({
                                       label,
                                       name,
                                       type = "text",
                                       autoComplete = undefined,
                                       value,
                                       onChange,
                                       isDisabled = false,
                                       error = undefined
                                   }) => {
    const {setFieldValue} = useFormikContext()
    return (
        <>
            {/*<div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                    <label
                        htmlFor="project-description"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                        {'ciao'}
                    </label>
                </div>*/}

            <div className="sm:col-span-2">
                {type !== 'textarea'
                    ?
                    <input
                        type={type}
                        name={name}
                        id={name}
                        value={value}
                        disabled={isDisabled}
                        onChange={event => setFieldValue(name, event.target.value)}
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                    :
                    <textarea
                        id={name}
                        name={name}
                        rows={3}
                        value={value}
                        onChange={onChange}
                        disabled={isDisabled}
                        className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                }

            </div>
            {/*  </div>*/}


            {error ? <p className="mt-2 text-sm text-red-600">{error}</p> : <></>}
        </>
    )
}

export default SlideOverFormInput;