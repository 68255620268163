import TableLink from "../../Buttons/TableLink";

const ProductsTableConfig = [{
    Header: 'Cod.',
    accessor: 'code',
    Cell: ({ row, value }) =>
        <div className='text-green-600 mt-2 text-sm'>
            <TableLink to={`/products/${row.original.uuid}`} value={value}/>
        </div>

},{
    Header: 'Descrizione',
    accessor: 'description',
    Cell: ({ row, value }) => <div className='mt-2 text-gray-500 text-sm'>
        {row.original.description}
    </div>
},{
    Header: 'Unità di Misura',
    accessor: 'um.label',
    Cell: ({ row, value }) => <div className='mt-2 text-gray-500 text-sm'>
        {value}
    </div>
}
]

export default ProductsTableConfig;