import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {useNavigate, useParams} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import serverServices from "../../../services/ServerService";
import {FormGroup} from "../../../components/UI/Forms/FormGroup";
import CancelButton from "../../../components/UI/Buttons/CancelButton";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import {Select} from "../../../components/UI/Forms/Select";
import {useRequest} from "ahooks";
import {FormInput} from "../../../components/UI/Fields/FormInput";
import customerService from "../../../services/CustomerService";

const setIsPleskState = (state) => {
    if (state) {
        return '1'
    } else {
        return '0'
    }
}
export const ServerForm = ({server = undefined}) => {
    const {flashMsg} = useFlashMessage();
    const {uuid} = useParams();
    const navigation = useNavigate();

    const {data: types = []} = useRequest(serverServices.serverTypeOptions);
    const {data: companies = []} = useRequest(customerService.companiesOptions, {
        defaultParams: [{
            filter:
                {is_provider: 1}
        }]
    });

    const createServer = (data, setSubmitting) => {
        serverServices.createServer(data)
            .then(res => {
                flashMsg('Server Creato', null, uuidv4(), true);
                navigation(-1);
            })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
            .finally(() => setSubmitting(false))
    }

    const updateServer = (data, setSubmitting) => {
        serverServices.updateServer(uuid, data)
            .then(res => {
                flashMsg('Server Modificato Con Successo', null, uuidv4(), true);
                navigation(-1);
            })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
            .finally(() => setSubmitting(false))
    }

    const initialValues = {
        name: server?.name ?? '',
        type: server?.type?.value ?? '',
        is_plesk: setIsPleskState(server?.is_plesk),
        ip_address: server?.ip_address ?? '',
        private_address: server?.private_address ?? '',
        login_address: server?.login_address ?? '',
        provider_id: server?.provider?.id ?? '',
        note: server?.note ?? ''
    }


    const handleSubmit = (values, {setSubmitting}) => {
        const data = {
            name: values.name,
            type: values.type,
            is_plesk: values.is_plesk,
            ip_address: values.ip_address,
            private_address: values.private_address,
            login_address: values.login_address,
            provider_id: values.provider_id,
            note: values.note
        }
        server ? updateServer(data, setSubmitting) : createServer(data, setSubmitting)
    }


    const validationSchema = Yup.object().shape({
        name: Yup.string().nullable().required(),
        type: Yup.string().nullable().required(),
        is_plesk: Yup.boolean().nullable().required(),
        ip_address: Yup.string().matches(
            /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            'Please insert a valid ip address'
        ),
        private_address: Yup.string().matches(
            /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            'Please insert a valid ip address'
        ),
        login_address: Yup.string().required(),
        provider_id: Yup.string().nullable().required(),
    });


    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });
    return (
        <FormikProvider value={formik}>
            <Form>
                <div className="space-y-12">
                    <div
                        className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div className='flex flex-col ms-4'>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">{server ? "Modifica Server" : 'Inserisci Nuovo Server'}</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">{server ? 'Modifica' : 'Inserisci'} le
                                informazioni del server</p>
                        </div>

                        <div
                            className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                            <div className="sm:col-span-3">
                                <FormGroup required={true} label='Nome' name='name'>
                                    <div className="mt-2">
                                        <Field as={FormInput} required label='name'
                                               name='name' value={formik.values.name}/>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="sm:col-span-3">
                                <FormGroup required={true} label='Provider' name='provider_id'>
                                    <div className="mt-2">
                                        <Field as={Select} value={formik.values.provider_id} name='provider_id'>
                                            {companies ?
                                                <>
                                                    <option className='text-center' value=''>--SELEZIONA PROVIDER---</option>
                                                    {companies?.map(item => (
                                                        <option key={item.value}
                                                                value={item.value}>{item.label}</option>
                                                    ))}
                                                </>

                                                : <option>Loading</option>
                                            }
                                        </Field>
                                    </div>
                                </FormGroup>
                            </div>


                            <div className="sm:col-span-3">
                                <FormGroup required={true} label='Indirizzo di Login' name='login_address'>
                                    <div className="mt-2">
                                        <Field as={FormInput} required label='login_address'
                                               name='login_address' value={formik.values.login_address}/>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="sm:col-span-3">
                                <FormGroup label='Indirizzo IP' name='ip_address'>
                                    <div className="mt-2">
                                        <Field as={FormInput} required={true} label='ip_address'
                                               name='ip_address' value={formik.values.ip_address}/>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="sm:col-span-3">
                                <FormGroup label='Indirizzo Privato' name='private_address'>
                                    <div className="mt-2">
                                        <Field as={FormInput} required={true} label='private_address'
                                               name='private_address' value={formik.values.private_address}/>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="sm:col-span-3">
                                <FormGroup required={true} label='Tipo' name='type'>
                                    <div className="mt-2">
                                        <Field as={Select} value={formik.values.type} name='type'>
                                            {types ?
                                                <>
                                                    <option className='text-center' value=''>--SELEZIONA TIPO---</option>
                                                    {types?.map(item => (
                                                        <option key={item.value}
                                                                value={item.value}>{item.label}</option>
                                                    ))}
                                                </>

                                                : <option>Loading</option>
                                            }
                                        </Field>
                                    </div>
                                </FormGroup>
                            </div>


                            <div className="sm:col-span-3">
                                <FormGroup required label='Plesk' name='is_plesk' className=''>
                                    <div className="mt-3" role='group'>
                                        <div
                                            className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                            <div key='1' className="flex items-center">
                                                <Field type="radio" name="is_plesk" value='1'
                                                       className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 accent-green-600"/>
                                                <label
                                                    className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                    Sì
                                                </label>
                                            </div>
                                            <div key='0' className="flex items-center">
                                                <Field type="radio" name="is_plesk" value='0'
                                                       className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 accent-green-600"/>
                                                <label
                                                    className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-span-full">
                                <FormGroup label='Note' name='note'>
                                    <div className="mt-2">
                                        <Field as={FormInput} type='textarea' label='note'
                                               name='note' value={formik.values.note}/>
                                    </div>
                                </FormGroup>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <CancelButton/>
                    <SubmitButton label={server ? 'Salva' : 'Inserisci'}/>
                </div>
            </Form>
        </FormikProvider>
    )
}

export default ServerForm;