import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'

export default function PopOver({title, children}) {
    return (
            <Popover className="relative">
                {({ open }) => (
                    <>
                        <Popover.Button
                            className={`
                ${open ? 'text-green-600' : ''}
                group inline-flex items-center px-3 py-2 hover:text-green-600 focus:outline-none`}
                        >
                            <span>{title}</span>
                            <ChevronDownIcon
                                className={`${open ? 'text-green-600' : ''}
                  ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-green-600/80`}
                                aria-hidden="true"
                            />
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="fixed w-max left-3/4 z-10 -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                    <div className="grid gap-8 bg-white p-7">
                                        {children}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
    )
}