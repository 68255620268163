import {Outlet} from "react-router-dom";
import PermissionChecker from "../Theme/PermissionChecker";

export const CompaniesWrapper = ({permissions}) => {
    return (
        <PermissionChecker permissions={permissions} navigateTo={'/404'}>
            <Outlet/>
        </PermissionChecker>
    )
}
export default CompaniesWrapper