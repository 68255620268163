import React from "react";

export const SlideOverNote=({note})=>{
    return (
        <>
            <div>
                <h3 className="font-medium text-gray-900">Note</h3>
                <div className="mt-2 flex items-center justify-between">
                    <p className="text-sm italic text-gray-500">{note}</p>
                </div>
            </div>
        </>
    )
}

export default SlideOverNote