import React from "react";
import {PageBreadcrumbLink} from "../../components/Theme/PageHeader/PageBreadcrumbLink";
import {Outlet} from "react-router-dom";
import Roles from "../../pages/Roles/Roles";
import {RoleShow} from "../../pages/Roles/RoleShow";
import NewRole from "../../pages/Roles/NewRole";
import RolesWrapper from "../../components/Wrappers/RolesWrapper";
import PermissionChecker from "../../components/Theme/PermissionChecker";

export const roleRoutes = [
    {
        path: '/roles',
        element: <RolesWrapper/>,
        handle: {
            crumb: () => <PageBreadcrumbLink to="/roles" label="Ruoli"/>
        },
        children: [{
            path: ':uuid',
            element: <><Outlet/></>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Dettagli'/>
            },
            children: [{
                path: '',
                element: <RoleShow/>,
            }]
        }, {
            path: 'create',
            element: <PermissionChecker permissions={['roles:edit']} navigateTo={'/404'}><NewRole/></PermissionChecker>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Crea'/>
            }
        }, {
            path: '',
            element: <><Roles/></>,
        }]
    },
];