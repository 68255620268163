import {PlusCircleIcon} from "@heroicons/react/24/outline";
import React from "react";
import {PermissionChecker} from "./Theme/PermissionChecker";
import VerticalMenu from "./UI/VerticalMenu";

export const TableCardHeader = ({title, action = undefined}) => {
    return (
        <>
            <div className='flex justify-between min-w-full'>
                <h2 className='font-bold text-left'>{title}</h2>
                <div className='flex items-center text-right gap-x-1'>
                    {action ?
                        <PermissionChecker permissions={action?.permissions}>
                            <button
                                onClick={action?.action}
                                className="inline-flex items-center gap-x-2 rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                <PlusCircleIcon className="-ml-0.5 h-6 w-6" aria-hidden="true"/>
                            </button>
                            {action.vertical_actions ?
                                <VerticalMenu actions={action?.vertical_actions}/> : <></>}
                        </PermissionChecker>
                        : <></>}

                </div>
            </div>
        </>
    )
}

export default TableCardHeader