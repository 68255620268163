const tokenKey = 'dv_token';

const getToken = () => sessionStorage.getItem(tokenKey);
const setToken = (token) => sessionStorage.setItem(tokenKey, token);

const clear = () => sessionStorage.clear();

export const sessionConfig = {
  tokenKey,
  getToken,
  setToken,
  clear,
};
