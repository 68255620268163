import {TagIcon} from "@heroicons/react/24/solid";
import React, {useState} from "react";
import {v4 as uuidv4} from "uuid";
import quoteServices from "../../../services/QuoteService";
import {useParams} from "react-router-dom";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import EuroFormat from "../../../components/UI/EuroFormat";
import {PencilSquareIcon, PlusCircleIcon, TrashIcon} from "@heroicons/react/24/outline";
import {Disclosure} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {InfoNoteField} from "../../../components/UI/Fields/InfoNoteField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGripVertical} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../../components/UI/DeleteModal";
import EditProductSlideOver from "./components/EditProductSlideOver";
import NewProductPriceSlideOver from "./components/NewProductPriceSlideOver";
import PermissionChecker from "../../../components/Theme/PermissionChecker";
import RevisionItemPricesDragNDrop from "./components/RevisionItemPricesDragNDrop";

export const RevisionItem = ({
                                 item,
                                 revisionUuid,
                                 onProductDelete,
                                 onProductUpdate,
                                 onProductPriceUpdate,
                                 onProductPriceDefaultChange,
                                 onProductPriceCreate,
                                 onProductPriceDelete,
                                 permissions
                             }) => {
    const [showModal, setShowModal] = useState(false);
    const [showSlideOver, setShowSlideOver] = useState(false);
    const [showNewPriceSlideOver, setShowNewPriceSlideOver] = useState(false)

    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();

    /*useEffect(() => {
        setCurrentPrices(item?.quote_item_prices)
    }, []);*/

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const updatedPrices = item?.quote_item_prices;
        const [reorderedItem] = item?.quote_item_prices?.splice(result.source.index, 1);
        updatedPrices.splice(result.destination.index, 0, reorderedItem);

        const data = {
            current_prices: updatedPrices.map(item => (item.id))
        };

        quoteServices.quoteItemsSwap(uuid, revisionUuid, item?.uuid, data).then(res => {
            const updatedQuoteItemPrices = [...item.quote_item_prices];

            const temp = updatedQuoteItemPrices[result.source.index];
            updatedQuoteItemPrices[result.source.index] = updatedQuoteItemPrices[result.destination.index];
            updatedQuoteItemPrices[result.destination.index] = temp;

            item = {
                ...item,
                quote_item_prices: updatedQuoteItemPrices,
            }
        })
            .catch(err => {
                let myuuid = uuidv4();
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
            });
    };

    return (
        <>
            <div className='flex w-full'>
                <PermissionChecker permissions={permissions}>
                    <div className='flex justify-center items-center ml-3'>
                        <FontAwesomeIcon icon={faGripVertical} className="-ml-0.5 h-4 w-4" color={'gray'}/>
                        {/*<HandRaisedIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>*/}
                    </div>
                </PermissionChecker>
                <div className='rounded-xl border border-gray-300 my-2 mx-3 w-full'>
                    <Disclosure>
                        {({open}) => (
                            <>
                                <Disclosure.Button className="w-full">
                                    <div className="flex justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                        <div className='flex basis-2/3'>
                                            <TagIcon className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                     aria-hidden="true"/>
                                            <div className="ml-4 flex flex-row gap-2 mr-3">
                                                <span
                                                    className="flex items-center font-normal text-xs">{item?.code}</span>
                                                <span
                                                    className="flex lg:truncate items-center font-medium">{item?.product_description}</span>
                                            </div>
                                        </div>


                                        <div
                                            className='flex justify-between lg:items-center items-end w-44 lg:flex-row flex-col sm:gap-y-2'>
                                        <span
                                            className="font-medium text-right items-center pe-4">
                                            Qt. {item?.qty}
                                        </span>
                                            <div className='flex items-center'>
                                            <span
                                                className="font-medium mr-1 items-end justify-end justify-items-end me-2">
                                            <EuroFormat value={item?.total_price}/>
                                        </span>
                                                <ChevronDownIcon
                                                    className={`${
                                                        open ? 'rotate-180 transform' : ''
                                                    } h-5 w-5 text-gray-500`}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex'>
                                        <div className="ml-4 flex gap-2 mr-3 mb-2">
                                            <span className="font-normal">{item?.description}</span>
                                        </div>
                                    </div>
                                </Disclosure.Button>


                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                    <div className='my-2 mx-3'>
                                        <RevisionItemPricesDragNDrop item={item}
                                                                     onDrag={handleDragEnd}
                                                                     revisionUuid={revisionUuid}
                                                                     onProductPriceUpdate={onProductPriceUpdate}
                                                                     onProductPriceDelete={onProductPriceDelete}
                                                                     onProductPriceDefaultChange={onProductPriceDefaultChange}/>
                                    </div>
                                    {item?.note ? <div className='flex justify-left text-left pb-3'>
                                            {/*<span className="font-normal">{item?.description}</span>*/}
                                            <InfoNoteField value={item?.note}/>
                                        </div>
                                        : <></>}
                                    <PermissionChecker permissions={['quotes:edit']}>
                                        <div className='flex justify-center text-center text-green-600 pb-3'>
                                            <button onClick={e => setShowNewPriceSlideOver(true)}>
                                                <PlusCircleIcon className='h-6 w-6'/>
                                            </button>
                                        </div>
                                    </PermissionChecker>

                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>

                </div>
                <PermissionChecker permissions={['quotes:edit']}>
                    <div className='flex text-right me-3'>
                        <button
                            onClick={e => setShowSlideOver(true)}
                            className="inline-flex items-center gap-x-2 rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            <PencilSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                        </button>
                        <button
                            onClick={e => setShowModal(true)}
                            className="inline-flex ps-3 items-center gap-x-2 rounded-md text-xs font-semibold text-red-600 hover:text-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                        </button>
                    </div>
                </PermissionChecker>

            </div>
            <DeleteModal title={item?.code}
                         secondUuid={revisionUuid}
                         thirdUuid={item?.uuid}
                         type={'revision_item'}
                         onDelete={e => onProductDelete(revisionUuid, item?.uuid)}
                         onClose={e => setShowModal(false)}
                         state={showModal}/>
            <EditProductSlideOver product={item} revisionUuid={revisionUuid}
                                  onProductUpdate={onProductUpdate}
                                  state={showSlideOver}
                                  onClose={e => setShowSlideOver(false)}/>
            <NewProductPriceSlideOver revisionUuid={revisionUuid}
                                      productUuid={item?.uuid}
                                      onProductPriceCreate={onProductPriceCreate}
                                      state={showNewPriceSlideOver}
                                      onClose={e => setShowNewPriceSlideOver(false)}/>
        </>
    )
}