import * as Yup from "yup";
import {Field, Form, FormikProvider, useFormik} from "formik";
import _ from "lodash";
import {useRequest} from "ahooks";
import {FormGroup} from "../../../components/UI/Forms/FormGroup";
import {Select} from "../../../components/UI/Forms/Select";
import {FormInput} from "../../../components/UI/Fields/FormInput";
import CancelButton from "../../../components/UI/Buttons/CancelButton";
import {SubmitButton} from "../../../components/UI/Forms/SubmitButton";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";
import {useNavigate, useParams} from "react-router-dom";
import productServices from "../../../services/ProductService";

export default function ProductForm({product = undefined}) {
    const {uuid} = useParams();

    const {data: umTypes = []} = useRequest(productServices.umTypes)

    const navigation = useNavigate();
    const {flashMsg} = useFlashMessage();


    const initialValues = {
        code: product?.code ?? '',
        um: product?.um ?? '',
        description: product?.description ?? '',
        note: product?.note ?? '',
        type: product?.type ?? 'defualt',
    }

    const createProduct = (data, setSubmitting) => {
        productServices.createProduct(data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Prodotto Creato', undefined, myuuid, true)
                navigation(-1);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    const updateProduct = (data, setSubmitting) => {
        productServices.updateProduct(uuid, data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Prodotto Modificato', undefined, myuuid, true)
                navigation(-1);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
                console.log(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }


    const handleSubmit = (values, {setSubmitting}) => {

        const data = {
            code: values.code,
            um: values.um,
            description: values.description,
            note: values.note,
            type: 'default',
        }
            product ? updateProduct(data, setSubmitting) : createProduct(data, setSubmitting)
    }


    const validationSchema = Yup.object().shape({
        code: Yup.string().nullable().required(),
        um: Yup.string().nullable().required(),
        description: Yup.string().nullable().required(),
        note: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });


    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <div className="space-y-12">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div className='sm:ms-4'>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">{product ? 'Modifica' : 'Nuovo'} Prodotto</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">{product ? 'Modifica' : 'Inserisci'} le
                                    informazioni del
                                    Prodotto</p>
                            </div>

                            <div
                                className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                                <div className="sm:col-span-3">
                                    <FormGroup required label='Codice' name='code'>
                                        <div className="mt-2">
                                            <Field as={FormInput} required={true} label='code'
                                                   name='code' value={formik.values.code}/>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required={true} label='Unità di Misura' name='um'>
                                        <div className="mt-2">
                                            <Field as={Select} value={formik.values.um} name="um">
                                                {umTypes ?
                                                    <>
                                                        <option className='text-center'>--SELEZIONA UNITà DI MISURA---
                                                        </option>
                                                        {umTypes.map(item => (
                                                            <option key={item.value}
                                                                    value={item.value}>{item.label}</option>
                                                        ))}
                                                    </>

                                                    : <option>Loading</option>
                                                }
                                            </Field>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="col-span-full">
                                    <FormGroup label='Descrizione' name='description'>
                                        <div className="mt-2">
                                            <FormInput
                                                name='description'
                                                type='textarea'
                                                value={formik.values.description}
                                                onChange={event => formik.setFieldValue('description', event.target.value)}
                                                error={_.get(formik.errors, 'description')}
                                            />
                                        </div>
                                    </FormGroup>
                                </div>


                                <div className="col-span-full">
                                    <FormGroup label='Note' name='note'>
                                        <div className="mt-2">
                                            <FormInput
                                                name='note'
                                                type='textarea'
                                                autoComplete=''
                                                value={formik.values.note}
                                                onChange={event => formik.setFieldValue('note', event.target.value)}
                                                error={_.get(formik.errors, 'note')}
                                            />
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <CancelButton/>
                        <SubmitButton label={product ? 'Salva' : 'Inserisci'}/>
                    </div>
                </Form>
            </FormikProvider>
        </>
    )
}
