import briefcaseIcon from '@heroicons/react/20/solid/BriefcaseIcon';
import currencyEuroIcon from '@heroicons/react/20/solid/CurrencyEuroIcon';
import serverStackIcon from '@heroicons/react/20/solid/esm/ServerStackIcon';
import rectangleStackIcon from '@heroicons/react/20/solid/esm/RectangleStackIcon';
import wrenchScrewdriverIcon from '@heroicons/react/20/solid/esm/WrenchScrewdriverIcon';
import tagIcon from "@heroicons/react/20/solid/esm/TagIcon";
import documentTextIcon from "@heroicons/react/20/solid/esm/DocumentTextIcon";
import banknotesIcon from "@heroicons/react/20/solid/esm/BanknotesIcon";
import userGroupIcon from "@heroicons/react/20/solid/esm/UserGroupIcon";
import adjustmentsVerticalIcon from "@heroicons/react/20/solid/esm/AdjustmentsVerticalIcon";
import identificationIcon from "@heroicons/react/20/solid/esm/IdentificationIcon";

export const navigation = [
    {
        name: 'Amministrazione',
        action: '#',
        children: [{
            name: 'Clienti',
            description: 'Anagrafica e gestione report',
            icon: briefcaseIcon,
            action: '/companies'
        }, {
            name: 'Banche',
            description: 'Anagrafica e gestione conti correnti',
            action: '/banks',
            icon: banknotesIcon,
        }, {
            name: 'Fatturazione',
            description: 'Bills',
            icon: currencyEuroIcon,
            action: '/offers'
        }]
    },
    {
        name: 'Gestione',
        action: '#',
        children: [{
            name: 'Servizi',
            description: 'Storage, server, mail, etc.',
            icon: serverStackIcon,
            action: '/services'
        }, {
            name: 'Pacchetti Ore',
            description: 'Gestione pacchetti ore e inserimento attività',
            action: '/packages',
            icon: rectangleStackIcon,
        }, {
            name: 'Progetti',
            description: 'Anagrafica progetti e reports',
            action: '/projects',
            icon: wrenchScrewdriverIcon,
        }, {
            name: 'Server',
            description: 'Crea e Gestisci i Server',
            icon: adjustmentsVerticalIcon,
            action: '/servers'
        }]
    },
    {
        name: 'Vendite',
        action: '#',
        children: [{
            name: 'Prodotti',
            description: 'Prodotti',
            icon: tagIcon,
            action: '/products'
        },
            {
                name: 'Preventivi',
                description: 'Preventivi e Revisioni',
                icon: documentTextIcon,
                action: '/quotes'
            }]
    },
    {
        name: 'Configurazioni',
        action: '#',
        children: [{
            name: 'Utenti',
            description: 'Crea e Gestisci gli Utenti',
            icon: identificationIcon,
            action: '/users'
        },
            {
                name: 'Ruoli',
                description: 'Crea e Gestisci i Ruoli',
                icon: userGroupIcon,
                action: '/roles'
            }
        ]
    },
]
