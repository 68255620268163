import {useRequest} from "ahooks";
import serverServices from "../../services/ServerService";
import {createButtonConfig} from "../../components/UI/Configs/createButtonConfig";
import React, {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import tableOptions from "../../configs/tableOptions";
import PermissionChecker from "../../components/Theme/PermissionChecker";
import ServersTableConfig from "../../components/UI/Configs/Tables/ServersTableConfig";

export const Servers = () => {
    const navigation = useNavigate();
    const actions = [{
        ...createButtonConfig,
        action: (e => navigation('create')),
        //permissions: ['servers:edit']
    }]
    const columns = useMemo(() => ServersTableConfig, []);
    const {data = [], loading, run} = useRequest(serverServices.servers, {manual: false});
    return (
        <>
            <PermissionChecker permissions={['companies:view']}>
                <SectionHeader
                    title={'Server'}
                    subtitle={'Elenco di tutti i server registrati'}
                    actions={actions}/>
                <div
                    className="relative overflow-hidden rounded-lg bg-white pb-6  shadow sm:px-6 pt-6 text-center items-center justify-center">
                    <PaginatedTable
                        data={data}
                        columns={columns}
                        onParamsChange={run}
                        options={tableOptions}
                        isLoading={loading}
                    />
                </div>
            </PermissionChecker>
        </>
    )
}

export default Servers;