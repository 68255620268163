export const mergeArrays = (array1, array2) => {
    const idSet = new Set();
    const mergedArray = array1?.concat(array2).filter((item) => {
        if (idSet.has(item?.uuid || item?.id)) {
            return false;
        }
        idSet.add(item?.uuid || item?.id);

        const isInArray2 = array2?.some((i) => i.id || item?.uuid === item?.id || item?.uuid);
        if (!isInArray2) {
            return false;
        }
        return true;
    });
    return mergedArray;
};