import {TrashIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import DeleteModal from "../../../components/UI/DeleteModal";
import TableCardHeader from "../../../components/TableCardHeader";
import PermissionChecker from "../../../components/Theme/PermissionChecker";
import BillsTableItem from "./BillsTableItem";

export default function BillsTable({offer, onBillUpdated, onBillDelete, action, permissions}) {
    const {flashMsg} = useFlashMessage()
    const [showModal, setShowModal] = useState(false);
    const [billUuid, setBillUuid] = useState('');
    const [daFatturare, setDaFatturare] = useState(0)

    const getDaFatturare = (offer) => {
        let somma = 0;

        offer?.bills?.forEach(bill => {
            somma += bill?.amount
        });

        return Math.abs(offer?.accepted_price - somma);
    };

    useEffect(() => {
        setDaFatturare(getDaFatturare(offer))
    }, [offer]);
    const deleteHandler = (value) => {
        setBillUuid(value);
        setShowModal(true);
    }

    const handleUpdateBill = res => {
        flashMsg('Campo Aggiornato', null, res.uuid, true);
        onBillUpdated(res)
    }


    return (
        <>
            <TableCardHeader title={'Bills'} action={action}/>
            <div className="px-0">
                <div className="flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    {/*{billStatesEnums.map(state=>(
                                    <th scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3">
                                        {state.label}
                                    </th>
                                ))}*/}
                                    <th scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3">
                                        Mese Corrrente
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                                        Scalata da Anticipi
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                                        Mese Prossimo
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                                        Fatt. Creata
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                                        Fatt. Inviata
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                                        Fatt. Pagata
                                    </th>
                                    <PermissionChecker permissions={permissions}>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                            <span className="sr-only">Delete</span>
                                        </th>
                                    </PermissionChecker>
                                </tr>
                                </thead>
                                <tbody className="bg-white">
                                {offer?.bills?.map(bill => (
                                    <tr key={bill?.uuid} className="even:bg-gray-100">
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-3">
                                            {/*{bill?.state?.value=='current_month' ? bill?.amount : '---' }*/}
                                            {/*{bill?.states?.state?.value == 'current_month' ?
                                                <PopOver title={bill?.amount}><BillForm bill={bill}
                                                                                        onBillUpdated={handleUpdateBill}/></PopOver> : '---'}*/}
                                            <BillsTableItem bill={bill} state={'current_month'} onBillUpdate={handleUpdateBill} remainingAmount={daFatturare}/>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                                            <BillsTableItem bill={bill} state={'down_payment'} onBillUpdate={handleUpdateBill} remainingAmount={daFatturare}/>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                                            <BillsTableItem bill={bill} state={'next_month'} onBillUpdate={handleUpdateBill} remainingAmount={daFatturare}/>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                                            <BillsTableItem bill={bill} state={'invoice_created'} onBillUpdate={handleUpdateBill} remainingAmount={daFatturare}/>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                                            <BillsTableItem bill={bill} state={'invoice_sent'} onBillUpdate={handleUpdateBill} remainingAmount={daFatturare}/>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                                            {bill?.states?.state?.value === 'invoice_paid' ?
                                                bill?.amount : '---'}
                                        </td>
                                        <PermissionChecker permissions={permissions}>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                                <button
                                                    onClick={() => deleteHandler(bill?.uuid)}
                                                    className="inline-flex ps-3 items-center gap-x-2 rounded-md text-xs font-semibold text-red-600 hover:text-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                                >
                                                    <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                                </button>
                                            </td>
                                        </PermissionChecker>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <DeleteModal title={'questa riga'} onClose={() => setShowModal(false)}
                         onDelete={() => onBillDelete(billUuid)} state={showModal}/>

        </>


    )
}
