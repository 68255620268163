import {useSanctum} from "react-sanctum";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {RevisionItem} from "../RevisionItem";
import React from "react";

export const RevisionItemsDragNDrop = ({
                                           items,
                                           onDrag,
                                           revisionUuid,
                                           onProductDelete,
                                           onProductUpdate,
                                           onProductPriceUpdate,
                                           onProductPriceCreate,
                                           onProductPriceDelete,
                                           onProductPriceDefaultChange,

                                       }) => {
    const {user} = useSanctum();

    if (user.permissions.some(permission => ['quotes:edit']?.includes(permission))) {
        return (
            <>
                <DragDropContext onDragEnd={onDrag}>
                    <Droppable droppableId="priceItems">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {items?.map((item, index) => (
                                    <Draggable key={item?.id} draggableId={item?.id?.toString()} index={index}>
                                        {(provided) => (
                                            <div className='mb-1'
                                                 ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 {...provided.dragHandleProps}
                                            >
                                                <RevisionItem item={item}
                                                              revisionUuid={revisionUuid}
                                                              onProductDelete={onProductDelete}
                                                              onProductUpdate={onProductUpdate}
                                                              onProductPriceUpdate={onProductPriceUpdate}
                                                              onProductPriceCreate={onProductPriceCreate}
                                                              onProductPriceDelete={onProductPriceDelete}
                                                              onProductPriceDefaultChange={onProductPriceDefaultChange}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </>
        )
    } else {

        return <>
            {items?.map((item) => (
                <div className='mb-1'>
                    <RevisionItem item={item}
                                  revisionUuid={revisionUuid}
                                  onProductDelete={onProductDelete}
                                  onProductUpdate={onProductUpdate}
                                  onProductPriceUpdate={onProductPriceUpdate}
                                  onProductPriceCreate={onProductPriceCreate}
                                  onProductPriceDelete={onProductPriceDelete}
                                  onProductPriceDefaultChange={onProductPriceDefaultChange}
                                  permissions={['quotes:edit']}
                    />
                </div>
            ))}</>
    }

};

export default RevisionItemsDragNDrop;