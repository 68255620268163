import {Link} from "react-router-dom";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import React, {useState} from "react";
import DeleteModal from "../DeleteModal";
import PermissionChecker from "../../Theme/PermissionChecker";


const ActionButtons = ({title, onDelete, canDelete = true, permissions}) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <div className='text-right'>
                <PermissionChecker permissions={permissions}>
                    <Link
                        to={`edit`}
                        className="inline-flex items-center gap-x-2 rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                        <PencilSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                    </Link>
                </PermissionChecker>


                {canDelete &&
                    <PermissionChecker permissions={permissions}>
                        <button
                            //onClick={event => deleteApi.then(console.log('delete'), navigation(-1)) }
                            onClick={() => setShowModal(true)}
                            className="inline-flex ps-3 items-center gap-x-2 rounded-md text-xs font-semibold text-red-600 hover:text-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                        </button>
                    </PermissionChecker>
                }
            </div>
            <DeleteModal title={title} onClose={() => setShowModal(false)} onDelete={onDelete} state={showModal}/>
        </>
    )
}

export default ActionButtons;