import InfoCardGroup from "../../../../components/UI/Fields/InfoCardGroup";
import InfoCardField from "../../../../components/UI/Fields/InfoCardField";

export const StorageInfo = ({storage}) => {
    return (
        <InfoCardGroup>
            <InfoCardField title={'Provider'} value={storage?.provider}/>
            {storage?.note ? <InfoCardField title={'Note'} value={storage?.note}/> : <></>}
        </InfoCardGroup>
    )
}

export default StorageInfo;