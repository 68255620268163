import ProductForm from "./components/ProductForm";
import {useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import productServices from "../../services/ProductService";
import LoadingSpinner from "../../components/UI/LoadingSpinner";

export const EditProduct = () => {
    const {uuid} = useParams();
    const {data = [], loading} = useRequest(productServices.productShow, {
        defaultParams: [uuid]
    })
    return (
            <LoadingSpinner loading={loading}>
                <ProductForm product={data}/>
            </LoadingSpinner>
    )
}

export default EditProduct;