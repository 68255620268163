import React from "react";
import SlideOver from "../../../components/UI/SlideOvers/SlideOver";
import BillForm from "./BillForm";

export const NewBillSlideOver = ({offer, state, onClose, onBillCreated}) => {

    const handleCreateBill = res => {
        onBillCreated(offer?.uuid, res);
    }

    const getDaFatturare = (offer) => {
        let somma = 0;

        offer?.bills?.forEach(bill => {
            somma += bill.amount
        });

        return Math.abs(offer?.accepted_price - somma);
    };

    return (
        <SlideOver title={'Nuova Bill'}
                   subtitle={`${offer?.full_number} - ${offer?.description}`}
                   state={state}
                   size={'max-w-md'}
                   onClose={onClose}>
            <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <BillForm offerUuid={offer?.uuid} onBillCreated={handleCreateBill} remainingAmount={getDaFatturare(offer)}/>
            </div>
        </SlideOver>
    )
}

export default NewBillSlideOver;