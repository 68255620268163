import {Fragment, useState} from 'react'
import {useSanctum} from "react-sanctum";
import Card from "../../../components/UI/Cards/Card";
import ResetPasswordForm from "./components/ResetPasswordForm";
import UpdateUserInfoForm from "./components/UpdateUserInfoForm";
import SectionContent from "../../../components/Theme/PageSections/SectionContent";
import ProfileHeader from "./components/ProfileHeader";
import {ExpensesTable} from "../Expenses/ExpensesTable";
import {VehiclesTable} from "../Vehicles/VehiclesTable";


export default function Profile() {
    const {user} = useSanctum();
    const [displayUser, setDisplayUser] = useState(user);

    const handleUpdateUser = (res) => {
        setDisplayUser(res);
    }

    return (
        <>

            <ProfileHeader user={displayUser}/>

            <SectionContent
                left={
                    <>
                        <div className='flex justify-center flex-col w-full gap-x-4 gap-y-4 mt-6'>
                            <Card>
                                <h2 className='className="text-base font-semibold leading-7 text-gray-900 mb-4'>Modifica Informazioni</h2>
                                <UpdateUserInfoForm user={displayUser} onUpdate={handleUpdateUser}/>
                            </Card>
                            <Card>
                                <h2 className='className="text-base font-semibold leading-7 text-gray-900 mb-4'>Modifica Password</h2>
                                <ResetPasswordForm/>
                            </Card>
                        </div>
                    </>
                }
                right={
                    <div className='flex flex-col mt-6 gap-y-4'>
                        <Card>
                            <ExpensesTable/>
                        </Card>
                        <Card>
                            <VehiclesTable/>
                        </Card>
                    </div>
                }
            />

        </>
    )
}