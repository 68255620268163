import {useSanctum} from "react-sanctum";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import React from "react";
import {RevisionPriceItem} from "../RevisionPriceItem";

export const RevisionItemPricesDragNDrop = ({
                                                item,
                                                onDrag,
                                                revisionUuid,
                                                onProductPriceUpdate,
                                                onProductPriceDelete,
                                                onProductPriceDefaultChange,

                                            }) => {
    const {user} = useSanctum();

    if (user.permissions.some(permission => ['quotes:edit']?.includes(permission))) {
        return (
            <>
                <DragDropContext onDragEnd={onDrag}>
                    <Droppable droppableId="priceItems">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {item?.quote_item_prices?.map((price, index) => (
                                    <Draggable key={price.id} draggableId={price.id.toString()}
                                               index={index}>
                                        {(provided) => (
                                            <div className='mb-4'
                                                 ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 {...provided.dragHandleProps}
                                            >
                                                <RevisionPriceItem price={price}
                                                                   revisionUuid={revisionUuid}
                                                                   productUuid={item?.uuid}
                                                                   onProductPriceUpdate={onProductPriceUpdate}
                                                                   onProductPriceDelete={onProductPriceDelete}
                                                                   onProductPriceDefaultChange={onProductPriceDefaultChange}
                                                                   permissions={['quotes:edit']}/>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </>
        )
    } else {

        return <>
            {item?.quote_item_prices?.map((price, index) => (
                <div className='mb-4'>
                    <RevisionPriceItem price={price}
                                       is_default={price?.is_checked}
                        /*revisionUuid={revisionUuid}
                        productUuid={item?.uuid}
                        onProductPriceUpdate={onProductPriceUpdate}
                        onProductPriceDelete={onProductPriceDelete}
                        onProductPriceDefaultChange={onProductPriceDefaultChange}*/
                                       permissions={['quotes:edit']}/>
                </div>
            ))}
        </>
    }

};

export default RevisionItemPricesDragNDrop;