import React from "react";
import {PageBreadcrumbLink} from "../../components/Theme/PageHeader/PageBreadcrumbLink";
import {Outlet} from "react-router-dom";
import {ServersWrapper} from "../../components/Wrappers/ServersWrapper";
import Servers from "../../pages/Servers/Servers";
import NewServer from "../../pages/Servers/NewServer";
import {ServerShow} from "../../pages/Servers/ServerShow";
import {EditServer} from "../../pages/Servers/EditServer";

export const serverRoutes = [
    {
        path: '/servers',
        element: <ServersWrapper/>,
        handle: {
            crumb: () => <PageBreadcrumbLink to="/servers" label="Servers"/>
        },
        children: [{
            path: ':uuid',
            element: <><Outlet/></>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Dettagli'/>
            },
            children: [{
                path: 'edit',
                //element: <PermissionChecker permissions={['server:edit']} navigateTo={'/404'}><EditServer/></PermissionChecker>,
                element: <EditServer/>,
                handle: {
                    crumb: () => <PageBreadcrumbLink label='Modifica'/>
                }
            }, {
                path: '',
                element: <ServerShow/>,
            }]
        }, {
            path: 'create',
            element: <NewServer/>,
            //element: <PermissionChecker permissions={['servers:edit']} navigateTo={'/404'}><NewServer/></PermissionChecker>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Crea'/>
            }
        }, {
            path: '',
            element: <><Servers/></>,
        }]
    },
];