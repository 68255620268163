import {Outlet} from "react-router-dom";
import PermissionChecker from "../Theme/PermissionChecker";

export const OffersWrapper = () => {
    return (
        <PermissionChecker permissions={['offers:view']} navigateTo={'/404'}>
            <Outlet/>
        </PermissionChecker>
    )
}

export default OffersWrapper;