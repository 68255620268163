import * as Yup from "yup";
import {Form, FormikProvider, useFormik} from "formik";
import {FormGroup} from '../../../components/UI/Forms/FormGroup';
import {SubmitButton} from '../../../components/UI/Forms/SubmitButton';
import CancelButton from "../../../components/UI/Buttons/CancelButton";
import {useNavigate, useParams} from "react-router-dom";
import useFlashMessage from "../../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from 'uuid';
import userServices from "../../../services/UserService";
import {FormInput} from "../../../components/UI/Fields/FormInput";
import RolesSelect from "./RolesSelect";


export default function UserForm({user = undefined}) {
    const {flashMsg} = useFlashMessage();
    const {uuid} = useParams();
    const navigation = useNavigate();

    const createUser = (data, setSubmitting) => {
        userServices.createUser(data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Utente Creato', null, myuuid, true);
                navigation(-1);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const updateUser = (data, setSubmitting) => {
        userServices.updateUser(uuid, data)
            .then(res => {
                let myuuid = uuidv4();
                setSubmitting(false);
                flashMsg('Utente Modificato Con Successo', null, myuuid, true);
                navigation(-1);
            })
            .catch(err => {
                let myuuid = uuidv4();
                setSubmitting(false);
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
            .finally(() => {
                setSubmitting(false);
            })
    }

    const initialValues = {
        first_name: user?.first_name ?? '',
        last_name: user?.last_name ?? '',
        email: user?.email ?? '',
        //password: user?.password ?? '',
        roles: user?.roles ?? '',
    }


    const handleSubmit = (values, {setSubmitting}) => {
        const data = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            //password: values.password,
            roles: values.roles?.map(role => role?.value || role?.id),
        }
        user ? updateUser(data, setSubmitting) : createUser(data, setSubmitting)
    }


    const validationSchema = Yup.object().shape({
        first_name: Yup.string().nullable().required(),
        last_name: Yup.string().nullable().required(),
        email: Yup.string().email('Please enter a valid email').nullable().required(),
        //password: Yup.string().nullable().required(),
        roles: Yup.array().required()
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit
    });


    return (
        <>
            {/*<div className='mx-auto items-center justify-center max-w-7xl px-4'>*/}
            <FormikProvider value={formik}>
                <Form>
                    <div className="space-y-12">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div className='flex flex-col ms-4'>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">{user ? "Modifica Utente" : 'Inserisci Nuovo Utente'}</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">{user ? 'Modifica' : 'Inserisci'} le
                                    informazioni dell'utente</p>
                                <div className="flex items-center justify-center mt-6">
                                    <img
                                        className="h-25 w-25 rounded-full"
                                        src={user?.avatar}
                                        alt=""
                                    />
                                </div>

                            </div>

                            <div
                                className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                <div className="sm:col-span-3">
                                    <FormGroup required label='Nome' name='first_name'>
                                        <FormInput reuired label='first_name' name='first_name'
                                                   value={formik.values.first_name}/>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required={false} label='Cognome' name='last_name'>
                                        <FormInput required={false} label='last_name' name='last_name'
                                                   value={formik.values.last_name}/>
                                    </FormGroup>
                                </div>

                                <div className="sm:col-span-3">
                                    <FormGroup required label='Email' name='email'>
                                        <FormInput label='email' name='email'
                                                   type='email'
                                                   value={formik.values.email}/>
                                    </FormGroup>
                                </div>

                                {/*<div className="sm:col-span-3">
                                    <FormGroup required label='Password' name='password'>
                                        <FormInput label='password' name='password'
                                                   type='password'
                                                   value={formik.values.password}/>
                                    </FormGroup>
                                </div>*/}

                                <div className="sm:col-span-3">
                                    <FormGroup label='Ruoli' name='roles'>
                                        <div className="mt-2">
                                            <RolesSelect selectedRoles={formik.values.roles} values={formik.values}/>
                                        </div>
                                    </FormGroup>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <CancelButton/>
                        <SubmitButton label={user ? 'Salva' : 'Inserisci'}/>
                    </div>
                </Form>
            </FormikProvider>
        </>
    )
}