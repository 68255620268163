import React, {useEffect, useState} from "react";
import {ArrowTopRightOnSquareIcon, EyeIcon, EyeSlashIcon} from "@heroicons/react/24/outline";
import {useRequest} from "ahooks";
import serverServices from "../../../../services/ServerService";
import InfoPopOver from "../../../../components/UI/InfoPopOver";
import CopyToClipboardButton from "../../../../components/UI/Buttons/CopyToClipboardButton";

export const AccountTableItem = ({modelUuid, account, onClick}) => {
    const [viewPassword, setViewPassword] = useState(false);
    const [accountUuid, setAccountUuid] = useState();
    const [plainPassword, setPlainPassword] = useState();
    const hiddenPassword = '##########';

    const {data = [], run} = useRequest(serverServices.showPassword, {
        manual: true,
        defaultParams: [modelUuid, accountUuid]
    })

    const handleViewPassword = (account) => {
        setAccountUuid(account?.uuid);
        //setViewPassword(!viewPassword);

            !plainPassword &&
            run(modelUuid, account.uuid)

        setViewPassword(!viewPassword);
    }

    function copyPassword() {
        if (plainPassword) {
            return plainPassword
        } else {
            //run(modelUuid, account.uuid)
            return null
        }
    }

    useEffect(() => {
        setPlainPassword();
    }, [account]);

    useEffect(() => {
        setPlainPassword(data?.data?.password)
    }, [data]);

    return (
        <>
            <li key={account.id}
                className="flex flex-col sm:flex-row sm:items-center justify-between gap-x-4 gap-y-6 py-3">
                <div className='flex flex-row basis-1/3'>
                    <div className="basis-3/4 min-w-0">
                        <div className="flex items-start gap-x-3">
                            <p className="text-sm font-semibold leading-6 text-gray-900">{account?.username}</p>
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <div className="whitespace-nowrap">
                                <p>{account?.type?.label}</p>
                            </div>
                        </div>
                    </div>
                        <CopyToClipboardButton textToCopy={account?.username} message={'Username Copiato'}/>
                </div>

                <div className="flex flex-1 items-center justify-items-end gap-x-2">
                    <div className="flex items-center text-sm basis-4/5">
                        <p className='border border-gray-400 rounded-md px-2 py-1 w-44'>{!viewPassword ? hiddenPassword : (plainPassword ? plainPassword : hiddenPassword)}</p>
                        <button onClick={() => handleViewPassword(account)}>
                            {viewPassword ? <EyeSlashIcon className='ml-3 h-5 w-5'/> :
                                <EyeIcon className='ml-3 h-5 w-5'/>}
                        </button>
                        <div className='ml-3'>
                            <CopyToClipboardButton textToCopy={copyPassword()} message={'Password Copiata'}/>
                        </div>


                    </div>
                    <div className='flex items-center'>
                        {account?.note ? <InfoPopOver>
                            {account?.note}
                        </InfoPopOver> : <></>}
                    </div>


                    <div className="flex flex-1 items-end justify-end ml-4 pb-2">
                        <button
                            onClick={onClick}
                            className="rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            <ArrowTopRightOnSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                        </button>

                    </div>
                </div>
            </li>
        </>
    )
}

export default AccountTableItem;