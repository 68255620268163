import React from 'react';

export const AuthCheckbox = ({ name, label, checked, onChange }) => (
  <div className="flex items-center">
    <input
      id={name}
      name={name}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
    />
    <label htmlFor={name} className="ml-3 block text-sm leading-6 text-gray-700">
      {label}
    </label>
  </div>
)
