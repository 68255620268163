import TableLink from "../../Buttons/TableLink";

const RolesTableConfig = [
    {
    Header: 'Ruolo',
    accessor: 'name',
    Cell: ({ row, value }) =>
        <div className='mt-2 text-green-600'>
            <TableLink to={`/roles/${row.original.id}`} value={value}/>
        </div>

},{
        Header: 'N. Permessi',
        accessor: 'permissions_count',
        Cell: ({ row, value }) =><div className='text-gray-500 text-sm'>
            {row.original.permissions_count}
        </div>

    },
]

export default RolesTableConfig;