import TableLink from "../../Buttons/TableLink";
import TrueFalseBadge from "../../TrueFalseBadge";

const CompaniesTableConfig = [{
    Header: 'Ragione Sociale',
    accessor: 'business_name',
    Cell: ({ row, value }) =>
        <div className='mt-2 text-green-600'>
            <TableLink to={`/companies/${row.original.uuid}`} value={value}/>
        </div>

},{
    Header: 'P. IVA',
    accessor: 'vat_number',
    Cell: ({ row, value }) => <div className='text-gray-500 text-sm'>
        {row.original.vat_number}
    </div>
},{
    Header: 'Codice Fiscale',
    accessor: 'fiscal_code',
    Cell: ({ row, value }) => <div className='text-gray-500 text-sm'>
        {row.original.fiscal_code}
    </div>
},
    {
        Header: 'Provider',
        accessor: 'is_provider',
        Cell: ({value }) => <div className='text-center'>
            <TrueFalseBadge value={value}/>
        </div>
    }
]

export default CompaniesTableConfig;