import React from 'react';
import { Disclosure } from '@headlessui/react';
import { MainNavabrMobileProfileMenu } from './MainNavabrMobileProfileMenu';
import { MainNavbarMobileMenuItem } from './MainNavbarMobileMenuItem';

export const MainNavbarMobileMenuContent = ({ navigation, user, userNavigation }) => {

  return (
    <Disclosure.Panel className="md:hidden">
      <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
        {navigation.map((item, index) =>
          item.children && item.children.length > 0
            ? item.children.map((subItem, subIndex) => <MainNavbarMobileMenuItem item={subItem} key={`${index}_${subIndex}`} />)
            : <MainNavbarMobileMenuItem item={item} key={index} />
        )}
      </div>

      <MainNavabrMobileProfileMenu
        user={user}
        userNavigation={userNavigation}
      />
    </Disclosure.Panel>
  )

}
