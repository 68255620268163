import React from 'react';
import {Label} from './Label';
import {useFormikContext} from 'formik';
import _ from 'lodash';

export const FormGroup = ({label, name, required = false, children, className = "relative mt-2 rounded-md shadow-sm"}) => {

    const {errors} = useFormikContext()
    const error = _.get(errors, name)

    return (
        <>
            <Label label={label} name={name} required={required} error={error}/>
            <div className={className}>
                {children}
                {/*{error ?
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center me-6">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
          </div>
          : <></>
        }*/}
            </div>
            {error ? <p className="mt-2 text-sm text-red-600" id="email-error">{error}</p> : <></>}
        </>
    )
}
