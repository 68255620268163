import React from "react";
import {PageBreadcrumbLink} from "../../components/Theme/PageHeader/PageBreadcrumbLink";
import {Outlet} from "react-router-dom";
import {Quotes} from "../../pages/Quotes/Quotes";
import QuoteShow from "../../pages/Quotes/QuoteShow";
import NewQuote from "../../pages/Quotes/NewQuote";
import EditQuote from "../../pages/Quotes/EditQuote";
import QuotesWrapper from "../../components/Wrappers/QuotesWrapper";
import PermissionChecker from "../../components/Theme/PermissionChecker";

export const quoteRoutes = [
    {
        path: '/quotes',
        element: <QuotesWrapper/>,
        handle: {
            crumb: () => <PageBreadcrumbLink to="/quotes" label="Preventivi"/>
        },
        children: [{
            path: ':uuid',
            element: <><Outlet/></>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Dettagli'/>
            },
            children: [{
                path: 'edit',
                element: <PermissionChecker permissions={['quotes:edit']} navigateTo={'/404'}>
                    <EditQuote/></PermissionChecker>,
                handle: {
                    crumb: () => <PageBreadcrumbLink label='Modifica'/>
                }
            }, {
                path: '',
                element: <><QuoteShow/></>,
            }]
        }, {
            path: 'create',
            element: <PermissionChecker permissions={['quotes:create']} navigateTo={'/404'}><NewQuote/></PermissionChecker>,
            handle: {
                crumb: () => <PageBreadcrumbLink label='Crea'/>
            }
        }, {
            path: '',
            element: <><Quotes/></>,
        }]
    },
];