import TableCardHeader from "../../../../components/TableCardHeader";
import PermissionChecker from "../../../../components/Theme/PermissionChecker";
import React from "react";
import ServerTableItem from "./ServerTableItem";

export const ServerTable = ({servers}) => {
    return (
        <>
            {servers?.length > 0 ?
                <><TableCardHeader title={'Server'} /*action={{'permission': ['servers:view']}}*//>
                    <PermissionChecker /*permissions={['servers:view']}*/
                        message={'NON HAI IL PERMESSO DI VISUALIZZARE I SERVER'}>
                        <ul className="divide-y divide-gray-100">
                            {servers?.map((s) => (
                                <ServerTableItem server={s} key={s.id}/>
                            ))}
                        </ul>
                    </PermissionChecker></>
                : <></>
            }
        </>
    )
}

export default ServerTable