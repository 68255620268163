import React, {useEffect, useState} from "react";
import NewElement from "../../../../components/UI/Buttons/NewElement";
import TableCardHeader from "../../../../components/TableCardHeader";
import PermissionChecker from "../../../../components/Theme/PermissionChecker";
import PersonTableField from "./PersonTableField";
import PersonSlideOver from "./PersonSlideOver";
import {contactServices} from "../../../../services/ContactService";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";

export const PeopleTable = ({people, onCreate, onUpdate, onDelete}) => {
    const [showSlideOver, setShowSlideOver] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState();
    const [updatedPeople, setUpdatedPeople] = useState([]);

    useEffect(() => {
        setUpdatedPeople(mergePeopleArrays(updatedPeople, people))
        // eslint-disable-next-line
    }, [people]);

    const mergePeopleArrays = (array1, array2) => {
        const idSet = new Set();

        const mergedArray = array1.concat(array2).filter((person) => {
            if (idSet.has(person.uuid)) {
                return false;
            }
            idSet.add(person.uuid);

            const isInArray2 = array2.some((p) => p.id === person.id);
            if (!isInArray2) {
                return false;
            }
            return true;
        });
        return mergedArray;
    };

    const {flashMsg} = useFlashMessage();

    const addContactHandler = (res, personUuid)=>{
        setUpdatedPeople(updatedPeople.map(p => p.uuid !== personUuid ? p : {...p, contacts: p.contacts ? [...p.contacts, res] : [res]}))
    }

    const updateContactHandler = (res, personUuid)=>{
        setUpdatedPeople(updatedPeople.map(p => p.uuid !== personUuid ? p : {...p, contacts: p.contacts?.map(c=> c.uuid !== res.uuid ? c : res)}))
    }

    const deleteContactHandler = (model, personUuid, contactUuid) => {
        contactServices.deleteContact(model, personUuid, contactUuid)
            .then(res => {
                flashMsg(res.message, null, uuidv4(), true);
                setUpdatedPeople(updatedPeople.map(p => p.uuid !== personUuid ? p : {...p, contacts: p.contacts.filter(c => c.uuid !== contactUuid)}));
            })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false);
            });
    };

    return (
        <>
            {people.length > 0 ? (
                    <>
                        <TableCardHeader title={'Persone'} action={{
                            'action': () => (setSelectedPerson() & setShowSlideOver(true)),
                            'permissions': ['contacts:edit']
                        }}/>
                        <PermissionChecker permissions={['contacts:view']}
                                           message={'NON HAI IL PERMESSO DI VISUALIZZARE LE PERSONE'}>
                            <ul className="divide-y divide-gray-100">
                                {updatedPeople?.map((person) => (
                                    <PersonTableField person={person} key={person.id}
                                                      onCreate={addContactHandler}
                                                      onUpdate={updateContactHandler}
                                                      onDelete={deleteContactHandler}
                                                      onClick={() => (setSelectedPerson(person) & setShowSlideOver(true))}/>
                                ))}
                            </ul>
                        </PermissionChecker>
                    </>
                )
                :
                <PermissionChecker permissions={['contacts:edit']} message={'NON HAI IL PERMESSO DI AGGIUNGERE NUOVI PERSONE'}>
                    <NewElement type={'person'}
                                onClick={() => (setSelectedPerson() & setShowSlideOver(true))}/>
                </PermissionChecker>
            }
            <PersonSlideOver state={showSlideOver} person={selectedPerson}
                             onCreate={onCreate}
                             onUpdate={onUpdate}
                             onDelete={onDelete}
                             onClose={() => setShowSlideOver(false)}/>
        </>
    )
}

export default PeopleTable;
