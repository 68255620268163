import PermissionChecker from "../Theme/PermissionChecker";
import {Outlet} from "react-router-dom";

export const RolesWrapper = ()=>{
    return(
        <PermissionChecker permissions={['roles:view']} navigateTo={'/404'}>
            <Outlet/>
        </PermissionChecker>
    )
}

export default RolesWrapper;