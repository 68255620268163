import React from "react";
import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import SiteForm from "./SiteForm";

export const SiteSlideOver = ({site, serverUuid, onClose, onCreate, state}) => {

    return (
        <SlideOver title={site ? 'Informazioni Sito' : 'Nuovo Sito'}
                   subtitle={site ? 'Modifica le informazioni del sito' : 'Inserisci le informazioni del sito'}
                   state={state}
                   onClose={onClose}>

            <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                <SiteForm serverUuid={serverUuid} onCreate={onCreate}/>
            </div>
        </SlideOver>
    )
}

export default SiteSlideOver;