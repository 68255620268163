import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import RevisionProductPriceForm from "./RevisionProductPriceForm";
import React from "react";

export const NewProductPriceSlideOver = ({state, onClose, revisionUuid, productUuid, onProductPriceCreate}) => {
    return (
        <>
            <SlideOver title={'Nuovo Prezzo'}
                       subtitle={'Inserisci le informazioni del nuovo prezzo per questa revisione'}
                       state={state}
                       size={'max-w-md'}
                       onClose={onClose}>

                <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                    <RevisionProductPriceForm
                        revisionUuid={revisionUuid}
                        productUuid={productUuid}
                        onProductPriceCreate={onProductPriceCreate}
                    />
                </div>
            </SlideOver>
        </>
    )
}

export default NewProductPriceSlideOver