import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

export const AuthInput = ({ name, type = "text", autoComplete = undefined, required, value, onChange, error = undefined }) => (
  <>
    <div className="relative mt-2 rounded-md shadow-sm">
      <input
        id={name}
        name={name}
        type={type}
        autoComplete={autoComplete}
        required={required}
        value={value}
        onChange={onChange}
        className="block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
      {error ?
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
        </div>
        : <></>
      }
    </div>
    {error ? <p className="mt-2 text-sm text-red-600" id="email-error">{error}</p> : <></>}
  </>
)
