import React, {useState} from "react";
import SlideOver from "../../../components/UI/SlideOvers/SlideOver";
import ActivityForm from "./ActivityForm";
import DeleteModal from "../../../components/UI/DeleteModal";
import {useSanctum} from "react-sanctum";
import ActionButtonsSlideOver from "../../../components/UI/Buttons/ActionButtonsSlideOver";

export const ShowActivitySlideOver = ({state, onClose, package_id, project_id, activity, onUpdate, onDelete}) => {
    const {user} = useSanctum();
    const [isDisabled, setIsDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);


    return (
        <>
            <SlideOver title={'Informazioni Attività'}
                       subtitle={'Modifica le informazioni dell\'attività'}
                       state={state}
                       onClose={() => (onClose() & setIsDisabled(true))}>

                <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                    {user?.id === activity?.user?.id ?
                        <ActionButtonsSlideOver editAction={() => setIsDisabled(false)} deleteAction={() => setShowModal(true)}/>
                        :
                        <></>
                    }

                    <ActivityForm package_id={package_id} project_id={project_id} isDisabled={isDisabled}
                                  onUpdate={onUpdate}
                                  activity={activity}/>
                </div>
            </SlideOver>
            <DeleteModal title={'questa attività'} onClose={() => setShowModal(false)}
                         onDelete={() => onDelete(activity.uuid)} state={showModal}/>
        </>
    )
}

export default ShowActivitySlideOver;