import React, {useMemo} from 'react';
import {useRequest} from "ahooks";
import customerService from "../../services/CustomerService";
import CompaniesTableConfig from "../../components/UI/Configs/Tables/CompaniesTableConfig";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import {useNavigate} from "react-router-dom";
import tableOptions from "../../configs/tableOptions";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import {createButtonConfig} from "../../components/UI/Configs/createButtonConfig";
import PermissionChecker from "../../components/Theme/PermissionChecker";
//import companiesTableOption from "../../configs/companiesTableOption";

const Companies = () => {
    const navigation = useNavigate();
    const actions = [{
        ...createButtonConfig,
        action: () => navigation('create'),
        permissions: ['companies:edit']
    }]
    const columns = useMemo(() => CompaniesTableConfig, []);
    const {data = [], loading, run} = useRequest(customerService.companies, {
        manual: true
    });


    return (
        <>
            <PermissionChecker permissions={['companies:view']}>
                <SectionHeader
                    title={'Clienti'}
                    subtitle={'Elenco di tutti i clienti registrati'}
                    actions={actions}/>
                <div
                    className="relative overflow-hidden rounded-lg bg-white pb-6  shadow sm:px-6 pt-6 text-center items-center justify-center">
                    <PaginatedTable
                        data={data}
                        columns={columns}
                        onParamsChange={run}
                        options={tableOptions}
                        isLoading={loading}
                    />
                </div>
            </PermissionChecker>


        </>

    )

}

export default Companies
