import React, {Fragment, useState} from 'react'
import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import ActionButtonsSlideOver from "../../../../components/UI/Buttons/ActionButtonsSlideOver";
import PersonForm from "./PersonForm";
import DeleteModal from "../../../../components/UI/DeleteModal";
import {useParams} from "react-router-dom";

export default function PersonSlideOver({person = undefined, onCreate, onUpdate, onDelete, onClose, state}) {
    const {uuid} = useParams();
    const [isDisabled, setIsDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <SlideOver title={person ? 'Informazioni Persona' : 'Nuovo Persona'}
                       subtitle={person ? 'Modifica le informazioni della persona' : 'Inserisci le informazioni della persona'}
                       onClose={onClose}
                       state={state}
                //size='max-w-md'
            >
                <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                    {person ? <ActionButtonsSlideOver editAction={() => setIsDisabled(false)}
                                                      deleteAction={() => setShowModal(true)}/> : <></>}
                    <PersonForm person={person} companyUuid={uuid} onCreate={onCreate} onUpdate={onUpdate} isDisabled={person ? isDisabled : false}/>
                </div>
            </SlideOver>

            <DeleteModal title={person?.full_name} onClose={() => setShowModal(false)}
                         onDelete={() => onDelete(person?.uuid)} state={showModal}/>
        </>
    )
}