import TableCardHeader from "../../../../components/TableCardHeader";
import PermissionChecker from "../../../../components/Theme/PermissionChecker";
import NewElement from "../../../../components/UI/Buttons/NewElement";
import React, {useEffect, useState} from "react";
import AccountTableItem from "./AccountTableItem";
import AccountSlideOver from "./AccountsSlideOver";
import {accountServices} from "../../../../services/AccountService";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";

export const AccountsTable = ({accounts, model, siteId, modelUuid, onDelete, onCreate, onUpdate}) => {
    const [activeAccount, setActiveAccount] = useState()
    const [showSlideOver, setShowSlideOver] = useState(false);
    const [updatedAccounts, setUpdatedAccounts] = useState(accounts);

    const {flashMsg} = useFlashMessage();
    const openSlideOver = (account) => {
        setActiveAccount(account);
        setShowSlideOver(true)
    }

    useEffect(() => {
        setUpdatedAccounts(accounts)
    }, [accounts]);

    const createHandler = (res) => {
        setUpdatedAccounts(updatedAccounts ? [...updatedAccounts, res] : [res])
    }

    const updateHandler = (res) => {
        setUpdatedAccounts(updatedAccounts.map(a => a.uuid !== res.uuid ? a : res))
    }

    const deleteAccountHandler = (model, modelUuid, accountUuid) => {
        accountServices.deleteAccount(model, modelUuid, accountUuid)
            .then(res => {
                flashMsg(res.message, null, uuidv4(), true);
                setUpdatedAccounts(updatedAccounts.filter(a => a.uuid !== accountUuid));
            })
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false);
            });
    };

    return (
        <>
            {updatedAccounts?.length > 0 ?
                <>
                    <TableCardHeader title='Account' action={{
                        'action': () => (setActiveAccount() & setShowSlideOver(true)),
                        //permissions: ['accounts:edit']
                    }}/>
                    <PermissionChecker /*permissions={['accounts:view']}*/
                        message={'NON HAI IL PERMESSO DI VISUALIZZARE GLI ACCOUNT'}>
                        <ul className="divide-y divide-gray-100">
                            {updatedAccounts?.map(account =>
                                <AccountTableItem
                                    key={account?.id}
                                    model={model}
                                    modelUuid={modelUuid}
                                    account={account}
                                    onClick={() => openSlideOver(account)}/>
                            )}
                        </ul>
                    </PermissionChecker>
                </>
                :
                <PermissionChecker /*permissions={['accounts:edit']}*/
                    message={'NON HAI IL PERMESSO DI AGGIUNGERE NUOVI ACCOUNT'}>
                    <NewElement type='account'
                                onClick={() => (setShowSlideOver(true) & setActiveAccount())}/>
                </PermissionChecker>

            }
            <AccountSlideOver account={activeAccount} siteId={siteId} model={model} modelUuid={modelUuid}
                              state={showSlideOver} onClose={() => setShowSlideOver(false)} onDelete={deleteAccountHandler} onCreate={createHandler}
                              onUpdate={updateHandler}/>
        </>
    )
}

export default AccountsTable