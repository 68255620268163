import api from '../configs/api.config';

const peopleUrl = '/persons'
const companiesUrl = '/companies'

const people = params => api.get(peopleUrl, params).then(res => res.data);
const createPerson = (companyUuid, params) => api.post(`${companiesUrl}/${companyUuid}${peopleUrl}`, params).then(res => res.data);
const updatePerson = (companyUuid, personUuid, params) => api.put(`${companiesUrl}/${companyUuid}${peopleUrl}/${personUuid}`, params).then(res => res.data);
const personShow = (id, params) => api.get(peopleUrl + '/' + id, params).then(res => res.data);
const deletePerson = (companyUuid, personUuid, params) => api.delete(`${companiesUrl}/${companyUuid}${peopleUrl}/${personUuid}`, params).then(res => res.data);

export const personServices = {
    people,
    createPerson,
    updatePerson,
    deletePerson,
    personShow,
}