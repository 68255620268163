import api from '../configs/api.config';

const serverUrl = '/servers';

const servers = params => api.get(serverUrl, params).then(res => res.data);
const serverShow = (id, params) => api.get(serverUrl + '/' + id, params).then(res => res.data)
const createServer = params => api.post(serverUrl, params).then(res => res.data);
const updateServer = (uuid, params) => api.put(`${serverUrl}/${uuid}`, params).then(res => res.data);
const deleteServer = (id, params) => api.delete(`${serverUrl}/${id}`, params).then(res => res.data);
const createStorage = (id, params) => api.post(`${serverUrl}/${id}/storages`, params).then(res => res.data);
const updateStorage = (id, storageUuid, params) => api.put(`${serverUrl}/${id}/storages/${storageUuid}`, params).then(res => res.data);
const deleteStorage = (id, storageUuid, params) => api.delete(`${serverUrl}/${id}/storages/${storageUuid}`, params).then(res => res.data);
const createDatabase = (id, params) => api.post(`${serverUrl}/${id}/databases`, params).then(res => res.data);
const updateDatabase = (id, dbUuid, params) => api.put(`${serverUrl}/${id}/databases/${dbUuid}`, params).then(res => res.data);
const deleteDatabase = (id, dbUuid, params) => api.delete(`${serverUrl}/${id}/databases/${dbUuid}`, params).then(res => res.data);
const createLicense = (id, params) => api.post(`${serverUrl}/${id}/licenses`, params).then(res => res.data);
const updateLicense = (id, licenseUuid, params) => api.put(`${serverUrl}/${id}/licenses/${licenseUuid}`, params).then(res => res.data);
const deleteLicense = (id, licenseUuid, params) => api.delete(`${serverUrl}/${id}/licenses/${licenseUuid}`, params).then(res => res.data);
const createSite = (id, params) => api.post(`${serverUrl}/${id}/sites`, params).then(res => res.data);
const updateSite = (id, siteUuid, params) => api.put(`${serverUrl}/${id}/sites/${siteUuid}`, params).then(res => res.data);
const deleteSite = (id, siteUuid, params) => api.delete(`${serverUrl}/${id}/sites/${siteUuid}`, params).then(res => res.data);
const showPassword = (id, accountUuid, params) => api.get(`${serverUrl}/${id}/accounts/${accountUuid}`, params).then(res => res.data);
const serverOptions = params => api.get(`/options${serverUrl}`, params).then(res => res.data);
const serverTypeOptions = params => api.get(`/options/server-types`, params).then(res => res.data);
const databaseTypeOptions = params => api.get(`/options/database-types`, params).then(res => res.data);
const siteStageTypeOptions = params => api.get(`/options/stage-types`, params).then(res => res.data);

const serverServices={
    servers,
    serverShow,
    createServer,
    updateServer,
    deleteServer,
    createStorage,
    updateStorage,
    deleteStorage,
    createDatabase,
    updateDatabase,
    deleteDatabase,
    createLicense,
    updateLicense,
    deleteLicense,
    createSite,
    updateSite,
    deleteSite,
    serverOptions,
    showPassword,
    serverTypeOptions,
    databaseTypeOptions,
    siteStageTypeOptions,
}

export default serverServices