import {FlashMessagesContext} from "./FlashMessagesContext";
import {useContext} from "react";

const useFlashMessage =()=>{
    const {pushMessages, flashMessages}  = useContext(FlashMessagesContext);
    const flashMsg = (title, description, uuid, success) => pushMessages(title, description, uuid, success);

    return {
        flashMsg,
    }
}

export default useFlashMessage;