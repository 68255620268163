import React from "react";
import SlideOver from "../../../../components/UI/SlideOvers/SlideOver";
import DatabaseForm from "./DatabaseForm";

export const DatabaseSlideOver = ({database, siteId, serverUuid, onClose, onCreate, state}) => {

    return (
        <SlideOver title={database ? 'Informazioni Database' : 'Nuovo Database'}
                   subtitle={database ? 'Modifica le informazioni del database' : 'Inserisci le informazioni del database'}
                   state={state}
                   onClose={onClose}>

            <div className="space-y-6 py-6 sm:space-y-0 sm: sm:py-0">
                <DatabaseForm serverUuid={serverUuid} siteId={siteId} onCreate={onCreate}/>
            </div>
        </SlideOver>
    )
}

export default DatabaseSlideOver;