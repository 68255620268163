import CompanyForm from "./components/CompanyForm";
import {useRequest} from "ahooks";
import CustomerService from "../../services/CustomerService";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import {useParams} from "react-router-dom";

const EditCompany = () =>{
    const {uuid} = useParams();
    const {data = [], loading} = useRequest( CustomerService.companyShow, {
        defaultParams: [`${uuid}`]
    })

    return(
            <LoadingSpinner loading={loading}>
                <CompanyForm company={data}/>
            </LoadingSpinner>
    )
}



export default EditCompany