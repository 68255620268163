import React from 'react';

export const PageContent = props => {

  return (
    <main>
      <div className="mx-auto max-w-full px-4 py-6">
        {props.children}
      </div>
    </main>
  )

}
