import TableCardHeader from "../../../../components/TableCardHeader";
import PermissionChecker from "../../../../components/Theme/PermissionChecker";
import NewElement from "../../../../components/UI/Buttons/NewElement";
import React, {useEffect, useState} from "react";
import LicenseTableItem from "./LicenseTableItem";
import {LicenseSlideOver} from "./LicenseSlideOver";
import serverServices from "../../../../services/ServerService";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";

export const LicensesTable = ({licenses, siteId, onCreate, onUpdate, onDelete, serverUuid}) => {
    const [showSlideOver, setShowSlideOver] = useState(false)
    const [activeLicense, setActiveLicense] = useState();
    const [updatedLicenses, setUpdatedLicenses] = useState(licenses);

    const {flashMsg} = useFlashMessage();

    useEffect(() => {
        setUpdatedLicenses(licenses)
    }, [licenses]);

    const openSlideOver = (license) => {
        setShowSlideOver(true)
        setActiveLicense(license)
    }

    const createHandler = (res) =>{
        setUpdatedLicenses(updatedLicenses ? [...updatedLicenses, res] : [res])
    }

    const updateHandler = (res)=>{
        setUpdatedLicenses(updatedLicenses.map(l => l.uuid !== res.uuid ? l : res))
    }

    const deleteHandler = (modelUuid, licenseUuid) => {
        serverServices.deleteLicense(modelUuid, licenseUuid).then(res => {
                flashMsg(res.message, null, uuidv4(), true)
                setUpdatedLicenses(updatedLicenses.filter(l => l.uuid !== licenseUuid));
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
    }

    return (
        <>
            {updatedLicenses?.length > 0 ?
                <>
                    <TableCardHeader title='Licenze' action={{
                        'action': () => (setActiveLicense() & setShowSlideOver(true)),
                        //permissions: ['licenses:edit']
                    }}/>
                    <PermissionChecker /*permissions={['licenses:view']}*/
                        message={'NON HAI IL PERMESSO DI VISUALIZZARE LE LICENZE'}>
                        <ul className="divide-y divide-gray-100">
                            {updatedLicenses?.map(license =>
                                <LicenseTableItem
                                    key={license?.id}
                                    license={license}
                                    serverUuid={serverUuid}
                                    onUpdate={updateHandler}
                                    onClick={() => openSlideOver(license)}/>
                            )}
                        </ul>
                    </PermissionChecker>
                </>
                :
                <PermissionChecker /*permissions={['licenses:edit']}*/
                    message={'NON HAI IL PERMESSO DI AGGIUNGERE NUOVE LICENZE'}>
                    <NewElement type='license'
                                onClick={() => (setActiveLicense() & setShowSlideOver(true))}/>
                </PermissionChecker>
            }
            <LicenseSlideOver license={activeLicense} siteId={siteId} serverUuid={serverUuid}
                              onCreate={createHandler} onUpdate={updateHandler} onDelete={deleteHandler}
                              state={showSlideOver}
                              onClose={() => setShowSlideOver(false)}/>
        </>
    )
}

export default LicensesTable;