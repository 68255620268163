import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import packageServices from "../../services/PackageService";
import PackageShowInfo from "./components/PackageShowInfo";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import ActivitiesTable from "./components/ActivitiesTable";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";
import NewActivitySlideOver from "./components/NewActivitySlideOver";
import PackageActivitiesTableConfig from "../../components/UI/Configs/Tables/PackageActivitiesTableConfig";
import ShowActivitySlideOver from "./components/ShowActivitySlideOver";
import useFlashMessage from "../../components/FlashMessages/useFlashMessage";
import {v4 as uuidv4} from "uuid";
import activityServices from "../../services/ActivityService";
import SectionContent from "../../components/Theme/PageSections/SectionContent";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import Card from "../../components/UI/Cards/Card";

export const PackageShow = () => {
    const {uuid} = useParams();
    const {flashMsg} = useFlashMessage();
    const navigation = useNavigate();
    const [showSlideOver, setShowSlideOver] = useState(false);
    const [showEditSlideOver, setShowEditSlideOver] = useState(false);
    const [activity, setActivity] = useState('');
    const [extraP, setExtraP] = useState({})


    const {data: pack = undefined, loading, mutate} = useRequest(packageServices.packageShow, {
        defaultParams: [uuid],
    })

    const {data: activities = [], run, mutate:mutateAct} = useRequest(activityServices.activities, {
        manual: true,
        defaultParams: [{
            filter: {
                package_id: pack?.id ?? '',
                project_id: pack?.project?.id ?? '',
            },
            sort: {date: '-date'}
        }]
    });

    useEffect(() => {
        setExtraP({
            filter: {
                package_id: pack?.id ?? '',
                project_id: pack?.project?.id ?? '',
            },
            sort: {date: '-date'}
        })
    }, [pack])

    useEffect(() => {
            !loading && run(extraP)
        // eslint-disable-next-line
    }, [extraP]);

    const handleStateUpdate = state => {
        mutate({
            ...pack,
            states: {
                available_states: state.available_states,
                state: state.state
            }
        })
    }

    const updatePackData = (updatedActivities) => {
        let usedAmount = 0;
            updatedActivities.forEach(a => {
                usedAmount += a?.amount
            });


        const updatedData = {
            ...pack,
            used_amount: usedAmount,
            remaining_amount: pack?.amount-usedAmount
        };

        mutate(updatedData);
    }


    const deleteHandler = () => {
        let myuuid = uuidv4();
        packageServices.deletePackage(uuid).then(res => {
                flashMsg(res.message, null, myuuid, true)
                navigation(-1)
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
    }

    const handleAddActivity = (res) => {
        const updatedData=[...activities.data, res]

        mutateAct({...activities, data:updatedData});

        updatePackData(updatedData);
    }

    const handleUpdateActivity = (res) => {
        const updatedData = activities?.data?.map(act => act.uuid !== res.uuid ? act : {...res, user: act.user, project: act.project, package: act.package})

        mutateAct({...activities, data:updatedData});

        updatePackData(updatedData);
    }

    const handleDeleteActivity = (activityUuid) => {
        let myuuid = uuidv4();
        activityServices.deleteActivity(uuid, activityUuid).then(res => {
                flashMsg(res.message, null, myuuid, true)
                const updatedData = activities?.data?.filter(act => act.uuid !== activityUuid)
                mutateAct({...activities, data: updatedData});
                updatePackData(updatedData)

            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, myuuid, false)
            })
    }

    const tableConfig = [
        ...PackageActivitiesTableConfig,
        {
            accessor: 'uuid',
            className: 'items-center text-center w-16',
            disableSortBy: true,
            Cell: ({row}) =>
                <div className='items-center'>
                    <button
                        onClick={() => (setActivity(row.original) & setShowEditSlideOver(true))}
                        className="rounded-md text-xs font-semibold text-green-600 hover:text-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                        <ArrowTopRightOnSquareIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>
                </div>
        }
    ]

    return (
        <>
            <LoadingSpinner loading={loading}>
                <SectionHeader title={'Dettagli Pacchetto'}/>
                <SectionContent
                    left={
                        <PackageShowInfo data={pack}
                                         onStateUpdate={handleStateUpdate}
                                         onDelete={deleteHandler}
                        />
                    }
                    right={
                        <Card>
                            <ActivitiesTable activities={activities} tableConfig={tableConfig}
                                             action={{'action': () => setShowSlideOver(true), 'permissions': ['activities:edit']}}/>
                        </Card>
                    }
                />
            </LoadingSpinner>
            <NewActivitySlideOver state={showSlideOver}
                                  project_id={pack?.project?.id}
                                  package_id={pack?.id}
                                  company_id={pack?.company?.id}
                                  onCreate={handleAddActivity}
                                  onClose={() => setShowSlideOver(false)}/>
            <ShowActivitySlideOver state={showEditSlideOver}
                                   project_id={pack?.project?.id}
                                   package_id={pack?.id}
                                   disabled={true}
                                   activity={activity}
                                   onUpdate={handleUpdateActivity}
                                   onDelete={handleDeleteActivity}
                                   onClose={() => setShowEditSlideOver(false)}/>
        </>
    )
}

export default PackageShow