import TableCardHeader from "../../../../components/TableCardHeader";
import PermissionChecker from "../../../../components/Theme/PermissionChecker";
import NewElement from "../../../../components/UI/Buttons/NewElement";
import React, {useState} from "react";
import DatabaseTableItem from "./DatabaseTableItem";
import DatabaseSlideOver from "./DatabaseSlideOver";
import serverServices from "../../../../services/ServerService";
import {v4 as uuidv4} from "uuid";
import useFlashMessage from "../../../../components/FlashMessages/useFlashMessage";

export const DatabasesTable = ({
                                   databases,
                                   siteId,
                                   serverUuid
                               }) => {
    const [showDatabaseSlideOver, setShowDatabaseSlideOver] = useState(false);
    const [updatedDatabases, setUpdatedDatabases] = useState(databases);

    const {flashMsg} = useFlashMessage();

    const addDatabaseHandler = (res) => {
        setUpdatedDatabases(updatedDatabases ? [...updatedDatabases, res] : [res])
    }

    const updateDatabaseHandler = (res) => {
        setUpdatedDatabases(updatedDatabases.map(d => d.uuid !== res.uuid ? d : res))
    }

    const deleteDatabaseHandler = (modelUuid, dbUuid) => {
        serverServices.deleteDatabase(modelUuid, dbUuid).then(res => {
                flashMsg(res.message, null, uuidv4(), true)
                setUpdatedDatabases(updatedDatabases.filter(a => a.uuid !== dbUuid))
            }
        )
            .catch(err => {
                console.log(err);
                flashMsg('Error', err?.response?.data?.message, uuidv4(), false)
            })
    }

    return (
        <>
            {updatedDatabases?.length > 0 ?
                <>
                    <TableCardHeader title='Database' action={{
                        'action': () => (setShowDatabaseSlideOver(true)),
                        //permissions: ['databases:edit']
                    }}/>
                    <PermissionChecker /*permissions={['databases:view']}*/
                        message={'NON HAI IL PERMESSO DI VISUALIZZARE I DATABASE'}>
                        <ul className="divide-y divide-gray-100">
                            {updatedDatabases?.map(db =>
                                <DatabaseTableItem
                                    key={db?.id}
                                    database={db}
                                    siteId={siteId}
                                    onUpdate={updateDatabaseHandler}
                                    onDelete={deleteDatabaseHandler}
                                    serverUuid={serverUuid}
                                />
                            )}
                        </ul>
                    </PermissionChecker>
                </>
                :
                <PermissionChecker /*permissions={['databases:edit']}*/
                    message={'NON HAI IL PERMESSO DI AGGIUNGERE NUOVI DATABASE'}>
                    <NewElement type='database'
                                onClick={() => setShowDatabaseSlideOver(true)}/>
                </PermissionChecker>
            }
            <DatabaseSlideOver serverUuid={serverUuid} siteId={siteId} onCreate={addDatabaseHandler}
                               state={showDatabaseSlideOver} onClose={() => setShowDatabaseSlideOver(false)}/>

        </>
    )
}

export default DatabasesTable