import {Select} from "../../../components/UI/Forms/Select";
import {useRequest} from "ahooks";
import CustomerService from "../../../services/CustomerService";

export const PaymentConditionsSelect =({value})=>{
    const {data = [], } = useRequest(CustomerService.paymentConditions);

    return(
        <>
            <Select
                value={value}
                name="payment_condition"
                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
                {data ?
                    <>
                        <option className='text-center'>--SELEZIONA OPZIONE DI
                            PAGAMENTO---
                        </option>
                        {data?.map(item => (
                            <option key={item.value}
                                    value={item.value}>{item.label}</option>
                        ))}
                    </>

                    : <option>Loading</option>
                }

            </Select>
        </>
    )
}
