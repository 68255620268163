import api from '../configs/api.config';

const usersUrl = '/users';

const users = params => api.get(usersUrl, params).then(res => res.data);
const userShow = (id, params) => api.get(usersUrl + '/' + id, params).then(res => res.data)
const createUser = params => api.post(usersUrl, params).then(res => res.data);
const updateUser = (uuid, params) => api.put(`${usersUrl}/${uuid}`, params).then(res => res.data);
const deleteUser = (id, params) => api.delete(`${usersUrl}/${id}`, params).then(res => res.data);


const userServices = {
    users,
    userShow,
    createUser,
    updateUser,
    deleteUser,
}

export default userServices;