import api from '../configs/api.config';

const expenseUrl = '/expense-reports';
const expenses = params => api.get(expenseUrl, params).then(res => res.data);
const expenseShow = (id, params) => api.get(expenseUrl + '/' + id, params).then(res => res.data)
const createExpense = params => api.post(expenseUrl, params).then(res => res.data);
const updateExpense = (uuid, params) => api.put(`${expenseUrl}/${uuid}`, params).then(res => res.data);
const deleteExpense = (id, params) => api.delete(`${expenseUrl}/${id}`, params).then(res => res.data);
const createExpenseEntry = (expenseUuid, params) => api.post(`${expenseUrl}/${expenseUuid}/expense-entries`, params).then(res => res.data);
const updateExpenseEntry = (expenseUuid, uuid, params) => api.put(`${expenseUrl}/${expenseUuid}/expense-entries/${uuid}`, params).then(res => res.data);
const deleteExpenseEntry = (expenseUuid, uuid, params) => api.delete(`${expenseUrl}/${expenseUuid}/expense-entries/${uuid}`, params).then(res => res.data);
const expenseTypeOptions = params => api.get('/options/expense-entry-types', params).then(res => res.data);
const monthOptions = params => api.get('/options/months', params).then(res => res.data);


const expenseServices = {
    expenses,
    expenseShow,
    createExpense,
    updateExpense,
    deleteExpense,
    createExpenseEntry,
    updateExpenseEntry,
    deleteExpenseEntry,
    expenseTypeOptions,
    monthOptions,
}

export default expenseServices;