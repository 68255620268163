import React, {useMemo} from 'react';
import {useRequest} from "ahooks";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import {useNavigate} from "react-router-dom";
import tableOptions from "../../configs/tableOptions";
import SectionHeader from "../../components/Theme/PageSections/SectionHeader";
import {createButtonConfig} from "../../components/UI/Configs/createButtonConfig";
import userServices from "../../services/UserService";
import UsersTableConfig from "../../components/UI/Configs/Tables/UsersTableConfig";
//import companiesTableOption from "../../configs/companiesTableOption";

const Users = () => {
    const navigation = useNavigate();
    const actions = [{
        ...createButtonConfig,
        action: (e => navigation('create')),
        permissions: ['users:edit']
    }]
    const columns = useMemo(() => UsersTableConfig, []);
    const {data = [], loading, run} = useRequest(userServices.users, {
        manual: true
    });


    return (
        <>
            <SectionHeader
                title={'Utenti'}
                subtitle={'Elenco di tutti gli utenti registrati'}
                actions={actions}/>
            <div
                className="relative overflow-hidden rounded-lg bg-white pb-6  shadow sm:px-6 pt-6 text-center items-center justify-center">
                <PaginatedTable
                    data={data}
                    columns={columns}
                    onParamsChange={run}
                    options={tableOptions}
                    isLoading={loading}
                />
            </div>

        </>

    )

}

export default Users
