import CopyToClipboardButton from "../Buttons/CopyToClipboardButton";
import React from "react";

export const InfoCardField = ({title, value, canCopy = false})=>{
    return(
        <>
            <div className='flex flex-row gap-x-4'>
                <div>
                    <dt className="text-sm font-bold leading-6 text-gray-900">{title}</dt>
                    <dd className="text-sm leading-6 text-gray-700">{value ? value : '---'}</dd>
                </div>
                {canCopy && <CopyToClipboardButton textToCopy={value}/>}
            </div>


        </>
    )
}

export default InfoCardField