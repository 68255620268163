import {NumericFormat} from "react-number-format";

const EuroFormat = ({value}) => {
    return (
        <NumericFormat
            value={value}
            displayType='text'
            decimalSeparator=','
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator='.'
            prefix='€ '
        />
    );
};

export default EuroFormat;