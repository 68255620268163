import {useSanctum} from "react-sanctum";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {PriceItem} from "./PriceItem";

export const ProductPricesDragNDrop = ({onDrag, prices, onPriceDelete, onPriceUpdate, onClick}) => {
    const {user} = useSanctum();

    if (user.permissions.some(permission => ['product_prices:edit']?.includes(permission))) {
        return <>
            <DragDropContext onDragEnd={onDrag}>
                <Droppable droppableId="priceItems">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {prices?.map((price, index) => (
                                <Draggable key={price.id} draggableId={price.id.toString()} index={index}>
                                    {(provided) => (
                                        <div className='mb-4'
                                             ref={provided.innerRef}
                                             {...provided.draggableProps}
                                             {...provided.dragHandleProps}
                                        >
                                            <PriceItem price={price} is_default={price?.is_default}
                                                       onPriceDelete={onPriceDelete}
                                                       onPriceUpdated={onPriceUpdate}
                                                       onClick={onClick}
                                                       permissions={['product_prices:edit']}/>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>;
    } else {
        return <>{prices?.map((price) => (

            <div className='mb-4'
            >
                <PriceItem price={price} is_default={price?.is_default}
                           onPriceDelete={onPriceDelete}
                           onPriceUpdated={onPriceUpdate}
                           onClick={onClick}
                           permissions={['product_prices:edit']}/>
            </div>
        ))}</>
    }

};

export default ProductPricesDragNDrop;