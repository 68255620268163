import InfoTableField from "../../../components/UI/Fields/InfoTableField";
import React from "react";
import StateDropDown from "../../../components/UI/StateDropDown";
import {InfoNoteField} from "../../../components/UI/Fields/InfoNoteField";
import InfoCard from "../../../components/UI/Cards/InfoCard";
import DateFormat from "../../../components/UI/DateFormat";

export default function QuoteShowInfo(props) {
    const quote = props.data;

    return (
        <InfoCard title={quote?.subject?.toUpperCase()} canDelete={false} permissions={['quotes:edit']}>
            <InfoTableField title={'Cliente'} value={quote?.company?.business_name}
                            link={`/companies/${quote?.company?.uuid}`}/>
            <InfoTableField title={'Progetto'} value={quote?.project?.name}
                            link={`/projects/${quote?.project?.uuid}`}/>
            <InfoTableField title={'Data'} value={<DateFormat value={quote?.quote_date}/>}/>
            <div className="flex justify-center border-t border-gray-100 px-4 py-4 sm:col-span-full sm:px-0">
                <StateDropDown available_states={quote?.states?.available_states} value={quote?.states?.state} permissions={['quotes:edit']}/>
            </div>
            <InfoNoteField title={'Note'} value={quote?.note}/>
        </InfoCard>
    )
}