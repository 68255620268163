import {Disclosure} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import ActionButtonsSlideOver from "../../../../components/UI/Buttons/ActionButtonsSlideOver";
import AccountsTable from "../Accounts/AccountsTable";
import React, {useState} from "react";
import StoragesTable from "../Storages/StoragesTable";
import Divider from "../Divider";
import DatabasesTable from "../Databases/DatabasesTable";
import LicensesTable from "../Licenses/LicensesTable";
import SiteInfo from "./SiteInfo";
import SiteForm from "./SiteForm";
import DeleteModal from "../../../../components/UI/DeleteModal";
import StatusBadge from "../../../../components/UI/StatusBadge";

export const SiteTableItem = ({
                                  site,
                                  onUpdate,
                                  onDelete,
                                  serverUuid
                              }) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <li key={site.id}
                className="flex flex-col sm:flex-row items-center gap-x-6 py-3 w-full">
                <div className='flex w-full'>
                    <div className="w-full rounded-2xl bg-white basis-7/8 ">
                        <Disclosure>
                            {({open}) => (
                                <>
                                    <Disclosure.Button
                                        className="flex w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75">
                                        <div className='flex flex-col gap-y-2'>
                                            <span>{site?.domain}</span>
                                            <StatusBadge value={site?.stage?.label} color={site?.stage?.color}/>
                                        </div>

                                        <ChevronDownIcon
                                            className={`${
                                                open ? 'rotate-180 transform' : ''
                                            } h-5 w-5 text-gray-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                        className="px-4 pb-2 pt-4 mt-1 border border-gray-500 text-sm rounded-lg">
                                        <div className='flex flex-col min-w-full mb-4'>
                                            <div className='text-right'>
                                                <ActionButtonsSlideOver editAction={e => setIsDisabled(!isDisabled)}
                                                                        deleteAction={e => setShowModal(true)}
                                                                        canDelete={true}/>
                                            </div>
                                            <div>
                                                {isDisabled ? <SiteInfo site={site}/>
                                                    :
                                                    <SiteForm site={site}
                                                              serverUuid={serverUuid}
                                                              onUpdate={onUpdate}
                                                              onCancel={e => setIsDisabled(true)}
                                                    />}
                                            </div>
                                        </div>

                                        <Divider/>

                                        <AccountsTable accounts={site?.accounts} model={site?.model}
                                                       modelUuid={site?.uuid}
                                        />

                                        <Divider/>

                                        <LicensesTable licenses={site?.licenses}
                                                       serverUuid={serverUuid}
                                                       siteId={site?.id}
                                        />

                                        <Divider/>

                                        <StoragesTable storages={site?.storages}
                                                       serverUuid={serverUuid}
                                                       siteId={site?.id}
                                        />

                                        <Divider/>

                                        <DatabasesTable databases={site?.databases}
                                                        serverUuid={serverUuid}
                                                        siteId={site?.id}
                                        />

                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </div>
                </div>
            </li>
            <DeleteModal title={site?.domain} onClose={() => setShowModal(false)}
                         onDelete={() => onDelete(serverUuid, site?.uuid)} state={showModal}/>
        </>
    )
}

export default SiteTableItem