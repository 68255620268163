import LoadingSpinner from "../../components/UI/LoadingSpinner";
import ServerForm from "./components/ServerForm";
import {useRequest} from "ahooks";
import serverServices from "../../services/ServerService";
import {useParams} from "react-router-dom";

export const EditServer = ()=>{
    const {uuid} = useParams();
    const {data, loading} = useRequest(serverServices.serverShow, {defaultParams: [uuid]});

    return(
        <LoadingSpinner loading={loading}>
            <ServerForm server={data}/>
        </LoadingSpinner>
    )
}